import React, { useState, useEffect } from 'react';
import './manageUsers.css';
import Papa from 'papaparse';
import axios from './axiosConfig';
import { customFetch } from './customFetch';

import Select from 'react-select';
import { List, Input, Tag, Dropdown, Button, message, Alert, Tooltip, Modal, Form } from "antd";
import { UserDeleteOutlined, CloseOutlined, UserAddOutlined } from '@ant-design/icons';

const PY_API_URL = process.env.REACT_APP_PY_API_URL;

function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [userIndex, setUserIndex] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [cleared, setCleared] = useState(0);
  const roles = ['Engineer', 'Project Leader', 'Cost Center Manager', 'Test Technician','Admin'];
  var userNames = users.map(user => ({
    value: user.name, // Use a unique identifier for each user
    label: user.name
  }));

  const getUserRowColor = (role) => {
    switch (role) {
      case 'Engineer':
        return 'eng';
      case 'Project Leader':
        return 'pl';
      case 'Cost Center Manager':
        return 'ccm';
      default:
        return '';
    }
  };

  useEffect(() => {
    customFetch(`${PY_API_URL}/api/getUsers`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setUsers(data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
  }, [cleared]);

  const roleIDs = [
    { name: 'Engineer', id: 1 },
    { name: 'Project Leader', id: 2 },
    { name: 'Cost Center Manager', id: 3 },
    { name: 'Test Technician', id: 4 },
    { name: 'Admin', id: 5 }
  ];
 
  const updateRole = async (updatedUser) => {
    const selectedRole = roleIDs.find(role => role.name === updatedUser.role);
   
    if (!selectedRole) {
      console.error('Role not found:', updatedUser.role);
      return;
    }
 
    const userWithRoleID = { ...updatedUser, roleID: selectedRole.id };
 
    const formData = new FormData();
    formData.append('user', JSON.stringify(userWithRoleID));
 
    console.log(userWithRoleID);
    console.log(userWithRoleID['roleID']);
    console.log(formData);
 
    try {
      const response = await customFetch(`${PY_API_URL}/api/updateRole`, {
        method: 'POST',
        body: formData,
      });
 
      if (response.ok) {
        console.log("User's role successfully updated");
      } else {
        console.error('Form data submission failed. HTTP status code:', response.status);
      }
    } catch (error) {
      console.error('An error occurred while sending the form data:', error);
    }
  };
  




  const handleRoleChange = (index, selectedRole) => {
    const updatedUsers = [...users];
    const updatedUser = { ...updatedUsers[index], role: selectedRole };
    updatedUsers[index] = updatedUser;
    setUsers(updatedUsers);
  
    updateRole(updatedUser);
  };
  

  const filterUsers = () => {
    const updatedUsers = [];
    for (const user of users){
      if (user.name === selectedUser){
        updatedUsers.push(user);
      };
    };
    setUsers(updatedUsers);
  };

  useEffect(() => {
    filterUsers();
  }, [selectedUser])



  const getRoleColor = (role) => {
    switch (role) {
      case "Engineer":
        return "purple";
      case "Project Leader":
        return "blue";
      case "Cost Center Manager":
        return "orange";
      case "Admin":
        return "red";
      case "Test Technician":
        return "green";
      default:
        return "grey";
    }
  };

  const shorten_role = (role) => {
    switch (role) {
      case "Engineer":
        return "Engineer";
      case "Project Leader":
        return "Project Leader";
      case "Cost Center Manager":
        return "CC Manager";
      case "Admin":
      return "Admin";
      case "Test Technician":
      return "Test Techician";
      default:
        return "";
    }
  }
  const fileInputRef = React.createRef();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userRole, setUserRole] = useState('');

  const handleBulkRegister = () => {
    setModalIsOpen(true);
  };

  const handleCSVUpload = () => {
    fileInputRef.current.click();
  };


  
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    Papa.parse(file, {
      complete: async (results) => {
        console.log('Parsed data:', results.data);

        const mapRoleToRoleID = (role) => {
          switch (role.toLowerCase()) {
            case 'engineer': return 1;
            case 'project leader': return 2;
            case 'cost center manager': return 3;
            case 'test technician': return 4;
            case 'admin': return 5;
            default: return 1; // Default to Engineer if role is not recognized
          }
        };

        const users = results.data.map(row => {
          // if (row.length < 2) { 
          //   console.error('Invalid row with insufficient data:', row);
          //   return null; 
          // }
          const name = row[0] && row[0].includes(',') ? row[0].split(', ').reverse().join(' ') : row[0];
          const email = row[1]; 
          const roleID = mapRoleToRoleID(userRole);
  
          return {
            name: name,
            email: email,
            roleID: roleID
          };
        }).filter(user => user !== null); 
        console.log('The users are:', users);

    try {
          const response = await axios.post("https://zwewaetojsbe.azurewebsites.net/bulkRegister", { users });

          message.success('Bulk registration successful!');
          console.log(response.data);
        } catch (error) {
          console.error('Bulk registration failed:', error);
          message.error('Bulk registration failed: ' + error.message);
        }
      },
      header: false 
    });
  };

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;

  const handleAddUser = async () => {
    try {
      const values = await form.validateFields();
      // const response = await axios.post(`${PY_API_URL}/api/add_user`, {
      //   user: values.userName,
      //   email: values.email,
      //   role: values.role,
      // });
      const response = await axios.post("https://ztowaetojsbackend.azurewebsites.net/bulkRegister", {
        users: [{
          name: values.userName,
          email: values.email,
          roleID: values.role.value,
        }],
      });
      if (response.status === 200) {
        form.resetFields();
        setIsAddUserModalOpen(false);
        message.success('User Added!');
        // Fetch users to update the list
        const usersResponse = await customFetch(`${PY_API_URL}/api/getUsers`);
        if (!usersResponse.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await usersResponse.json();
        setUsers(data);
      } else {
        console.error('Error in response:', response);
      }
    } catch (error) {
      console.error('Error submitting new user:', error);
    }
  };

  const handleDeactivateUser = (user) => {
    try {
      const response = axios.post(`${PY_API_URL}/api/deactivate_user`, {
      email: user.email,
    });
    message.success('User Deactivated!');
    } catch (error) {
      console.error('Error submitting new eto project:', error);
    }
};
  
  
  const showConfirmDeactivateUser = (user) => {
    Modal.confirm({
      title: 'Are you sure you want to deactivate this user?',
      content: 'This action cannot be undone.',
      onOk() {
        handleDeactivateUser(user);
      },
      onCancel() {
        console.log('Deactivation cancelled');
      },
    });
  };
  
  

  return (
    <div className="manage-users-container">
      <h2 style={{ display: 'inline-block', marginRight: '10px', marginBottom: '0' }}>Manage Users</h2>
      <h5 style={{ display: 'inline-block', marginBottom: '0', marginRight: '10px' }}>{users.length} total</h5>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button style={{ width: '200px' }} onClick={handleBulkRegister}>
          Add Multiple Users
        </Button>
        <Tooltip title="Add User">
          <UserAddOutlined
            style={{ marginLeft: '1em', marginBottom: '0.7em', color: 'green', fontSize: '150%' }}
            onClick={() => setIsAddUserModalOpen(true)}
          />
        </Tooltip>
        <Modal
          title="Add User"
          open={isAddUserModalOpen}
          onOk={handleAddUser}
          onCancel={() => setIsAddUserModalOpen(false)}
          okText="Add"
          cancelText="Cancel"
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={{ role: 0 }}
            style={{width: '100%'}}
          >
            <Alert message="The added user will have the password 'password'. Make sure to change it later to secure the account." type="info" />
            <br />
            <Form.Item
              name="userName"
              label="User Name"
              rules={[{ required: true, message: 'Please enter the user name!' }]}
            >
              <Input placeholder="Enter user name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please enter a valid Magna email!' }, { type: 'email', message: 'Please enter a Magna valid email!' }]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: 'Please select a role!' }]}
            >
              <Select
                showSearch
                placeholder="Select a role"
                optionFilterProp="label"
                // onChange={onChange}
                // onSearch={onSearch}
                options={[
                  {
                    value: 5,
                    label: 'Admin',
                  },
                  {
                    value: 1,
                    label: 'Engineer',
                  },
                  {
                    value: 2,
                    label: 'Project Leader',
                  },
                  {
                    value: 3,
                    label: 'Cost Center Manager',
                  },
                  {
                    value: 5,
                    label: 'Test Technician',
                  },
                ]}
              />
            </Form.Item>
          </Form>
        </Modal>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileUpload}
          accept=".csv"
        />
      </div>
      {modalIsOpen && (
      <div style={{
        position: 'fixed',
        top: '20%',
        left: '30%',
        width: '40%',  
        transform: modalIsOpen ? 'translateY(0)' : 'translateY(-20px)',
        opacity: modalIsOpen ? 1 : 0,
        transition: 'opacity 0.3s ease, transform 0.3s ease',
        backgroundColor: 'white',
        padding: '20px',
        zIndex: 100,
        boxShadow: '0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)',
        borderRadius: '8px'  
      }}>

    {<CloseOutlined onClick={() => setModalIsOpen(false)} style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        cursor: 'pointer'
      }}/>}
          <p>Please upload a CSV file with the first column being the user name and the second column being the user email.</p>
          <div>
            <p>Please select the user role of these users:</p>
            <select value={userRole} onChange={e => setUserRole(e.target.value)}>
              <option value="">Select Role</option>
              <option value="Admin">Admin</option>
              <option value="Engineer">Engineer</option>
              <option value="Project Leader">Project Leader</option>
              <option value="Cost Center Manager">Cost Center Manager</option>
              <option value="Test Technician">Test Technician</option>
            </select>
          </div>
          <button onClick={handleCSVUpload}>Upload</button>
        </div>
      )}

        <div className='user-header' style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            options={userNames}
            isSearchable
            isClearable
            placeholder="Search For Users"
            onChange={(selectedOption) => {
              if (!selectedOption) {
                setCleared(cleared+1);
              } else {
                const value = selectedOption.value;
                setSelectedUser(value);
              }
            }}
            styles={{
              control: (provided) => ({
                ...provided,
                width: '45.2vw',
                marginTop: '15px',
                marginLeft: '3vw'
              }),
            }}
          />
          
        </div>
        
        <div className='users-list'>
          <List
            className="white-background-list"
            itemLayout="horizontal"
            dataSource={users}
            renderItem={(user, index) => (
              <List.Item>
                <Tag  
                      className='vertical-tag '
                      color={getRoleColor(user.role)}
                      style={{display: 'inline-block'}} >
                  {shorten_role(user.role)}
                </Tag>
                
                <div className="role-strip" style={{ backgroundColor: getRoleColor(user.role) }} />
                <List.Item.Meta
                  title={<a href="javascript:;">{user.name}</a>}
                  description={user.email}
                />
                
                <select
                    className="user-role"
                    value={user.role}
                    onChange={(e) => handleRoleChange(index, e.target.value)}
                    >
                    {roles.map((role, roleIndex) => (
                        <option key={roleIndex} value={role}>
                        {role}                
                        </option>
                    ))}
                </select>
                <Tooltip title="Deactivate User">
                <UserDeleteOutlined style={{marginLeft: '1em', color: 'red', fontSize: '150%'}} onClick={() => showConfirmDeactivateUser(user)}/>
                </Tooltip>
                
              </List.Item>
            )}
          />
        </div>
    </div>
  );
}

export default ManageUsers;

