import React, { useState, useRef, useEffect } from 'react';
import { HotTable } from '@handsontable/react';
import { Drawer, Upload, message, Spin,Input, Button, Modal} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import "./DynamicTable.css";
import * as XLSX from 'xlsx';
import axios from 'axios';
import Papa from 'papaparse';
import { customFetch } from '../customFetch';
import {Link, useLocation, useNavigate} from 'react-router-dom';



registerAllModules();

const { Dragger } = Upload;

const PY_API_URL = process.env.REACT_APP_PY_API_URL;

const DynamicTable = React.forwardRef((tableProps, ref) => {
  // const { nodeID, initialCSVData = '', testName, testNumber } = tableProps;
  

  const data = useLocation();
  const navigate = useNavigate();

  const nodeID = data.state.nodeID;
  const nodeNumber = parseInt(nodeID.split("-")[1], 10);
  const initialCSVData = '';
  const testName = data.state.testName;
  const testNumber = data.state.testNumber;


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        console.log("gek")
        event.preventDefault(); // Prevent default backspace action
        // Add your logic for handling backspace in the table if necessary
      }
    };

    // Add the event listener for keydown in the table
    // window.addEventListener('keydown', handleKeyDown);

    // return () => {
    //   // Clean up the event listener
    //   window.removeEventListener('keydown', handleKeyDown);
    // };
  }, []);

  
  const initialProperties = [
    "IS back up free travel", "IS electrical release", "IS travel barrier (contact with metal barrier lever)",
    "IS back up mechanical realease travel", "IS back up MECANICAL UNLOCK TRAVEL (1 pull,from start to micro TCR)",
    "IS Full travel", "Over travel (values between release and full travel must be min. 2mm)",
    "IS Release Effort (with metal barrier lever)", "IS Return effort (at 3,5mm)", "OS back up free travel",
    "OS electrical release", "OS back up mechanical realease travel", "OS Full travel",
    "Over travel (values between release an full travel must be min. 2mm)", "OS Release Effort",
    "OS Return effort (at 3,5mm)", "OS Return effort (arrivare a FULL TRAVEL)", "OS Return effort (arrivare a META' CORSA)",
    "Latching effort", "Overslam (To be intended as Full travel)", "Distance between prim. and sec.",
    "Distance between secondary and pawl rise", "Distance between primary and pawl rise", "Door ajar switch point (from prim.) in andata",
    "Start of mechanical lock travel (42°->46°)", "Full travel (73°)", "Pretravel effort", "Max. Lock effort",
    "El. switch point andata (27,5°-> 49,5)", "El. switch point ritorno (27,5°-> 49,5)", "Start of mechanical unlock travel (42°->46°)",
    "Unlock to lock effort", "Travel da start a reset durante il ritorno", "Effort in reset durante il ritorno del cavo",
    "Travel from start to full travel", "Massimo valore di effort durante la chiusura", "Massimo valore di effort dove il pawl e' chiuso",
    "Minimo valore di effort nella valle", "Power release time (from start to stall)", "Power release stall current absorption",
    "Crash unlock time (from start to stall)", "Crash unlock stall current absorption", "Unlock to lock (from start to stall)",
    "Unlock stall current (target for reference)", "Lock to unlock (from start to stall)", "Lock stall current"
  ];

  const [attachedFiles, setAttachedFiles] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState({});
  console.log(comments);
  console.log(attachedFiles);

  const initialTableData = [
    ['Latch Number', '', '', '', '', '', '', ''],
    ['Latch Version', '', '', '', '', '', '', ''],
    ['Latch code', '', '', '', '', '', '', ''],
    ['Functional Audit', '', '', '', '', '', '', ''],
    ['Visual Inspection', '', '', '', '', '', '', ''],
    ['DATA measurement', '', '', '', '', '', '', ''],
    ['Note', '', '', '', '', '', '', ''],
    ['operator ID', '', '', '', '', '', '', ''],
    ['Properties', 'Category', 'Unit', 'rich. min.', 'rich. max', 'MIN', 'MAX', 'AVG']
  ];

  const [tableData, setTableData] = useState(initialTableData);

  useEffect(() => {
    if (initialCSVData) {
      populateTableFromCSV(initialCSVData);
    }
  }, [initialCSVData]);

  const populateTableFromCSV = (csvData) => {
    Papa.parse(csvData, {
      complete: (result) => {
        setTableData(result.data);
      },
      skipEmptyLines: true,
    });
  };

  const hotTableRef = useRef(null);

  const updateFormulaColumn = (data) => {
    const updatedData = [...data];
  
    for (let rowIndex = 9; rowIndex < updatedData.length; rowIndex++) {
      const row = updatedData[rowIndex];
      const values = row.slice(7).map(Number).filter(val => !isNaN(val) && val !== 0 && val !== null && val !== ''); // Exclude empty or null values
      const avg = values.length ? (values.reduce((acc, val) => acc + val, 0) / values.length).toFixed(2) : '';
      row[7] = avg;
    }

    for (let rowIndex = 9; rowIndex < updatedData.length; rowIndex++) {
      const row = updatedData[rowIndex];
      const values = row.slice(7).map(Number).filter(val => !isNaN(val) && val !== null && val !== ''); // Exclude empty or null values
      const max = values.length ? Math.max(...values).toFixed(2) : '';
      row[6] = max;
    }

    for (let rowIndex = 9; rowIndex < updatedData.length; rowIndex++) {
      const row = updatedData[rowIndex];
      const values = row.slice(7).map(Number).filter(val => !isNaN(val) && val !== null && val !== ''); // Exclude empty or null values
      const min = values.length ? Math.min(...values).toFixed(2) : '';
      row[5] = min;
    }
    
    setTableData(updatedData);
  };
  

  const handleExportToExcel = () => {
    const hotInstance = hotTableRef.current.hotInstance;
    const tableData = hotInstance.getData();
  
    const ws = XLSX.utils.aoa_to_sheet(tableData);
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    XLSX.writeFile(wb, `${testName}-${testNumber}-testData.xlsx`);
  };

  const [loading, setLoading] = useState(false);


  const exportToExcel = () => {
    setLoading(true);
    const ws = XLSX.utils.aoa_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    const formData = new FormData();
    formData.append('excel', blob, 'dynamic_table_data.xlsx');
    formData.append('nodeID', nodeNumber); 
    formData.append('comments', JSON.stringify(comments));

    Object.keys(attachedFiles).forEach((row) => {
      Object.keys(attachedFiles[row]).forEach((col) => {
          attachedFiles[row][col].forEach((file, index) => {
              formData.append(`file_${row}_${col}_${index}`, file);
          });
      });
  });

    axios.post(`${PY_API_URL}/api/upload_data_table`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      message.success("Changes saved");
    })
    .catch((error) => {
      message.error('Update failed', error);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleAttachFile = (row, col, files) => {
    setAttachedFiles((prevAttachedFiles) => ({
        ...prevAttachedFiles,
        [row]: {
            ...(prevAttachedFiles[row] || {}),
            [col]: [...(prevAttachedFiles[row]?.[col] || []), ...files],
        },
    }));
};

  

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const uploadProps = {
    name: 'file',
    multiple: true,
    customRequest({ file, onSuccess }) {
      setTimeout(() => {
        if (selectedCell) {
          handleAttachFile(selectedCell.row, selectedCell.col, [file]);
        }
        onSuccess("ok");
      }, 500);
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
      const files = Array.from(e.dataTransfer.files);
      if (selectedCell) {
        handleAttachFile(selectedCell.row, selectedCell.col, files);
    }
    }
  };

  React.useImperativeHandle(ref, () => ({
    exportToExcel,
  }));

  const [hiddenRowColArray, setHiddenRowColArray] = useState([]);
  useEffect(() => {
    setIsLoading(true);
  
    customFetch(`${PY_API_URL}/api/get_data_table/${nodeNumber}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        populateTableFromCSV(data.csv);
  
        if (data.comments) {
          setComments(data.comments);
        }

        if (data.attachments) {
          setAttachedFiles(data.attachments);
        }
        setHiddenRowColArray(data.emptyRowCol);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [nodeNumber]);
  
  const handleCommentChange = (value) => {
    if (selectedCell) {
      const { row, col } = selectedCell;
      setComments((prevComments) => ({
        ...prevComments,
        [row]: {
          ...(prevComments[row] || {}),
          [col]: value,
        },
      }));
    }
  };

  // const hiddenColumns = [...new Set(hiddenRowColArray.map(item => item[1]))];
  // const hiddenRows = [...new Set(hiddenRowColArray.map(item => item[0]))];

  // useEffect(() => {
  //   const hotInstance = hotTableRef.current?.hotInstance;
  
  //   if (hotInstance) {
  //     const hiddenColumnsPlugin = hotInstance.getPlugin('hiddenColumns');
  //     const hiddenRowsPlugin = hotInstance.getPlugin('hiddenRows');
  
  //     // Hide columns
  //     hiddenColumns.forEach((colIndex) => {
  //       hiddenColumnsPlugin.hideColumn(colIndex);
  //     });
  
  //     // Hide rows
  //     hiddenRows.forEach((rowIndex) => {
  //       hiddenRowsPlugin.hideRow(rowIndex);
  //     });
  
  //     console.log(hiddenRowColArray);
  //     hotInstance.render();
  //   }
  // }, [hiddenRowColArray, hiddenColumns, hiddenRows]);

  const backToFlowchart = () => {
    // console.log(jobData);
    const jobDetailsString = localStorage.getItem('job_details');
    if (jobDetailsString) {
        const jobDetails = JSON.parse(jobDetailsString);
        jobDetails.state['nodeID'] = nodeID;
        navigate('/flowchart', {state:jobDetails.state})
    }

  };

  const showConfirm_back = () => {
    Modal.confirm({
      title: 'Are you sure you want to go back? Any unsaved changes will be lost.',
      onOk() {
        backToFlowchart();
      },
      onCancel() {
        console.log('Back cancelled');
      },
    });
  };


  return (
    isLoading ? (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', 
      }}>
        <Spin tip="Loading..." size="large">
        </Spin>
      </div>
    ) : 
    <>
      <div className="test-header-dt">
        <span className="test-name-dt">{testName}</span>
        <div className="divider-dt" />
        <span className="test-number-dt">{testNumber}</span>
        
        <div className="button-group-dt">
        <Button type="default" onClick={handleExportToExcel} style={{ backgroundColor: 'lightblue', borderColor: 'lightblue' }}>Export</Button>
        <Spin spinning={loading}><Button  key="submit" type="primary" onClick={exportToExcel} style={{ backgroundColor: '#50C878', borderColor: '#50C878' }}>Save</Button></Spin>
        <Button type="default" onClick={showConfirm_back}>Exit</Button>
        </div>
      </div>
      <div className="table-container">
      <HotTable
        className="custom-hot-table"
        // style={{ maxHeight: '95%', overflowY: 'auto' }}
        data={tableData}
        height="auto"
        width="auto"
        ref={hotTableRef}
        colHeaders={(index) => String.fromCharCode(65 + index)}
        rowHeaders={true}
        hiddenColumns={{
          indicators: true // shows a small indicator where columns are hidden
        }}
        hiddenRows={{
          indicators: true, // shows a small indicator where rows are hidden
        }}
        contextMenu={{
          items: {
            // 'row_above': {},
            'row_below': {
              name: 'Insert row at the bottom',
              callback: function () {
                const hotInstance = hotTableRef.current.hotInstance;
                const numRows = hotInstance.countRows();
                hotInstance.alter('insert_row_below', numRows - 1); // Insert a row at the very bottom
              }
            },
            // 'remove_row': {},
            // 'col_left': {},
            'col_right': {
                name: 'Insert column at far right',
                callback: function () {
                  const hotInstance = hotTableRef.current.hotInstance;
                  const numCols = hotInstance.countCols();
                  hotInstance.alter('insert_col_end', numCols); // Insert a column at the far right
                }
              },
            // 'remove_col': {},
            '---------': {},
            'undo': {},
            'redo': {},
            'hide_column': {
              name: 'Remove column',
              callback: function (key, selection) {
                if (selection.length) {
                  const colIndex = selection[0].start.col;
                  const hotInstance = hotTableRef.current.hotInstance;
      
                  // Clear all data in the selected column before hiding it
                  const numRows = hotInstance.countRows();
                  for (let row = 0; row < numRows; row++) {
                    hotInstance.setDataAtCell(row, colIndex, '-'); // Clear the cell data
                  }
      
                  const hiddenColumnsPlugin = hotInstance.getPlugin('hiddenColumns');
                  hiddenColumnsPlugin.hideColumn(colIndex);
      
                  hotInstance.render();
                }
              }
            },
            'hide_row': {
              name: 'Remove row',
              callback: function (key, selection) {
                if (selection.length) {
                  const rowIndex = selection[0].start.row;
                  const hotInstance = hotTableRef.current.hotInstance;

                  // Clear all data in the selected row before hiding it
                  const numCols = hotInstance.countCols();
                  for (let col = 0; col < numCols; col++) {
                    hotInstance.setDataAtCell(rowIndex, col, '-');
                  }

                  const hiddenRowsPlugin = hotInstance.getPlugin('hiddenRows');
                  hiddenRowsPlugin.hideRow(rowIndex);

                  hotInstance.render();
                }
              },
            },
            // 'show_column': {
            //   name: 'Show all columns',
            //   callback: function () {
            //     hotTableRef.current.hotInstance.getPlugin('hiddenColumns').showColumn([...Array(hotTableRef.current.hotInstance.countCols()).keys()]);
            //     hotTableRef.current.hotInstance.render();
            //   }
            // },
            'attachments': {
              name: 'Additional info',
              callback: function (key, selection) {
                if (selection.length) {
                  const cell = selection[0];
                  setSelectedCell({ row: cell.start.row, col: cell.start.col });
                }
                showDrawer();
              }
            }
          }
        }}
        cells={(row, col) => {
          
          if (row < 8 && col >= 1 && col <= 7) {
            return { readOnly: true, className: 'htDimmed3' };
          }

          if (row < 8 && col === 0) {
            return { readOnly: true, className: 'htDimmed2' };
          }

          if (row === 8) {
            return { className: 'htBold' };
          }

          if (row > 8 && col === 0) {
            return { type: 'dropdown', source: initialProperties};
          }

          if (row > 6 && col > 2) {
            if (tableData[row]){
            const minValue = parseFloat(tableData[row][3]); // rich.min. column
            const maxValue = parseFloat(tableData[row][4]); // rich.max. column
            const cellValue = parseFloat(tableData[row][col]);
      
            if (!isNaN(minValue) && !isNaN(cellValue) && (cellValue < minValue)) {
              if (comments[row] && comments[row][col] && comments[row][col] !== '') {
                return { className: 'highlight-red-comment-border' };
              }
              if (attachedFiles[row] && attachedFiles[row][col] && attachedFiles[row][col].length > 0) {
                return { className: 'highlight-red-file-border' };
              }
              return { className: 'highlight-red' };
            }

            if (!isNaN(maxValue) && !isNaN(cellValue) && (cellValue > maxValue)) {
              if (comments[row] && comments[row][col] && comments[row][col] !== '') {
                return { className: 'highlight-red-comment-border' };
              }
              if (attachedFiles[row] && attachedFiles[row][col] && attachedFiles[row][col].length > 0) {
                return { className: 'highlight-red-file-border' };
              }
              return { className: 'highlight-red' };
            }
          }}

          if (comments[row] && comments[row][col] && comments[row][col] !== '') {
            return { className: 'comment-border' };
          }

          if (attachedFiles[row] && attachedFiles[row][col] && attachedFiles[row][col].length > 0) {
            return { className: 'file-border' };
          }

          return {};
        }}
        comments={true}
        autoWrapRow={true}
        autoWrapCol={true}
        rowHeights={40}
        colWidths={(index) => index === 0 ? '400%' : index === 1 ? '100%' : '70%'}
        // minSpareRows={1}
        licenseKey="non-commercial-and-evaluation"
        afterChange={(changes, source) => {
          if (changes) {
            const newData = hotTableRef.current.hotInstance.getData();
            setTableData(newData);
            updateFormulaColumn(newData);
          }
        }}
        plugins={['ExportFile']}
      />
      </div>

      <Drawer title="" placement="right" onClose={onCloseDrawer} visible={drawerVisible}>
        {selectedCell ? (
          <>
            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
              Cell: 
              <span 
                style={{ 
                  padding: '5px 10px', 
                  backgroundColor: '#e6f7ff', 
                  border: '1px solid #91d5ff', 
                  borderRadius: '4px', 

                  marginLeft: '5px' 
                }}
              >
                Row {selectedCell.row + 1}, Column {selectedCell.col + 1}
              </span>
            </p>
            <p style={{ fontWeight: 'bold' }}>
              Value: 
              <span 
                style={{ 
                  padding: '5px 10px', 
                  backgroundColor: '#f0f0f0', 
                  border: '1px solid #d9d9d9', 
                  borderRadius: '4px', 
                  marginLeft: '5px',
                }}
              >
                {tableData[selectedCell.row][selectedCell.col] || 'No value'}
              </span>
            </p>
            <p style={{marginTop: '10%'}}>
              Attached File(s): {
                  attachedFiles &&
                  attachedFiles[selectedCell.row] && 
                  attachedFiles[selectedCell.row][selectedCell.col] ? 
                  attachedFiles[selectedCell.row][selectedCell.col].join(', ') || 'No attachments' : 
                  'No attachments'
              }
          </p>


            <Dragger style={{maxHeight: '20%' }} {...uploadProps}>
              <p className="ant-upload-drag-icon" style={{ maxHeight: '30%' }}>
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>

            <div style={{ marginTop: '20px' }}>
              <p style={{ fontWeight: 'bold' }}>Comments:</p>
              <Input.TextArea 
                rows={4}
                placeholder="Add your comments here..."
                onChange={(e) => handleCommentChange(e.target.value)}
                value={comments[selectedCell.row]?.[selectedCell.col] || ''}
              />
            </div>
          </>
        ) : (
          <p>Select a cell to view attachments</p>
        )}
      </Drawer>

    </>
  );
});

export default DynamicTable;
