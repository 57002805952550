import React, { useCallback, useState, useRef, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Handle, Position, useNodeId, useReactFlow } from "reactflow";
import "./UniqueTest.css";
import {
  Modal,
  Table,
  Button,
  message,
  Spin,
  Divider,
  Space,
  Tag,
  InputNumber,
  Input,
  Tooltip,
  Tabs,
} from "antd";
import axios from "../axiosConfig";
import { customFetch } from "../customFetch";
import {
  ReloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  NumberOutlined,
  MessageTwoTone,
  SettingTwoTone,
  FieldNumberOutlined,
  TableOutlined,
  ArrowsAltOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
// import { runes } from 'runes2';
import * as XLSX from "xlsx";
import ContextMenu from "./ContextMenu";
// import DynamicTable from './DynamicTable';
// import DynamicTable from './DynamicTable2';
import DynamicTable from "./DynamicTable3";

const PY_API_URL = process.env.REACT_APP_PY_API_URL;

function UniqueTest({ data, isConnectable }) {
  const userRole = localStorage.getItem("userRole");
  const userName = localStorage.getItem("userName");
  const job_details = useLocation();
  const [isExpanded, setIsExpanded] = useState(data["selected"] ? true : false);
  const [moduloNodeId, setModuloNodeId] = useState(
    data["moduloID"] ? data["moduloID"] : 0
  );
  const { TabPane } = Tabs;

  const [testingInstruction, setTestingInstruction] = useState(
    data["testingInstruction"] ? data["testingInstruction"] : ""
  );
  const [startDate, setStartDate] = useState(
    data["startDate"]
      ? new Date(data["startDate"]).toISOString().split("T")[0]
      : null
  );
  const [endDate, setEndDate] = useState(
    data["endDate"]
      ? new Date(data["endDate"]).toISOString().split("T")[0]
      : null
  );
  const [testDuration, setTestDuration] = useState(
    data["testDuration"] !== undefined &&
      data["testDuration"] !== null &&
      data["testDuration"] !== -1
      ? data["testDuration"]
      : ""
  );
  const [expenseEstimation, setExpenseEstimation] = useState(
    data["expenseEstimation"] !== undefined &&
      data["expenseEstimation"] !== null &&
      data["expenseEstimation"] !== -1
      ? data["expenseEstimation"]
      : ""
  );
  const [hoursEstimation, setHoursEstimation] = useState(
    data["hoursEstimation"] !== undefined &&
      data["hoursEstimation"] !== null &&
      data["hoursEstimation"] !== -1
      ? data["hoursEstimation"]
      : ""
  );

  // console.log(data);

  const [fixture, setFixture] = useState(
    data["fixture"] ? data["fixture"] : ""
  );
  // console.log("hehe:" , data);
  // useEffect(() => {
  //   console.log('hoursEstimation is:', hoursEstimation);
  // }, [hoursEstimation]);

  // console.log(data["hoursEstimation"] === '' || data["hoursEstimation"] === 0);
  const [fixtures, setFixtures] = useState([]);
  const [totalUsedQuantity, setTotalUsedQuantity] = useState(0);

  const [climaticChamber, setClimaticChamber] = useState(
    data["climaticChamber"] ? data["climaticChamber"] : ""
  );
  const [climaticChambers, setClimaticChambers] = useState([]);
  const [status, setStatus] = useState(
    data["status"] ? data["status"] : "Test Not Planned"
  );
  const [linkCopied, setLinkCopied] = useState(false);
  const [TIRNum, setTIRNum] = useState(data["TIRNum"] ? data["TIRNum"] : "");
  const [sampleSizeNote, setSampleSizeNote] = useState(
    data["sampleSizeNote"] ? data["sampleSizeNote"] : ""
  );

  const [pdName, setPdName] = useState(data["PDName"] ? data["PDName"] : "");

  // useEffect(() => {
  //   data["sampleSizeNote"] = sampleSizeNote;
  //   data["testingInstruction"] = testingInstruction;
  //   data["startDate"] = startDate;
  //   data["endDate"] = endDate;
  //   data["testDuration"] = testDuration;
  //   data["expenseEstimation"] = expenseEstimation;
  //   data["hoursEstimation"] = hoursEstimation;
  //   data["fixture"] = fixture;
  //   data["climaticChamber"] = climaticChamber;
  //   data["status"] = status;
  //   data['TIRNum'] = TIRNum;
  // }, [sampleSizeNote, testingInstruction, startDate, endDate, testDuration, expenseEstimation, hoursEstimation, fixture, climaticChamber, status, TIRNum]);
  data["sampleSizeNote"] = sampleSizeNote;
  data["testingInstruction"] = testingInstruction;
  data["startDate"] = startDate;
  data["endDate"] = endDate;
  data["testDuration"] = testDuration;
  data["expenseEstimation"] = expenseEstimation;
  data["hoursEstimation"] = hoursEstimation;
  data["fixture"] = fixture;
  data["climaticChamber"] = climaticChamber;
  data["status"] = status;
  data["TIRNum"] = TIRNum;
  data["PDName"] = pdName;
  const testStatuses = [
    "Test Not Started",
    "In Progress Without Incident",
    "Completed Without Incident",
    "In Progress With Incident",
    "Completed With Incident",
  ];
  // const onChange = useCallback((evt) => {
  //   console.log(evt.target.value);
  // }, []);
  const nodeId = useNodeId();
  console.log(nodeId);
  const tableRef = useRef(null);
  const fileInputRef = useRef(null);

  // For Test Results
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [logData, setLogData] = useState([]);

  //For Test Reports
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [isReportModalVisible, setReportIsModalVisible] = useState(false);
  const [reportLogData, setReportLogData] = useState([]);

  //For Test Report Uploads
  const [isUploadingReport, setIsUploadingReport] = useState(false);
  const [isApprovedUpload, setIsApprovedUpload] = useState(true);

  // For Sample Selection
  const [isSampleModalVisible, setIsSampleModalVisible] = useState(false);
  const [sampleData, setSampleData] = useState([]);

  //For Data Table
  const [hover, setHover] = useState(false);
  const [isDynamicTableModalVisible, setIsDynamicTableModalVisible] =
    useState(false);
  const showModal = () => {
    // setIsDynamicTableModalVisible(true);
  };

  const handleOk = () => {
    if (tableRef.current) {
      tableRef.current.exportToExcel();
    }
    // setIsDynamicTableModalVisible(false);
  };

  const handleCancel = () => {
    setIsDynamicTableModalVisible(false);
  };

  const [sampleInputs, setSampleInputs] = useState({});
  console.log("samples:", sampleInputs);
  const [uploadedData, setUploadedDate] = useState(false);

  // const job_details = useLocation();
  const reactFlowInstance = useReactFlow();
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const nodeRef = useRef(null);
  const columns = [
    // {
    //   title: "Test Spec ID",
    //   dataIndex: "TestSpecID",
    //   key: "TestSpecID",
    // },
    // {
    //   title: "Node ID",
    //   dataIndex: "NodeID",
    //   key: "NodeID",
    // },
    {
      title: "User",
      dataIndex: "userStamp",
      key: "userStamp",
    },
    {
      title: "Timestamp",
      dataIndex: "timeStamp",
      key: "timeStamp",
    },
  ];

  const downloadFile = (file) => {
    customFetch(`${PY_API_URL}/api/download_file/${file}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Get the response as a Blob
      })
      .then((blob) => {
        // Create a link element
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = file;

        // Append the link to the body
        document.body.appendChild(a);

        // Simulate click
        a.click();

        // Remove the link
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    setIsExpanded(data["selected"] ? true : false);
  }, [data]);

  useEffect(() => {
    customFetch(`${PY_API_URL}/api/get_climatic_chambers`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        let res = response.json();
        return res;
      })
      .then((data) => {
        setClimaticChambers(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    customFetch(`${PY_API_URL}/api/get_fixtures`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        let res = response.json();
        return res;
      })
      .then((data) => {
        setFixtures(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (userRole === "Test Technician") {
      const updateTestStatus = async () => {
        try {
          const response = await axios.post(
            `${PY_API_URL}/api/update_test_status`,
            {
              status: status,
              nodeID: parseInt(nodeId.split("-")[1], 10),
              TIR: TIRNum,
            }
          );
          console.log(response.data);
        } catch (error) {
          console.error("Error updating test status:", error);
        }
      };
      updateTestStatus();
    }
  }, [status, TIRNum]);

  useEffect(() => {
    if (userRole === "Test Technician") {
      // jobOrderID = job_details.state.jobOrderID;
      if (status !== "Test Not Started") {
        console.log("the current status is: ", status);
        if (job_details.state.status === "Job Approved by CCM") {
          const updateJobStatus = async () => {
            try {
              const response = await axios.post(
                `${PY_API_URL}/api/test_start_status/${job_details.state.jobOrderID}`
              );
              console.log(response.data);
            } catch (error) {
              console.error("Error updating test status:", error);
            }
          };
          updateJobStatus();
        }
      }
    }
  }, [status]);

  const handleSelectSampleChange = (value, record) => {
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    console.log("the value is: ", value);
    console.log("the record is: ", record);
    console.log("the numericNodeId is: ", numericNodeId);
    console.log("the sample id is: ", record.SamplesID);
    console.log("into the api call...");
    try {
      const response = axios.post(`${PY_API_URL}/api/selectSample/update`, {
        SamplesID: record.SamplesID,
        numericNodeId: numericNodeId,
        usedQuantity: value,
      });
      if (response.status === 200) {
        console.log(response.data);
      } else {
        console.log("response.status is", response.status);
        console.log("response.data is", response.data);
        message.error("Failed to select sample");
      }
    } catch (error) {
      console.error("Error updating the used quantity:", error);
      message.error("Error in selecting sample");
    }
  };

  const handleSaveAll = async () => {
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    const updatePromises = Object.entries(sampleInputs).map(
      async ([sampleId, { usedQuantity, quantityDetails, notes }]) => {
        try {
          const response = await axios.post(
            `${PY_API_URL}/api/selectSample/update`,
            {
              numericNodeId: numericNodeId,
              SamplesID: sampleId,
              usedQuantity: usedQuantity ? usedQuantity : 0,
              quantityDetails: quantityDetails,
              notes: notes,
            }
          );
          if (response.status === 200) {
            return { success: true };
          } else {
            console.error("Failed to update sample", sampleId, response);
            return { success: false, error: "Failed to select sample" };
          }
        } catch (error) {
          console.error("Error updating the used quantity:", error);
          return { success: false, error: "Error in selecting sample" };
        }
      }
    );

    Promise.all(updatePromises).then((results) => {
      const allSuccessful = results.every((result) => result.success);
      if (allSuccessful) {
        message.success("All samples have been successfully updated.");

        // Update totalUsedQuantity based on the latest sample data
        const updatedTotalUsedQuantity = Object.values(sampleInputs).reduce(
          (sum, input) => sum + (input.usedQuantity || 0),
          0
        );
        setTotalUsedQuantity(updatedTotalUsedQuantity); // Update state
      } else {
        message.error("One or more samples failed to update.");
      }
    });
  };

  // const handleSaveAll = async () => {
  //   const numericNodeId = parseInt(nodeId.split("-")[1], 10);
  //   const updatePromises = Object.entries(sampleInputs).map(
  //     async ([sampleId, { usedQuantity, quantityDetails, notes }]) => {
  //       try {
  //         const response = await axios.post(
  //           `${PY_API_URL}/api/selectSample/update`,
  //           {
  //             numericNodeId: numericNodeId,
  //             SamplesID: sampleId,
  //             usedQuantity: usedQuantity ? usedQuantity : 0,
  //             quantityDetails: quantityDetails,
  //             notes: notes,
  //           }
  //         );
  //         if (response.status === 200) {
  //           return { success: true };
  //         } else {
  //           console.error("Failed to update sample", sampleId, response);
  //           return { success: false, error: "Failed to select sample" };
  //         }
  //       } catch (error) {
  //         console.error("Error updating the used quantity:", error);
  //         return { success: false, error: "Error in selecting sample" };
  //       }
  //     }
  //   );

  //   Promise.all(updatePromises).then((results) => {
  //     const allSuccessful = results.every((result) => result.success);
  //     if (allSuccessful) {
  //       message.success("All samples have been successfully updated.");
  //     } else {
  //       message.error("One or more samples failed to update.");
  //     }
  //   });
  // };

  const { TextArea } = Input;
  const sample_columns = [
    {
      title: "Part Name",
      dataIndex: "PartName",
      key: "PartName",
    },
    {
      title: "Part Number",
      dataIndex: "PartNumber",
      key: "PartNumber",
    },
    {
      title: "Revision Level",
      dataIndex: "RevisionLevel",
      key: "RevisionLevel",
    },
    {
      title: "Max Quantity",
      dataIndex: "Quantity",
      key: "Quantity",
    },
    {
      title: "Used Quantity",
      key: "usedQuantity",
      render: (_, record) => (
        <InputNumber
          min={0}
          max={record.Quantity}
          value={
            sampleInputs[record.SamplesID]?.usedQuantity !== undefined
              ? sampleInputs[record.SamplesID].usedQuantity
              : record.UsedQuantity
          }
          prefix={<SettingTwoTone />}
          onChange={(value) => {
            setSampleInputs((current) => ({
              ...current,
              [record.SamplesID]: {
                ...current[record.SamplesID],
                usedQuantity: value,
              },
            }));
          }}
        />
      ),
    },
    {
      title: "Quantity Details",
      key: "SampleNumbering",
      render: (_, record) => (
        <Input
          value={
            sampleInputs[record.SamplesID]?.quantityDetails ||
            record.SampleNumbering
          }
          prefix={
            <span style={{ color: "#1677FF" }}>
              <FieldNumberOutlined />
            </span>
          }
          onChange={(e) => {
            const value = e.target.value;
            setSampleInputs((current) => ({
              ...current,
              [record.SamplesID]: {
                ...current[record.SamplesID],
                quantityDetails: value,
              },
            }));
          }}
        />
      ),
    },
    {
      title: "Notes",
      key: "Notes",
      render: (_, record) => (
        <TextArea
          value={sampleInputs[record.SamplesID]?.notes || record.Notes}
          onChange={(e) => {
            const value = e.target.value;
            setSampleInputs((current) => ({
              ...current,
              [record.SamplesID]: {
                ...current[record.SamplesID],
                notes: value,
              },
            }));
          }}
        />
      ),
    },
  ];

  const handleReportApprove = async (reportId) => {
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    const reports_node = {
      reportId: reportId,
      nodeId: numericNodeId,
    };

    try {
      const response = await axios.post(
        `${PY_API_URL}/api/approveReport`,
        reports_node,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        message.success("Report approved successfully");
        fetchReportLogData(); // Refresh table data
      } else {
        message.error("Failed to approve report");
      }
    } catch (error) {
      console.error("Error approving report:", error);
      message.error("Error approving report");
    }
  };

  const handleReportReject = async (reportId) => {
    try {
      const response = await axios.post(
        `${PY_API_URL}/api/rejectReport/${reportId}`
      );
      if (response.status === 200) {
        message.success("Report rejected successfully");
        // Refresh table data
        fetchReportLogData();
      } else {
        message.error("Failed to reject report");
      }
    } catch (error) {
      console.error("Error rejecting report:", error);
      message.error("Error rejecting report");
    }
  };

  const report_columns = [
    {
      title: "Report ID",
      dataIndex: "ReportID",
      key: "ReportID",
    },
    {
      title: "Document Link",
      dataIndex: "DocumentLink",
      key: "DocumentLink",
      render: (text) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            downloadFile(text);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Generated By",
      dataIndex: "GeneratedBy",
      key: "GeneratedBy",
    },
    {
      title: "Report Status",
      dataIndex: "ReportStatus",
      key: "ReportStatus",
    },
  ];

  // Add the Action column conditionally based on userRole
  if (userRole === "Project Leader") {
    report_columns.push({
      title: "Action",
      dataIndex: "ReportStatus",
      key: "action",
      render: (text, record) => {
        if (text === "Pending Approval") {
          return (
            <>
              <Button
                type="primary"
                onClick={() => handleReportApprove(record.ReportID)}
                style={{ marginRight: 8 }}
              >
                Approve
              </Button>
              <Button
                type="danger"
                onClick={() => handleReportReject(record.ReportID)}
              >
                Reject
              </Button>
            </>
          );
        } else if (text === "Approved") {
          return (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Approved
            </Tag>
          );
        } else if (text === "Rejected") {
          return (
            <Tag icon={<CloseCircleOutlined />} color="error">
              Rejected
            </Tag>
          );
        } else {
          return text;
        }
      },
    });
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "Test Not Started":
        return "gray";
      case "Completed Without Incident":
        return "lightgreen";
      case "Completed With Incident":
        return "red";
      case "In Progress Without Incident":
        return "yellow";
      case "In Progress With Incident":
        return "linear-gradient(to right, red 50%, yellow 50%)";
      default:
        return "gray";
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
    setLinkCopied(false);
  };

  const handleCopyLink = () => {
    // const linkAddress = `file:///Y:/Testing%20Instruction/${data["testNumber"]}`;
    const linkAddress = "";
    setLinkCopied(true);
    navigator.clipboard
      .writeText(linkAddress)
      .then(() => {
        console.log("Link address copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy link address to clipboard", error);
      });
  };

  //handleUpload
  const handleTestUpload = () => {
    // document.getElementById("fileInput").click();
    fileInputRef.current.click();
    setUploadedDate(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Start loading animation
      setIsLoading(true);

      const reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result;
        let workbook;
        let isCSV = file.name.endsWith(".csv");

        if (isCSV) {
          console.log("if is csv, the data is: ", data);
          parseCSVData(data, nodeId, userName, userRole);
        } else {
          workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          data = XLSX.utils.sheet_to_csv(worksheet);
          console.log("Converted Excel to CSV data:", data);
          parseCSVData(data, nodeId, userName, userRole);
        }
      };

      if (file.name.endsWith(".csv")) {
        reader.readAsText(file);
      } else {
        reader.readAsBinaryString(file);
      }
    }
  };

  const parseCSVData = (text, nodeId, userName, userRole) => {
    const lines = text.split(/\r\n|\n/); // Handle different newline characters
    console.log("Parsed lines:", lines);
    const uniqueSpecsMap = new Map();
    let specIdCounter = 1;

    const dataMap = new Map();

    lines.forEach((line, index) => {
      if (index < 5 || !line.trim()) return; // Skip header or empty lines
      const values = line.split(",");
      console.log("Parsed values:", values);
      const spec = values.slice(1, 20).join(",");
      const dataPart = values.slice(20).map((field) => field || null);

      let refNumber;
      if (!uniqueSpecsMap.has(spec)) {
        refNumber = specIdCounter++;
        uniqueSpecsMap.set(spec, refNumber);
      } else {
        refNumber = uniqueSpecsMap.get(spec);
      }

      if (!dataMap.has(refNumber)) {
        dataMap.set(refNumber, []);
      }
      dataMap.get(refNumber).push(dataPart);
    });

    const uniqueSpecsArray = Array.from(
      uniqueSpecsMap,
      ([spec, refNumber]) => ({
        refNumber,
        spec: spec.split(",").map((field) => field || null),
      })
    );
    const dataArray = Array.from(dataMap, ([refNumber, data]) => ({
      refNumber,
      data,
    }));
    // Node ID
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);

    const payload = {
      uniqueSpecs: uniqueSpecsArray,
      data: dataArray,
      userName: userName,
      userRole: userRole,
      nodeId: numericNodeId,
    };
    console.log("The uniqueSpecsArray of the excel is: ", uniqueSpecsArray);

    customFetch(`${PY_API_URL}/api/upload_test_specs_batch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setIsLoading(false);
        message.success("Upload successful!");
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        message.error("Upload failed!");
      });
  };

  useEffect(() => {
    if (
      userRole === "Project Leader" ||
      userRole === "Test Technician" ||
      userRole === "Admin"
    ) {
      const numericNodeId = parseInt(nodeId.split("-")[1], 10);
      customFetch(`${PY_API_URL}/api/getTestLog/${numericNodeId}`, {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          setLogData(data);
        })
        .catch((error) => {
          console.error("Error:", error);
          // message.error("Failed to fetch test log.");
        });
    }
  }, [uploadedData]);

  const handleTestLog = () => {
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    customFetch(`${PY_API_URL}/api/getTestLog/${numericNodeId}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        setLogData(data);
        setIsModalVisible(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    customFetch(`${PY_API_URL}/api/getTestLog/${numericNodeId}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        setLogData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        // message.error("Failed to fetch test log.");
      });
  }, [isLoading]);

  useEffect(() => {
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    customFetch(`${PY_API_URL}/api/getSelectSample/${numericNodeId}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        setSampleData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleSampleSelection = () => {
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    customFetch(`${PY_API_URL}/api/getSelectSample/${numericNodeId}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        setSampleData(data);
        setIsSampleModalVisible(true);

        const initialInputs = data.reduce((acc, sample) => {
          acc[sample.SamplesID] = {
            usedQuantity: sample.UsedQuantity,
            quantityDetails: sample.SampleNumbering,
            notes: sample.Notes,
          };
          return acc;
        }, {});

        setSampleInputs(initialInputs);
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error("Failed to fetch sample types log.");
      });
  };

  // console.log(startDate);

  const handleTestReport = async () => {
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    setIsLoadingReport(true);

    const node_username = {
      nodeId: numericNodeId,
      userName: userName,
    };

    try {
      const response = await axios.post(
        `${PY_API_URL}/api/generateTestReport`,
        node_username,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.path) {
        console.log("Document has been saved to: ", response.data.path);
        message.success("Report generation successful!");
      } else if (response.data.error) {
        message.error(response.data.error);
      }
    } catch (error) {
      console.error("Error generating the report:", error);
      if (error.response && error.response.data && error.response.data.error) {
        message.error(error.response.data.error);
      } else {
        message.error("Failed to generate the report.");
      }
    } finally {
      setIsLoadingReport(false);
    }
  };

  const handleViewTestReport = () => {
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    customFetch(`${PY_API_URL}/api/getReportLog/${numericNodeId}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        setReportLogData(data);
        setReportIsModalVisible(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error("Failed to fetch test log.");
      });
  };

  const checkTestReports = () => {
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    customFetch(`${PY_API_URL}/api/getReportLog/${numericNodeId}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        setReportLogData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    checkTestReports();
  }, []);

  const nodeIdRef = useRef();
  const handleReportUpload = () => {
    // const numericNodeId = parseInt(nodeId.split("-")[1], 10);

    // nodeIdRef.current = numericNodeId; // Store nodeId in ref

    // document.getElementById("fileInput").click();
    // console.log("handleReportUpload, nodeIdRef.current", nodeIdRef.current);
    const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    const fileInput = document.getElementById("fileInput");
    fileInput.setAttribute("data-nodeid", numericNodeId); // Set nodeId as a data attribute
    fileInput.click();
  };

  const handleReportFileChange = async (event) => {
    // const numericNodeId = parseInt(nodeId.split("-")[1], 10);
    // const numericNodeId = nodeIdRef.current;
    // const file = event.target.files[0];
    // if (!file) {
    //   return;
    // }
    // console.log("Selected file:", file.name);
    // console.log("handleReportFileChange, nodeIdRef.current", nodeIdRef.current);

    const fileInput = event.target;
    const numericNodeId = parseInt(fileInput.getAttribute("data-nodeid"), 10);
    const file = fileInput.files[0];
    if (!file) {
      return;
    }
    console.log("handleReportFileChange, numericNodeId", numericNodeId);
    setIsUploadingReport(true);

    const formData = new FormData();
    formData.append("reportFile", file);
    formData.append("userName", userName); //userName from local Storage
    formData.append("nodeId", numericNodeId);

    try {
      const response = await axios.post(
        `${PY_API_URL}/api/uploadReport`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("File uploaded successfully");
        message.success("Upload completed");
        fetchReportLogData();
      } else {
        console.error("File upload failed");
        message.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Error uploading file");
    } finally {
      setIsUploadingReport(false); // Stop showing the spinner
    }
  };

  const fetchReportLogData = async () => {
    // message.loading('Refreshing data...');

    try {
      const numericNodeId = parseInt(nodeId.split("-")[1], 10);
      const response = await customFetch(
        `${PY_API_URL}/api/getReportLog/${numericNodeId}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      setReportLogData(data);
      // message.success('Data refreshed successfully');
    } catch (error) {
      console.error("Failed to fetch data:", error);
      message.error("Failed to refresh data");
    }
  };

  const approvedReportIndex = reportLogData.findIndex(
    (report) => report.ReportStatus === "Approved"
  );

  // const handleContextMenu = (event) => {
  //   event.preventDefault();
  //   setIsContextMenuVisible(true);
  //   nodeRef.current.style.left = `${event.pageX}px`;
  //   nodeRef.current.style.top = `${event.pageY}px`;
  // };
  const handleContextMenu = (event) => {
    event.preventDefault();
    // console.log('Context menu event:', event);
    setContextMenuPosition({ x: event.pageX, y: event.pageY });
    // console.log('Setting context menu position to:', { x: event.pageX, y: event.pageY });
    setIsContextMenuVisible(true);
    // console.log('Context menu visibility set to true');
  };

  const handleClick = () => {
    // console.log('Click detected, hiding context menu');
    setIsContextMenuVisible(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    // console.log('isContextMenuVisible changed to:', isContextMenuVisible);
  }, [isContextMenuVisible]);

  const handleDeleteNode = () => {
    console.log("Deleting node:", nodeId);
    reactFlowInstance.setNodes((nds) =>
      nds.filter((node) => node.id !== nodeId)
    );
  };

  const handleDownloadNode = (e) => {
    // if (e) e.preventDefault();
    downloadFile(data["testNumber"]);
  };

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.key === 'Backspace' && isDynamicTableModalVisible) {
  //       event.preventDefault(); // Prevent the default backspace action
  //       event.stopPropagation();
  //     }
  //   };

  //   if (isDynamicTableModalVisible) {
  //     window.addEventListener('keydown', handleKeyDown);
  //   }

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [isDynamicTableModalVisible]);

  useEffect(() => {
    const updatedTotal = Object.values(sampleInputs).reduce(
      (sum, input) => sum + (input.usedQuantity || 0),
      0
    );
    setTotalUsedQuantity(updatedTotal);
  }, [sampleInputs]);

  // const totalUsedQuantity = sampleData.reduce((sum, sample) => {
  //   return sum + sample.UsedQuantity;
  // }, 0);

  console.log("1076", sampleData);

  return (
    <div
      className={
        isExpanded ? "text-updater-node-expanded" : "text-updater-node"
      }
      onContextMenu={handleContextMenu}
    >
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      {isContextMenuVisible && (
        <div
          ref={nodeRef}
          className="context-menu"
          style={{
            backgroundColor: "white",
            border: "1px solid black",
            zIndex: 1000,
          }}
        >
          <ContextMenu
            onDelete={handleDeleteNode}
            onDownload={handleDownloadNode}
          />
          {console.log("Context menu rendered")}
        </div>
      )}

      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault();
          if (approvedReportIndex !== -1) {
            downloadFile(reportLogData[approvedReportIndex].DocumentLink);
          }
        }}
      >
        {approvedReportIndex !== -1 ? (
          <Tag
            icon={<CheckCircleOutlined />}
            color="success"
            className="tag-hover"
          >
            Report Approved
          </Tag>
        ) : null}
      </div>

      <div className="wrapper">
        <div
          className="node-status"
          style={{ background: getStatusColor(status) }}
        ></div>
        <div className="inner-rect">
          <h1 className="TestNumber">
            {data["testNumber"]
              ? data["testNumber"].substring(0, 10)
              : data["testNumber"]}
          </h1>
        </div>
      </div>

      <div className="TestName">
        {data["testName"] ? (
          <h4>{data["testName"]}</h4>
        ) : (
          <Input
            placeholder="Enter test name"
            style={{ marginBottom: "2%" }}
            value={pdName}
            onChange={(e) => setPdName(e.target.value)}
          />
        )}
      </div>
      {job_details.state.from !== "NavBar" && (
        <div style={{ fontSize: "14px", color: "#888", padding: "8px 0" }}>
          <center>
            <strong>Start Date:</strong> {startDate}
            <span style={{ margin: "0 10px" }}> | </span>
            <strong>End Date:</strong> {endDate}
          </center>
        </div>
      )}

      {job_details.state.from !== "NavBar" && (
        <div style={{ fontSize: "14px", color: "#888", padding: "8px 0" }}>
          <center>Samples being used: {totalUsedQuantity}</center>
        </div>
      )}

      {job_details.state.from !== "NavBar" &&
        (userRole === "Project Leader" ||
          userRole === "Test Technician" ||
          userRole === "Engineer" ||
          userRole === "Admin") && (
          <>
            <button
              className="selectsample-button"
              onClick={handleSampleSelection}
            >
              Select Sample
            </button>
            <Modal
              title="Select Samples"
              visible={isSampleModalVisible}
              onCancel={() => setIsSampleModalVisible(false)}
              width={1200}
              pagination={{ pageSize: 5 }}
              footer={[
                <Button key="save" onClick={handleSaveAll}>
                  Save
                </Button>,
              ]}
            >
              <Table
                dataSource={sampleData}
                columns={sample_columns}
                pagination={{ pageSize: 5 }}
              />
            </Modal>
          </>
        )}

      {userRole === "Engineer" || userRole === "Cost Center Manager" ? null : (
        <button className="details-button" onClick={toggleExpansion}>
          {!isExpanded ? "Show Details" : "Hide Details"}
        </button>
      )}

      {isExpanded && (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Test Information" key="1">
            <div className="node-content-container">
              <div className="status-container">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    htmlFor="text"
                    style={{ marginTop: "0", marginRight: "10px" }}
                  >
                    Change Test Status:
                  </label>
                  <select
                    className="statusDropdown"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="" disabled selected>
                      Change Test Status
                    </option>
                    {testStatuses.map((testStatus) => (
                      <option key={testStatus} value={testStatus}>
                        {testStatus}
                      </option>
                    ))}
                  </select>
                </div>
                {status.includes("With Incident") && (
                  <>
                    <label
                      htmlFor="text"
                      style={{ fontSize: "15px", marginTop: "5px" }}
                    >
                      TIR #:
                    </label>
                    <Tooltip title="Please enter only integers">
                      <input
                        id="text"
                        name="text"
                        value={TIRNum}
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          const numericVal = inputVal.replace(/\D/g, "");
                          setTIRNum(numericVal);
                        }}
                        className="TIR"
                        maxLength={6}
                      />
                    </Tooltip>
                  </>
                )}
              </div>

              <div>
                <label htmlFor="text">Testing Instruction Note:</label>
                {userRole !== "Test Technician" ? (
                  <input
                    id="text"
                    name="text"
                    value={testingInstruction}
                    onChange={(e) => setTestingInstruction(e.target.value)}
                    className="nodrag"
                  />
                ) : (
                  <input
                    className="autofill"
                    value={testingInstruction}
                    readOnly
                    required
                  />
                )}
              </div>

              <div className="group-container">
                <div>
                  <label htmlFor="text">Start Date:</label>
                  {userRole !== "Test Technician" ? (
                    <input
                      type="date"
                      id="text"
                      name="text"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="nodrag"
                      style={{
                        border:
                          startDate === null
                            ? "1px solid orange"
                            : "100% solid black",
                      }}
                    />
                  ) : (
                    <input
                      className="autofill"
                      value={startDate}
                      readOnly
                      required
                    />
                  )}
                </div>
                <div>
                  <label htmlFor="text">End Date:</label>
                  {userRole !== "Test Technician" ? (
                    <input
                      type="date"
                      id="text"
                      name="text"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="nodrag"
                      style={{
                        border:
                          endDate === null
                            ? "1px solid orange"
                            : "100% solid black",
                      }}
                    />
                  ) : (
                    <input
                      className="autofill"
                      value={endDate}
                      readOnly
                      required
                    />
                  )}
                </div>
              </div>

              <div className="group-container">
                <div>
                  <label htmlFor="text">Test Duration:</label>
                  {userRole !== "Test Technician" ? (
                    <Tooltip title="Please enter only integers">
                      <input
                        id="text"
                        name="text"
                        placeholder="0"
                        value={testDuration}
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          const numericVal = inputVal.replace(/\D/g, "");
                          setTestDuration(numericVal);
                        }}
                        className="nodrag"
                      />
                    </Tooltip>
                  ) : (
                    <input
                      className="autofill"
                      value={testDuration}
                      readOnly
                      required
                    />
                  )}
                </div>
                <div>
                  <label htmlFor="text">Hours Estimation:</label>
                  {userRole !== "Test Technician" ? (
                    <Tooltip title="Please enter only integers">
                      <input
                        id="text"
                        name="text"
                        style={{
                          border:
                            hoursEstimation === "" || hoursEstimation === -1
                              ? "1px solid red"
                              : "100% solid black",
                        }}
                        placeholder="0"
                        value={hoursEstimation}
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          const numericVal = inputVal.replace(/\D/g, "");
                          setHoursEstimation(numericVal);
                        }}
                        className="nodrag"
                      />
                    </Tooltip>
                  ) : (
                    <input
                      className="autofill"
                      value={hoursEstimation}
                      readOnly
                      required
                    />
                  )}
                </div>
                <div>
                  <label htmlFor="text">Expense Estimation:</label>
                  {userRole !== "Test Technician" ? (
                    <Tooltip title="Please enter only integers">
                      <input
                        id="text"
                        style={{
                          border:
                            expenseEstimation === ""
                              ? "1px solid red"
                              : "100% solid black",
                        }}
                        name="text"
                        placeholder="0"
                        value={expenseEstimation}
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          const numericVal = inputVal.replace(/\D/g, "");
                          setExpenseEstimation(numericVal);
                        }}
                        className="nodrag"
                      />
                    </Tooltip>
                  ) : (
                    <input
                      className="autofill"
                      value={expenseEstimation}
                      readOnly
                      required
                    />
                  )}
                </div>
              </div>

              <div className="group-container">
                <div>
                  <label htmlFor="text">Fixture:</label>
                  {userRole !== "Test Technician" ? (
                    <select
                      id="fixture-select"
                      name="fixture"
                      value={fixture}
                      onChange={(e) => setFixture(e.target.value)}
                      className="nodrag"
                    >
                      <option value="">Select a fixture</option>
                      {fixtures.map((fix, index) => (
                        <option key={index} value={fix.name}>
                          {fix.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      className="autofill"
                      value={fixture}
                      readOnly
                      required
                    />
                  )}
                </div>
                <div>
                  <label htmlFor="text">Climatic Chamber:</label>
                  {userRole !== "Test Technician" ? (
                    <select
                      id="climatic-chamber-select"
                      name="climaticChamber"
                      value={climaticChamber}
                      onChange={(e) => setClimaticChamber(e.target.value)}
                      className="nodrag"
                    >
                      <option value="">Select a climatic chamber</option>
                      {climaticChambers.map((chamber, index) => (
                        <option key={index} value={chamber.name}>
                          {chamber.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      className="autofill"
                      value={climaticChamber}
                      readOnly
                      required
                    />
                  )}
                </div>
              </div>

              <div>
                {(userRole === "Project Leader" ||
                  userRole === "Admin" ||
                  userRole === "Test Technician") && (
                  <button
                    icon={<PaperClipOutlined />}
                    className="viewreport-button"
                    onClick={handleViewTestReport}
                  >
                    <PaperClipOutlined /> Files
                  </button>
                )}
                <Modal
                  title="Report Log"
                  visible={isReportModalVisible}
                  onCancel={() => setReportIsModalVisible(false)}
                  width={1000}
                  style={{ position: "relative" }}
                  footer={[
                    <div
                      key="footer"
                      style={{
                        width: 150,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div>
                        {isUploadingReport && <Spin />}
                        {isApprovedUpload && (
                          <Button onClick={handleReportUpload}>
                            Upload Report
                          </Button>
                        )}
                      </div>
                    </div>,
                  ]}
                >
                  <Table
                    dataSource={reportLogData}
                    columns={report_columns}
                    pagination={{ pageSize: 5 }}
                  />
                </Modal>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleReportFileChange}
                />
              </div>
            </div>
          </TabPane>

          <TabPane tab="Data" key="2">
            <Link
              to="/dataTable"
              state={{
                nodeID: nodeId,
                testName: data["testName"],
                testNumber: data["testNumber"].substring(0, 10),
              }}
            >
              <Tooltip title="Click to View T&E Data">
                <div
                  style={{
                    display: "inline-block",
                    color: hover ? "black" : "darkgrey",
                    backgroundColor: hover ? "#f0f0f0" : "transparent",
                    padding: "10px",
                    borderRadius: "8px",
                    position: "relative",
                    fontSize: "100px",
                    cursor: "pointer",
                    marginLeft: "38%",
                    transition: "all 0.3s ease",
                    boxShadow: hover ? "0 0 10px rgba(0, 0, 0, 0.15)" : "none",
                    lineHeight: "1.0",
                  }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onClick={showModal}
                >
                  <TableOutlined style={{ fontSize: "inherit" }} />
                  {hover && (
                    <ArrowsAltOutlined
                      style={{
                        position: "absolute",
                        color: "#FFBF00",
                        top: "52%",
                        left: "49%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "120px",
                        pointerEvents: "none",
                      }}
                      className={`tooltip-icon ${hover ? "grow" : ""}`}
                    />
                  )}
                </div>
              </Tooltip>
            </Link>
            <div
              style={{
                textAlign: "center",
                color: "black",
                marginBottom: "10px",
              }}
            >
              Click to View Data
            </div>

            <div>
              {(userRole === "Project Leader" || userRole === "Admin") && (
                <button
                  className="report-button"
                  onClick={handleTestReport}
                  disabled={isLoadingReport}
                >
                  {isLoadingReport ? "Generating..." : "Generate Report"}
                </button>
              )}
            </div>
          </TabPane>
        </Tabs>
      )}
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </div>
  );

  // return (
  //   <div
  //     className={isExpanded ? "text-updater-node-expanded" : "text-updater-node"}
  //     onContextMenu={handleContextMenu}
  //   >
  //     <Handle
  //       type="target"
  //       position={Position.Top}
  //       isConnectable={isConnectable}
  //     />
  //     {isContextMenuVisible && (
  //       <div
  //         ref={nodeRef}
  //         className="context-menu"
  //         style={{ backgroundColor: 'white', border: '1px solid black', zIndex: 1000 }}
  //       >
  //         <ContextMenu onDelete={handleDeleteNode} onDownload={handleDownloadNode} />
  //         {console.log('Context menu rendered')}
  //       </div>
  //     )}

  //     <div style={{ cursor: 'pointer' }} onClick={(e) => {
  //       e.preventDefault();
  //       if (approvedReportIndex !== -1) {
  //           downloadFile(reportLogData[approvedReportIndex].DocumentLink);
  //       }
  //   }}>
  //       {approvedReportIndex !== -1 ? (
  //           <Tag icon={<CheckCircleOutlined />} color="success" className="tag-hover">
  //               Report Approved
  //           </Tag>
  //       ) : null}

  //     {/* <Tag>{data.nodeID % moduloNodeId}</Tag> */}
  //   </div>
  //   <div className="wrapper">
  //     <div
  //       className="node-status"
  //       style={{ background: getStatusColor(status) }}
  //    ></div>
  //     <div className='inner-rect'>
  //       <h1 className="TestNumber">
  //         {data["testNumber"]
  //           ? data["testNumber"].substring(0, 10)
  //           : data["testNumber"]}
  //       </h1>
  //     </div>

  //   </div>
  //     <div className="TestName">
  //       {data["testName"] ? (
  //         <h4>{data["testName"]}</h4>
  //       ) : (
  //         <Input
  //           placeholder="Enter test name"
  //           style={{ marginBottom: '2%' }}
  //           value={pdName}
  //           onChange={(e) => setPdName(e.target.value)}
  //         />
  //       )}
  //     </div>
  //     <div style={{ fontSize: '14px', color: '#888', padding: '8px 0' }}>
  //       <center>
  //         <strong>Start Date:</strong> {startDate}
  //         <span style={{ margin: '0 10px' }}> | </span>
  //         <strong>End Date:</strong> {endDate}
  //       </center>

  //     </div>
  //     <div style={{ fontSize: '14px', color: '#888', padding: '8px 0' }}>
  //       <center>
  //         Samples being used: {totalUsedQuantity}
  //       </center>

  //     </div>

  //     <div>
  //       {isLoadingReport && (
  //         <div
  //           style={{
  //             display: "flex",
  //             justifyContent: "center",
  //             marginTop: "20px",
  //           }}
  //         >
  //           <Spin tip="Generating..." />
  //         </div>
  //       )}

  //       <Modal
  //         title="Report Log"
  //         visible={isReportModalVisible}
  //         onCancel={() => setReportIsModalVisible(false)}
  //         width={1000}
  //         style={{ position: 'relative' }}
  //         footer={[
  //           <div key="footer" style={{ width: 150, display: 'flex', justifyContent: 'flex-start' }}>
  //             <div>
  //               {isUploadingReport && <Spin />}
  //               {isApprovedUpload && (<Button onClick={handleReportUpload}>Upload Report</Button>)}

  //             </div>
  //           </div>,
  //         ]}
  //       >
  //         <Table
  //           dataSource={reportLogData}
  //           columns={report_columns}
  //           pagination={{ pageSize: 5 }}

  //         />
  //       </Modal>

  //       <input
  //         type="file"
  //         id="fileInput"
  //         style={{ display: "none" }}
  //         onChange={handleReportFileChange}
  //       />
  //       {/* <Button onClick={triggerFileInput}>Upload Document</Button> */}

  //       {/* <label htmlFor="text">Test Sample Size:</label> */}
  //       {userRole !== "Test Technician" ?
  //       <TextArea rows={1}
  //       placeholder="Enter sample size note here"
  //       style={{marginBottom : '2%'}}
  //         id="text"
  //         name="text"
  //         value={sampleSizeNote}
  //         onChange={(e) => setSampleSizeNote(e.target.value)}
  //         className="nodrag" />
  //       // <Input placeholder="Enter sample size note here"
  //       //   style={{marginBottom : '2%'}}
  //       //   id="text"
  //       //   name="text"
  //       //   value={sampleSizeNote}
  //       //   onChange={(e) => setSampleSizeNote(e.target.value)}
  //       //   className="nodrag"
  //       // />
  //       // <input style={{width: "99.5%"}}
  //       //   placeholder="Enter Sample Size Note Here"
  //       //   id="text"
  //       //   name="text"
  //       //   value={sampleSizeNote}
  //       //   onChange={(e) => setSampleSizeNote(e.target.value)}
  //       //   className="nodrag"
  //       // />
  //       : <input className="autofill" style={{width: "99.5%"}} value={sampleSizeNote} readonly required/>}
  //     </div>
  //     {(userRole === "Project Leader" || userRole === "Test Technician" ||  userRole === "Engineer" || userRole === "Admin") && (
  //         <>
  //           <button className="selectsample-button" onClick={handleSampleSelection}>
  //             Select Sample
  //           </button>
  //           <Modal
  //             title="Select Samples"
  //             visible={isSampleModalVisible}
  //             onCancel={() => setIsSampleModalVisible(false)}
  //             width={1200}
  //             agination={{ pageSize: 5 }}
  //             footer={[
  //               <div key="footer" style={{ width: 150, display: 'flex', justifyContent: 'flex-start' }}>
  //                 <div>
  //                   <Button onClick={handleSaveAll}>Save</Button>
  //                 </div>
  //               </div>,
  //             ]}
  //           >
  //           <Table
  //             dataSource={sampleData}
  //             columns={sample_columns}
  //             pagination={{ pageSize: 5 }}
  //           />
  //         </Modal>
  //         </>
  //           )}

  //     {/* <div
  //       className="node-status"
  //       style={{ background: getStatusColor(status) }}
  //     ></div>  */}

  //     {userRole === "Engineer" || userRole === "Cost Center Manager" ? null : (
  //       <button className="details-button" onClick={toggleExpansion}>
  //         {!isExpanded ? "Show Details" : "Hide Details"}
  //       </button>
  //     )}

  //     {isExpanded && (
  //     <Tabs defaultActiveKey="1">
  //     <TabPane tab="Test Information" key="1">
  //     <div className='node-content-container'>
  //         <div className='node-inside-btn-container'>
  //         </div>
  //         <div className='status-container'>
  //         <div style={{display: 'flex', alignItems: 'center'}}>
  //               <label htmlFor="text" style={{marginTop: '0', marginRight: '10px'}}>Change Test Status:</label>
  //               <select className='statusDropdown' value={status}  onChange={handleStatusChange}>
  //                 <option value="" disabled selected>
  //                   Change Test Status
  //                 </option>
  //                 {testStatuses.map((testStatus) => (
  //                   <option key={testStatus} value={testStatus}>
  //                     {testStatus}
  //                   </option>
  //                 ))}
  //               </select>
  //             </div>
  //             {status.includes("With Incident") && (
  //               <>
  //                 <label htmlFor="text" style={{fontSize: '15px', marginTop: "5px"}}>
  //                   TIR #:
  //                 </label>
  //                 <Tooltip title="Please enter only integers">
  //                   <input
  //                     id="text"
  //                     name="text"
  //                     value={TIRNum}
  //                     onChange={(e) => {
  //                       const inputVal = e.target.value;
  //                       const numericVal = inputVal.replace(/\D/g, '');
  //                       setTIRNum(numericVal);
  //                     }}
  //                     className="TIR"
  //                     maxLength={6}
  //                   />
  //                 </Tooltip>

  //               </>
  //             )}

  //         </div>

  //         <div>
  //           <label htmlFor="text">Testing Instruction Note:</label>
  //           {userRole !== "Test Technician" ? (<input
  //             id="text"
  //             name="text"
  //             value={testingInstruction}
  //             onChange={(e) => setTestingInstruction(e.target.value)}
  //             className="nodrag"
  //           />) : <input className="autofill" value={testingInstruction} readonly required/>}

  //         </div>
  //         <div className="group-container">
  //           <div>
  //             <label htmlFor="text">Start Date:</label>
  //             {userRole !== "Test Technician" ? <input
  //               type="date"
  //               id="text"
  //               name="text"
  //               value={startDate}
  //               onChange={(e) => setStartDate(e.target.value)}
  //               className="nodrag"
  //               style={{ border: startDate === null ? '1px solid orange' : '100% solid black' }}
  //             />: <input className="autofill" value={startDate} readonly required/>}
  //           </div>
  //           <div>
  //             <label htmlFor="text">End Date:</label>
  //             {userRole !== "Test Technician" ?<input
  //               type="date"
  //               id="text"
  //               name="text"
  //               value={endDate}
  //               onChange={(e) => setEndDate(e.target.value)}
  //               className="nodrag"
  //               style={{ border: endDate === null ? '1px solid orange' : '100% solid black' }}
  //             />: <input className="autofill" value={endDate} readonly required/>}
  //           </div>
  //         </div>
  //         <div className="group-container">
  //           <div>
  //             <label htmlFor="text">Test Duration:</label>
  //             {userRole !== "Test Technician" ?
  //             <Tooltip title="Please enter only integers">
  //               <input
  //                 id="text"
  //                 name="text"
  //                 value={testDuration}
  //                 // onChange={(e) => setTestDuration(e.target.value)}
  //                 onChange={(e) => {
  //                   const inputVal = e.target.value;
  //                   const numericVal = inputVal.replace(/\D/g, '');
  //                   setTestDuration(numericVal);
  //                 }}
  //                 className="nodrag"
  //               />
  //             </Tooltip>
  //             :
  //             <input className="autofill" value={testDuration} readonly required/>}
  //           </div>
  //           <div>
  //             <label htmlFor="text">Hours Estimation:</label>
  //             {userRole !== "Test Technician" ?
  //             <Tooltip title="Please enter only integers">
  //               <input
  //                 id="text"
  //                 name="text"
  //               style={{ border: hoursEstimation === '' || hoursEstimation === -1 ? '1px solid red' : '100% solid black' }}
  //                 value={hoursEstimation}
  //                 // onChange={(e) => setHoursEstimation(e.target.value)}
  //                 onChange={(e) => {
  //                   const inputVal = e.target.value;
  //                   const numericVal = inputVal.replace(/\D/g, '');
  //                   setHoursEstimation(numericVal);
  //                 }}
  //                 className="nodrag"
  //               />
  //             </Tooltip>
  //             :
  //             <input className="autofill" value={hoursEstimation} readonly required/>}
  //           </div>
  //           <div>
  //             <label htmlFor="text">Expense Estimation:</label>
  //             {userRole !== "Test Technician" ?
  //             <Tooltip title="Please enter only integers">
  //               <input
  //                 id="text"
  //               style={{ border: expenseEstimation === '' ? '1px solid red' :  '100% solid black' }}
  //                 name="text"
  //                 value={expenseEstimation}
  //                 // onChange={(e) => setExpenseEstimation(e.target.value)}
  //                 onChange={(e) => {
  //                   const inputVal = e.target.value;
  //                   const numericVal = inputVal.replace(/\D/g, '');
  //                   setExpenseEstimation(numericVal);
  //                 }}
  //                 className="nodrag"
  //               />
  //             </Tooltip>
  //             :
  //             <input className="autofill" value={expenseEstimation} readonly required/>}
  //           </div>

  //         </div>
  //         <div className="group-container">
  //           <div>
  //             <label htmlFor="text">Fixture:</label>
  //             {/* {userRole !== "Test Technician" ?<input
  //               id="text"
  //               name="text"
  //               value={fixture}
  //               onChange={(e) => setFixture(e.target.value)}
  //               className="nodrag"
  //             />: <input className="autofill" value={fixture} readonly required/>} */}
  //             {userRole !== "Test Technician" ? (
  //                 <select
  //                   id="fixture-select"
  //                   name="fixture"
  //                   value={fixture}
  //                   onChange={(e) => setFixture(e.target.value)}
  //                   className="nodrag"
  //                 >
  //                   <option value="">Select a fixture</option>
  //                   {fixtures.map((fix, index) => (
  //                     <option key={index} value={fix.name}>{fix.name}</option>
  //                   ))}
  //                 </select>
  //               ) : (
  //                 <input
  //                   type="text"
  //                   className="autofill"
  //                   value={fixture}
  //                   readOnly
  //                   required
  //                 />
  //               )}
  //           </div>
  //           <div>
  //             <label htmlFor="text">Climatic Chamber:</label>
  //             {userRole !== "Test Technician" ? (
  //               <select
  //                 id="climatic-chamber-select"
  //                 name="climaticChamber"
  //                 value={climaticChamber}
  //                 onChange={(e) => setClimaticChamber(e.target.value)}
  //                 className="nodrag"
  //               >
  //                 <option value="">Select a climatic chamber</option>
  //                 {/* {climaticChambers.map((chamber, index) => (
  //                   <option key={index} value={chamber}>{chamber}</option>
  //                 ))} */}
  //                 {climaticChambers.map((chamber, index) => (
  //                   <option key={index} value={chamber.name}>{chamber.name}</option>
  //                 ))}

  //               </select>
  //             ) : (
  //               <input
  //                 type="text"
  //                 className="autofill"
  //                 value={climaticChamber}
  //                 readOnly
  //                 required
  //               />
  //             )}

  //           </div>
  //         </div>
  //         <div>
  //       {isApprovedUpload && (userRole === 'Project Leader' || userRole === 'Admin') && logData.length !== 0 && (
  //         <button
  //           className="report-button"
  //           onClick={handleTestReport}
  //           disabled={isLoadingReport}
  //         >
  //           {isLoadingReport ? "Generating..." : "Generate Report"}
  //         </button>
  //       )}
  //       {(userRole === 'Project Leader' || userRole === 'Admin') && logData.length !== 0 && (<button className="viewreport-button" onClick={handleViewTestReport}>
  //         Add Files
  //       </button>)}
  //         </div>

  //         <div>
  //             <>
  //               <button className="upload-button" onClick={handleTestUpload} disabled={isLoading}>
  //                 {isLoading ? 'Uploading...' : 'Upload Test Result'}
  //               </button>
  //               <input
  //                 type="file"
  //                 accept=".csv, .xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //                 style={{ display: 'none' }}
  //                 ref={fileInputRef}
  //                 onChange={handleFileChange}
  //               />

  //             </>

  //         </div>
  //         <div>
  //           {logData.length !== 0 && (
  //             <>
  //               <button className="testlog-button" onClick={handleTestLog}>
  //                 View Test Result Log
  //               </button>
  //             </>
  //           )}
  //           <Modal
  //             title="Test Result Log"
  //             visible={isModalVisible}
  //             onCancel={() => setIsModalVisible(false)}
  //             width={700}
  //             footer={null}
  //           >
  //             <Table
  //               dataSource={logData}
  //               columns={columns}
  //               pagination={{ pageSize: 5 }}
  //               size='small'
  //             />
  //           </Modal>
  //         </div>
  //         {isLoading && (
  //             <div
  //               style={{
  //                 display: "flex",
  //                 justifyContent: "center",
  //                 marginTop: "20px",
  //               }}
  //             >
  //               <Spin tip="Uploading..." />
  //             </div>
  //           )}
  //       </div>
  //       </TabPane>
  //       <TabPane tab="Data" key="2">
  //       <Link to="/dataTable" state={{
  //         // ref: tableRef,
  //         nodeID: nodeId,
  //         testName: data["testName"],
  //         testNumber: data["testNumber"].substring(0, 10),
  //       }}>
  //         <Tooltip title="Click to View T&E Data">
  //             <div
  //                 style={{
  //                     display: 'inline-block',
  //                     color: hover ? 'black' : 'darkgrey',
  //                     backgroundColor: hover ? '#f0f0f0' : 'transparent',
  //                     padding: '10px',
  //                     borderRadius: '8px',
  //                     position: 'relative',
  //                     fontSize: '100px',
  //                     cursor: 'pointer',
  //                     marginLeft: '38%',
  //                     transition: 'all 0.3s ease',
  //                     boxShadow: hover ? '0 0 10px rgba(0, 0, 0, 0.15)' : 'none',
  //                     lineHeight: '1.0'
  //                 }}
  //                 onMouseEnter={() => setHover(true)}
  //                 onMouseLeave={() => setHover(false)}
  //                 onClick={showModal}
  //             >
  //                 <TableOutlined style={{ fontSize: 'inherit' }} />
  //                 {hover && (
  //                     <ArrowsAltOutlined
  //                         style={{
  //                             position: 'absolute',
  //                             color: '#FFBF00',
  //                             top: '52%',
  //                             left: '49%',
  //                             transform: 'translate(-50%, -50%)',
  //                             fontSize: '120px',
  //                             pointerEvents: 'none'
  //                         }}
  //                         className={`tooltip-icon ${hover ? 'grow' : ''}`}
  //                     />
  //                 )}
  //             </div>
  //         </Tooltip>
  //         </Link>
  //         <div style={{ textAlign: 'center', color: 'black', marginBottom: '10px' }}>
  //             Click to View Data
  //         </div>
  //         {/* <Modal
  //             visible={isDynamicTableModalVisible}
  //             onOk={handleOk}
  //             onCancel={handleCancel}
  //             width={'100%'}
  //             bodyStyle={{
  //               height: '70vh',
  //           }}
  //             footer={[
  //                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
  //                     <Button style={{width: "40%"}} key="back" size="small" onClick={handleCancel}>
  //                         Cancel
  //                     </Button>
  //                     <Button style={{width: "40%"}} key="submit" size="small" type="primary" onClick={handleOk}>
  //                         Update
  //                     </Button>
  //                 </div>
  //             ]}
  //         >
  //             <div style={{ maxHeight: '65vh', overflow: 'auto' }}>
  //               <DynamicTable ref={tableRef} nodeID={nodeId} testName={data["testName"]} testNumber={data["testNumber"].substring(0, 10)} />
  //             </div>
  //         </Modal> */}
  //         {/* <center style={{marginTop: '10px', marginBottom: '20px'}}>Feature Coming Soon</center> */}
  //     </TabPane>

  //     </Tabs>
  //     )}
  //     <Handle
  //       type="source"
  //       position={Position.Bottom}
  //       id="b"
  //       isConnectable={isConnectable}
  //     />

  //   </div>

  // );
}

export default UniqueTest;
