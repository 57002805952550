// src/customFetch.js
export const customFetch = async (url, options = {}) => {
    const token = localStorage.getItem('token');
    // console.log('token is: ', token);
    
    const defaultHeaders = {
      ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
      // ...(options.headers && options.headers['Content-Type'] ? {} : { 'Content-Type': 'application/json' }),
    };
    
    const mergedOptions = {
      ...options,
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
    };
    
    const response = await fetch(url, mergedOptions);
    return response;
  };
  