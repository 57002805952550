import React from 'react';
import {Link} from 'react-router-dom';
import './CreateNewJob.css'
import { PlusSquareOutlined } from '@ant-design/icons';

function CreateNewJob() {
    // Retrieve user role from localStorage
    const userRole = localStorage.getItem('userRole');

    return (
    <div>
      {userRole === 'Engineer' && (
        <Link to="/addjob">
          <button id="new-job">
          <PlusSquareOutlined /> Create New Job
          </button>
        </Link>
      )}
      {userRole === 'Admin' && (
        <Link to="/addjob">
          <button id="new-job">
          <PlusSquareOutlined /> Create New Job
          </button>
        </Link>
      )}

      {/* {userRole === 'Project Leader' && (
              <Link to="/addjob">
                <button id="new-job">
                <PlusSquareOutlined /> Create New Job
                </button>
              </Link>
            )} */}
    </div>
    );
  }

  export default CreateNewJob;