import React, { useState, useEffect } from "react";
import { Button, Card, Divider, Input, Spin } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, MailOutlined, KeyOutlined } from "@ant-design/icons";
import axios from "axios";

export const Login = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const handle_submit = async (e) => {
    e.preventDefault();
    setIsLoading(true); 
    try {
      
      // const response = await axios.post("http://localhost:5001/login", {
      const response = await axios.post("https://zwewaetojsbe.azurewebsites.net/login", {
        email,
        password: pass,
      // });
      }, { withCredentials: true });

      if (response.data.success) {
        const expirationTime = new Date().getTime() + 600000; // 10 minutes
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('expirationTime', expirationTime);
        const userRole = response.data.role; 
        const userName = response.data.name; 
        const userEmail = response.data.email;
        localStorage.setItem('userRole', userRole); 
        localStorage.setItem('userName', userName); 
        localStorage.setItem('userEmail', userEmail);
        // Pass token and role to App.js
        props.onLogin(response.data.token, userRole); 

        window.location.reload();
      } 
      else if(response.data.message == "Account not verified"){
        console.log(response.data.message);
        console.log("login jsx, reponse as account not verified");
        setErrorMessage(response.data.message);
        // console.log("test: not verified");
        props.onVerifyForm(email);
      }
      
      
      else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handle_reset = async (e) => {
    e.preventDefault();
    props.onReset();
  }


  useEffect(() => {
    const token = localStorage.getItem('token');
    const expirationTime = localStorage.getItem('expirationTime');
    console.log("loginjsx, the local storage token is: ", token);

    // Check if token and expiration time are both valid
    if (token && expirationTime && new Date().getTime() < expirationTime) {
      props.onLogin();
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
    }
  }, []);

  return (
    
    <div className="login-container">
      
      <Card className="auth-form-card">
      <img className="logo-img" src="./magna-logo-colored.png" alt="Logo" />
        <Divider className="login-divider">
          ETO Login
        </Divider>

        <form className="login-form" onSubmit={handle_submit}>
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <Input
            prefix={<MailOutlined />}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="firstname.lastname@magna.com"
            id="email"
            name="email"
            className="form-input"
          />
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <Input.Password
            value={pass}
            prefix={<KeyOutlined />}
            onChange={(e) => setPass(e.target.value)}
            type="password"
            placeholder="**********"
            id="password"
            name="password"
            className="form-input"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {errorMessage && 
            (<div className="error-message">{errorMessage}</div>
        )}
        {isLoading ? (
            <div className="loading-overlay">
              <Spin tip="Logging in..." size="large">
                      {/* <div className="content" /> */}
              </Spin>
            </div>
          ) :
          <>
          <Button className="submit-btn"
                  type="primary"
                  onClick={handle_submit}>
            Log In
          </Button> 
          <Button
          className="register-btn"
          onClick={() => props.onFormSwitch("register")}
          >
            Don't have an account? Register Here
          </Button>
          <p
            className="password-reset"
            onClick={handle_reset}
          >
            Forgot your password? Click Here
          </p>
          </>}
        </form>

        
      </Card>
    </div>
  );
};