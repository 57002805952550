
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card } from "antd";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import {
  useNavigate
} from "react-router-dom";


function VerificationPage({ onVerify, onResend }) {
  const [form] = Form.useForm();
  const [code, setCode] = useState("");

  const navigate = useNavigate();
  const handleVerify = () => {
    onVerify(code);
  };
  const handleResend = () => {
    onResend();
    form.resetFields();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card style={{ width: "450px" }}>
        {" "}
        <Form form={form} layout="vertical" onFinish={handleVerify}>
          <Form.Item
            label="A Confirmation Code is sent to your Magna Email"
            name="code"
            rules={[
              {
                required: true,
                message: "Please input your confirmation code!",
              },
            ]}
          >
            <Input style={{ width: "400px" }}
              prefix={<KeyOutlined />}
              placeholder="Enter your code"
              onChange={(e) => setCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button className="submit-btn" style={{ width: "200px" }} type="primary" htmlType="submit" block>
              Verify 
            </Button>
          
            <Button className="register-btn" style={{ width: "200px" }} onClick={handleResend} block>
              Resend 
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
export default VerificationPage;

