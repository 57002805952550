import React, { useState, useEffect, useRef } from "react";
import { Login } from "./Login";
import { Register } from "./Register";
import { Reset } from "./Reset";
import { Profile } from "./Profile";
import { ResetVerification } from "./ResetVerification";
import { ResetPassword } from "./ResetPassword";
import Verification from "./Verification";
import Filter from "./allJobs/Filter";
import AddJob from "./addJob/AddJob";
import Flow from "./flowChart/Flow";
import NavBar from "./NavBar/NavBar";
import ManageUsers from './ManageUsers';
import AdminPanel from "./AdminPanel";
import axios from './axiosConfig'; 
// import axios from "axios";
// import HeatMapTracker from './heatMap';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Redirect,
  useNavigate,
  useLocation, 
  useParams,
} from "react-router-dom";

import { notification, message } from 'antd';
import "./App.css";
import DynamicTable from "./flowChart/DynamicTable3";

const BASE_URL = "https://zwewaetojsbe.azurewebsites.net";
const PY_API_URL = process.env.REACT_APP_PY_API_URL;

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentForm, setCurrentForm] = useState("login");

  const navigate = useNavigate();

 // States for verification
  const [isVerifying, setIsVerifying] = useState(false);

  const [isReset, setIsReset] = useState(false);
  const [isResetVerify, setIsResetVerify] = useState(false); //isResetPassword
  const [isResetPassword, setIsResetPassword] = useState(false);

  const [confirmationCode, setConfirmationCode] = useState("");
  // const [resetConfirmationCode, setResetConfirmationCode] = useState("");
  

  const [codeValidUntil, setCodeValidUntil] = useState("");
  const [userEmail, setUserEmail] = useState(''); // State to store the email
  const [userResetEmail, setUserResetEmail] = useState(''); // State to store the email

  // const heatmapRef = useRef(null);
  // const location = useLocation();

  // useEffect(() => {
  //   const pageName = location.pathname.replace('/', '') || 'Home';
  //   if (heatmapRef.current) {
  //     heatmapRef.current.saveHeatmapToLocalStorage(pageName);
  //     heatmapRef.current.resetHeatmap();
  //   }
  // }, [location]);

 
  // const handleLogin = (token, userRole) => {
  //   //
  //   // localStorage.setItem('token', token);
  //   // localStorage.setItem('userRole', userRole); // Store user role
  //   // console.log("handleLogin, the local storage of token is: ", token);
  //   // console.log("handleLogin, the local storage of userRole is: ", userRole);
  //   //
  //   setIsLoggedIn(true);
  //   navigate('/');
  // };
  

  const handleLogin = () => {
    ////////////////////////////////////////////////////
    // localStorage.setItem('token', token);  
    // const token = localStorage.getItem('token');
    // console.log("handleLogin, the local storage token is: ", token); 
    // localStorage.setItem('userRole', userRole);  
    setIsLoggedIn(true);
    ////////////////////////////////////////////////////
    navigate('/');
    // window.location.reload();
    console.log("setting is logged in true");
    
  };
  

  useEffect(() => {
    // This effect runs whenever `isLoggedIn` changes.
    if (isLoggedIn) {
      // Check if the page has already been reloaded to prevent infinite loop
      const hasReloaded = sessionStorage.getItem('hasReloaded');

      if (!hasReloaded) {
        sessionStorage.setItem('hasReloaded', 'true'); // Set the flag
        
        // Perform any actions you need to do only on login
        navigate('/'); // Navigate if needed
        
        // Reload the page
        // window.location.reload();
      }
    } else {
      // Optionally, when logging out or when isLoggedIn is false, 
      // you can remove the flag to allow the process to happen again on next login.
      sessionStorage.removeItem('hasReloaded');
    }
  }, [isLoggedIn, navigate]);
  
  // const handleLogout = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("expirationTime");
  //   localStorage.removeItem("userRole");
  //   localStorage.removeItem("userName");
  //   localStorage.removeItem("userEmail");
  //   localStorage.removeItem("hasNotificationShown");
  //   localStorage.removeItem("homepage_clicks");
  //   localStorage.removeItem("addjob_clicks");
  //   localStorage.removeItem("flowchart_clicks");
  //   setIsLoggedIn(false); // Update state to reflect logout
  // };
  const handleLogout = async () => {
    try {
      const homepageClicks = JSON.parse(localStorage.getItem("homepage_clicks") || "[]");
      const addJobClicks = JSON.parse(localStorage.getItem("addjob_clicks") || "[]");
      const flowchartClicks = JSON.parse(localStorage.getItem("flowchart_clicks") || "[]");
      const homepageMouseTrack = JSON.parse(localStorage.getItem("homepage_tracker") || "[]");
      const addJobMouseTrack = JSON.parse(localStorage.getItem("addjob_tracker") || "[]");
      const flowchartMouseTrack = JSON.parse(localStorage.getItem("flowchart_tracker") || "[]");
      const token = localStorage.getItem('token');
      const name = localStorage.getItem('userName');
      const heatmapData = [homepageClicks, addJobClicks, flowchartClicks, homepageMouseTrack, addJobMouseTrack, flowchartMouseTrack];
      // Clear local storage
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      localStorage.removeItem("userRole");
      localStorage.removeItem("userName");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("hasNotificationShown");
      localStorage.removeItem("homepage_clicks");
      localStorage.removeItem("addjob_clicks");
      localStorage.removeItem("flowchart_clicks");
      localStorage.removeItem("homepage_tracker");
      localStorage.removeItem("flowchart_tracker");
      localStorage.removeItem("addjob_tracker");
      localStorage.removeItem('filterNumber');
      localStorage.removeItem('job_details');
      localStorage.removeItem('originalRole');
      
      // Update state to reflect logout
      setIsLoggedIn(false);
      const response = await fetch(`${PY_API_URL}/api/generate_heatmap`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        },
        body: JSON.stringify({
          heatmap_data: heatmapData,
          name: name,
        }),
      });
  
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message || 'Failed to upload heatmap');
      }
      console.log("heatmap saved");
  
    } catch (error) {
      console.error('Error during logout:', error);
    }
    
  };
  

  const handleRegister = async (email, confirmationCode, codeValidUntil) => {
    // generateConfirmationCode();
    
    setUserEmail(email);
    setConfirmationCode(confirmationCode);
    setCodeValidUntil(Date.now() + 2 * 60000); // valid for 2 minutes
    setIsVerifying(true);

    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");

    setIsLoggedIn(false);
    
  };

  const handleVerifyCode = async (enteredCode) => {
    console.log("The confirmation code is:", confirmationCode);

    if (Date.now() > codeValidUntil) {
      alert("Code expired. Please click resend.");
      return;
    }

    if (enteredCode === confirmationCode) {
      // Make request to /verify endpoint with stored email
      console.log("entered confirmation code correctly");
      try {
        const response = await fetch(`${BASE_URL}/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userEmail,
            verificationCode: enteredCode,
          }),
        });
        
        const data = await response.json();
        if (data.success) {
          // alert("Success! Logging you in...");
          localStorage.removeItem('userEmail');
          setIsVerifying(false);
          message.success('Verification Success. Please login again')
          setCurrentForm('login');
          // setIsLoggedIn(true);
          // navigate('/');
        } else {
          alert(data.message);
        }
        
      } catch (error) {
        console.error("Verification failed", error);
      }
    } else {
      alert("Incorrect code. Please try again.");
    }
  };

  const handleResetVerifyCode = async (enteredCode) => {

    console.log("handleResetVerifyCode, the date now is:", Date.now());
    console.log("handleResetVerifyCode, the code is valid until: ", codeValidUntil);
    console.log("the correct code should be: ", confirmationCode);

    if (Date.now() > codeValidUntil) {
      alert("Code expired. Please click resend.");
      return;
    }
    if (enteredCode != confirmationCode) {
      alert("Incorrect Code. Please try again");
      return;
    }

    if (enteredCode === confirmationCode) {
      // Make request to /verify endpoint with stored email
      console.log("entered confirmation code correctly");
      console.log("IsResetPassword is: ", isResetPassword);
      setIsResetPassword(true);
    } else {
      alert("Incorrect code. Please try again.");
    }
  };

  const handleReset =() => {
    setIsReset(true);
  }

  const setVerifyForm =(email) => {
    setUserEmail(email);
    setIsVerifying(true);
  }
  const handleResetVerify = async(resetEmail, confirmationCode) => {

    setConfirmationCode(confirmationCode);
    setUserResetEmail(resetEmail);
    //
    setCodeValidUntil(Date.now() + 2 * 60000); // valid for 2 minutes
    //
    setIsResetVerify(true);
  }


  const handleResetPassword = async (pass) => {
    console.log("handleResetPassword, the userResetEmail is: ", userResetEmail);
    console.log("handleResetPassword, the pass is: ", pass);
    try {
        const response = await axios.post(`${BASE_URL}/resetpass`, {
          email: userResetEmail,
          pass
        });
        if (response.data.success) {
          setIsVerifying(false);
          setIsReset(false);
          setIsResetPassword(false);
          setIsResetVerify(false);
          message.success('Sucessfully reset password. Please login again')
          // setIsLoggedIn(true);
          // navigate("/Login")
        }
        
      } catch (error) {
        console.log(error.message);
      }
  
  };
  
  const handleResendCode = async() => {
    // generateConfirmationCode();
    const confirmationCode = Math.random().toString(36).substr(2, 6).toUpperCase();
    setConfirmationCode(confirmationCode);
    setCodeValidUntil(Date.now() + 2 * 60000); // valid for 2 minutes
    console.log("handleResendCode, the confirmationCode is: ", confirmationCode);
    try {
      const response = await fetch(`${BASE_URL}/resend`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userEmail,
          confirmationCode
        }),
      });
      
    } catch (error) {
      console.error("Verification failed", error);
    }
    
 
  };


  // Check if the user is an admin
  const isAdmin = () => {
    const userRole = localStorage.getItem('userRole');
    console.log("the user role is: ", userRole);
    return userRole === 'Admin';
  };

  useEffect(() => {
    if (isLoggedIn && !isAdmin()) {
      navigate('/');
    }
  }, [isLoggedIn]); 

  
  const showNotification = () => {
    setTimeout(() => {
    const hasNotificationShown = localStorage.getItem('hasNotificationShown');
  
    if (!hasNotificationShown) {
      
        const userName = localStorage.getItem('userName');
        const userRole = localStorage.getItem('userRole');
        notification.info({
          message: `Hello, ${userName}`,
          description: `${userRole}`,
          placement: 'topLeft',
          duration: 2,
        });
  
        // Set the flag in localStorage to indicate that the notification has been shown
        localStorage.setItem('hasNotificationShown', true);
    }
  }, 500);
  };

  const location = useLocation();

  useEffect(() => {
    // Extract number from URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const filterNumber = queryParams.get('filterNumber');

    if (filterNumber) {
      // Remove existing filterNumber from localStorage if it exists
      localStorage.removeItem('filterNumber');

      // Save the new filterNumber to localStorage
      localStorage.setItem('filterNumber', filterNumber);
    }
  }, [location]);
  
  if (isLoggedIn) {

    return (
      <div className="app-container">
        {/* <HeatMapTracker ref={heatmapRef} /> */}
        <NavBar onLogout={handleLogout} />
        {showNotification()}
        <Routes>
          <Route path='/addjob' element={<AddJob/>} />
          <Route path='/users' element={isAdmin() ? <ManageUsers/> : <div>Access Denied</div>} />
          <Route path='/adminPanel' element={isAdmin() ? <AdminPanel/> : <div>Access Denied</div>} />
          <Route path='/' element={<div className="App"><Filter /></div>} />
          <Route path='/flowchart' element={<Flow/>} />
          <Route path='/profile' element={<Profile/>} />
          <Route path='/dataTable' element={<DynamicTable/>} />
        </Routes>
      </div>
    );
  }
  else {
    if(isVerifying) {
      return (
        <Verification
          onVerify={handleVerifyCode}
          onResend={handleResendCode}
          codeValidUntil={codeValidUntil}
        />
      );
    }
    while(isReset) {
      while(isResetVerify && !isResetPassword){
        return (
          <ResetVerification 
            onVerify={handleResetVerifyCode}
            onResend={handleResendCode}
            codeValidUntil={codeValidUntil}
          />
        );
      }
      while (isResetPassword) {
        return <ResetPassword 
          onResetPassword={handleResetPassword}
        />;
      }
      
      return(<Reset
        onResetVerify={handleResetVerify}
      />);
         
    }
    while (!isVerifying && !isReset) {
      return (
        // Login/Registration Pages
        <div className="App">
          {currentForm === "login" ? (
            <Login 
              onFormSwitch={setCurrentForm} 
              onLogin={handleLogin} 
              onVerifyForm={setVerifyForm}
              onReset={handleReset}
              />
          ) : (
            <Register
              onFormSwitch={setCurrentForm}
              onRegister={handleRegister}
            />
          )}
        </div>
    );
    } 
  }
};
export default App;
