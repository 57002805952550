
import React, { useState } from "react";
import { Form, Input, Button, Card } from "antd";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";

export const Reset = (props) => {
  const [form] = Form.useForm();
  const [resetEmail, setResetEmail] = useState("");


  const handleReset = async (e) => {
    // Confirmation Code generation
    const confirmationCode = Math.random().toString(36).substr(2, 6).toUpperCase();
    console.log("handleReset resetEmail is:", resetEmail);

    try {
      const response = await axios.post("https://zwewaetojsbe.azurewebsites.net/resetconfirm", {
        resetEmail, 
        confirmationCode
      });
      if (response.data.success) {
        props.onResetVerify(resetEmail, confirmationCode);
      } 
      else {
        console.log(response.data.message);
        alert(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
    //   alert(error.message);
    }
    
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card style={{ width: "450px" }}>
        {" "}
        <Form form={form} layout="vertical" onFinish={handleReset}>
          <Form.Item
            label="Please Enter the Email associated with your ETO account"
            name="resetEmail"
            rules={[
              {
                required: true,
                message: "Please input your Magna Email",
              },
            ]}
          >
            <Input style={{ width: "400px" }}
              prefix={<MailOutlined />}
              placeholder="Enter your Magna Email"
              onChange={(e) => setResetEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button className="submit-btn" style={{ width: "150px" }} type="primary" htmlType="submit" block>
              Continue 
            </Button>
          
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
export default Reset;
