import React, { useState, useEffect, useMemo  } from 'react';
import { Table, Tag, Button, Tooltip  } from 'antd';
import { ExclamationCircleOutlined, WarningOutlined,  CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
 

const IncompleteNodesSidebar = ({ nodes, edges, onTestClick, onNodesUnfilled}) => {
  const [sidebarWidth, setSidebarWidth] = useState(150);
  const [isCollapsed, setIsCollapsed] = useState(false); 
  const [incompleteNodes, setIncompleteNodes] = useState([]);
  // console.log(incompleteNodes[0].node.data.hoursEstimation === '');
  const handleTestClick = (node) => {
    if (onTestClick) {
      onTestClick(node);
    }
  };

  const handleNodesUnfilled = (nodes) => {
    if (onNodesUnfilled) {
      onNodesUnfilled(nodes);
    }
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    setSidebarWidth(isCollapsed ? 150 : 0);
  };

  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!isCollapsed) {
      setSidebarWidth(e.clientX);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  // const incompleteNodes = nodes.filter(node => 
  //   !node.data.expenseEstimation || !node.data.hoursEstimation
  // ).map((node, index) => ({
  //   key: index,
  //   testName: node.data.testName,
  //   testNumber: node.data.testNumber,
  //   hoursEstimation: node.data.hoursEstimation,
  //   node: node,
  // }));

  const irregularDatesNodes = [];
  
  const checkDates = (source,target) => {
    for (let i = 0; i < nodes.length; i++){
      if (nodes[i].id === source){
        for (let j=0; j < nodes.length; j++){
          if(nodes[j].id === target){
              if (nodes[j].data.startDate < nodes[i].data.endDate){
                return false;
              }
              else{
                return true;
              };
          };
        };
      };
    };
  };

  
  const checkAllNodes = () => {
    for (let i = 0; i < edges.length; i++) {
      if (checkDates(edges[i].source, edges[i].target) === false){
        for (let j=0; j < nodes.length; j++){
          if (nodes[j].id === edges[i].target && !irregularDatesNodes.includes(nodes[j])){
            irregularDatesNodes.push(nodes[j]);
          }
        }
      };
      }
    };


  const incompleteNodesMemo = useMemo(() => 
    nodes.filter(node => 
      node.data.hoursEstimation === '' || node.data.expenseEstimation === ''
    ).map((node, index) => ({
      key: index,
      testName: node.data.testName,
      testNumber: node.data.testNumber,
      hoursEstimation: node.data.hoursEstimation,
      node: node,
    })),
    [nodes]
  );

  useEffect(() => {
    handleNodesUnfilled(incompleteNodesMemo);
  }, [incompleteNodesMemo]);

  
  useEffect(() => {
    checkAllNodes();
  
    const irregularNodesFormatted = irregularDatesNodes.map((node, index) => ({
      key: incompleteNodesMemo.length + index,
      testName: node.data.testName,
      testNumber: node.data.testNumber,
      hoursEstimation: node.data.hoursEstimation,
      node: node,
    }));
  
    const nodeIDs = irregularNodesFormatted.map((node) => node.node.id);
  
    const filteredIncompleteNodesMemo = incompleteNodesMemo.filter(
      (node) => !nodeIDs.includes(node.node.id)
    );
  
    const combinedNodes = [...filteredIncompleteNodesMemo, ...irregularNodesFormatted];
  
    setIncompleteNodes(combinedNodes);
  }, [nodes, incompleteNodesMemo]);
  

  // console.log(incompleteNodes);

  const columns = [
    {
      // title: 'Test Number',
      dataIndex: 'testNumber',
      key: 'testNumber',
      render: (text, record) => {
        const { expenseEstimation, hoursEstimation } = record.node.data;
        const tooltipTitle = expenseEstimation !== '' && hoursEstimation !== ''
          ? "The Start Date is before the End Date of parent test" 
          : "Please fill out the Expense and Hours Estimations";
        return (
          <Tooltip title={tooltipTitle}>
            <span style={{ color: 'red', marginRight: 8 }}>
            { expenseEstimation !== '' && hoursEstimation !== '' ? <Tag color="orange">
              <WarningOutlined /> Inconsistent Dates
              </Tag> : <Tag color="red">
                <ExclamationCircleOutlined /> Missing Details
              </Tag>  }
            </span>{text? text.substring(0,10): null}
          </Tooltip>
        );
      },
    },
    // {
    //   title: 'Test Name',
    //   dataIndex: 'testName',
    //   key: 'testName',
    //   render: (text, record) => {
    //     const { expenseEstimation, hoursEstimation } = record.node.data;
    //     const tooltipTitle = expenseEstimation && hoursEstimation 
    //       ? "Make sure the dates are correct" 
    //       : "Please fill out the Expense and Hours Estimations";
    //     return (
    //       <Tooltip title={tooltipTitle}>
    //         <span>{text}</span>
    //       </Tooltip>
    //     );
    //   },
    // }
  ];
  

  return (
    <div style={{ display: 'flex', alignItems: 'stretch', height: '89vh' }}>
      <div style={{ position: 'relative', width: sidebarWidth, background: '#f0f0f0', padding: '10px' }}>
        <div style={{ position: 'absolute', top: '45%', right: -20 }}> 
          <Button type="primary" onClick={toggleSidebar} style={{ marginBottom: 16, background: 'transparent', boxShadow: 0 }}>
            {isCollapsed ? <CaretRightOutlined className='details-button' /> : <CaretLeftOutlined className='details-button'/>} {/* Change icon based on state */}
          </Button>
        </div>
        {!isCollapsed && (
  <div style={{ height: '85vh', overflowY: 'auto' }}>
    <h4 style={{ marginTop: "5px" }}>Incomplete Tests</h4>
    <Table
      columns={columns}
      dataSource={incompleteNodes}
      pagination={false}
      size="small"
      rowClassName="incomplete-row"
      onRow={(record, rowIndex) => {
        return {
          onClick: event => {
            handleTestClick(record.node);
          },
          onMouseEnter: event => {
            event.currentTarget.style.cursor = 'pointer';
          },
        };
      }}
    />
  </div>
)}

      </div>
    </div>
  );
};

export default IncompleteNodesSidebar;
