import React, { useState, useEffect } from "react";
import "./AddJob.css";
import Quotation from "../quotation/Quotation";
import NavigationPanel from "./NavigationPanel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Checkbox,
  Button,
  Flex,
  Tabs,
  Table,
  Select,
  Space,
  Tooltip,
  Spin,
  Modal,
  message,
  Input,
  Upload,
  Divider,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "../axiosConfig";
import rateLimit from "axios-rate-limit";
import { customFetch } from "../customFetch";

const Heatmap = ({ clicks, moves }) => {
  return (
    <>
      {clicks.map((click, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            top: click.y,
            left: click.x,
            width: "8px",
            height: "8px",
            backgroundColor: "rgba(255, 0, 0, 0.2)",
            borderRadius: "100%",
            pointerEvents: "none",
          }}
        />
      ))}

      {/* {moves.map((move, index) => ( 
        <div
          key={index}
          style={{
            position: 'absolute',
            top: move.y,
            left: move.x,
            width: '8px',
            height: '8px',
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            borderRadius: '100%',
            pointerEvents: 'none',
          }}
        />
      ))} */}
    </>
  );
};

const PY_API_URL = process.env.REACT_APP_PY_API_URL;

function AddJob() {
  const job = useLocation();
  // console.log(job);
  const http = rateLimit(axios.create(), {
    maxRequests: 10,
    perMilliseconds: 1000,
    maxRPS: 10,
  });
  // console.log('job is:',job);
  // console.log('job state: ', job.state);
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");
  const [jobID, setJobID] = useState(
    job.state
      ? job.state.state
        ? job.state.state.jobOrderID
          ? job.state.state.jobOrderID
          : job.state.state.jobID
        : -1
      : -1
  );
  const [fromFlowChart, setFromFlowChart] = useState(
    job.state
      ? job.state.fromFlowChart
        ? job.state.fromFlowChart
        : false
      : false
  );
  const [fromFlowChartBackBtn, setFromFlowChartBackBtn] = useState(
    job.state
      ? job.state.fromFlowChartBackBtn
        ? job.state.fromFlowChartBackBtn
        : false
      : false
  );
  // const [step, setStep] = useState(job.state ? (job.state.nodes ? (job.state.nodes.length > 0 ? 3 : 1) : 1) : 1);
  const [step, setStep] = useState(
    fromFlowChart || fromFlowChartBackBtn
      ? 3
      : userRole === "Project Leader" &&
        job.state?.state?.status === "Testing In Progress"
      ? 3
      : 1
  );
  const [originator, setOriginator] = useState(
    job.state
      ? job.state.state
        ? job.state.state.originator.trim()
        : userName
      : userName
  );
  const [responsibility, setResponsibility] = useState(
    job.state
      ? job.state.state
        ? job.state.state.responsibility
          ? job.state.state.responsibility.trim()
          : userName
        : userName
      : userName
  );
  const [etoProject, setEtoProject] = useState(
    job.state
      ? job.state.state
        ? job.state.state.jobName
          ? job.state.state.jobName.trim()
          : job.state.state.etoProject
        : null
      : null
  );
  const [projectLeader, setProjectLeader] = useState(
    job.state
      ? job.state.state
        ? job.state.state.projectLeader
          ? job.state.state.projectLeader.trim()
          : ""
        : ""
      : ""
  );
  const [divisionName, setDivisionName] = useState(
    job.state
      ? job.state.state
        ? job.state.state.division
          ? job.state.state.division.trim()
          : job.state.state.divisionName.trim()
        : ""
      : ""
  );
  const [jobType, setJobType] = useState(
    job.state ? (job.state.state ? job.state.state.jobType.trim() : "") : ""
  );
  const [jobGroup, setJobGroup] = useState(
    job.state ? (job.state.state ? job.state.state.jobGroup.trim() : "") : ""
  );
  const [costCenter, setCostCenter] = useState(
    job.state ? (job.state.state ? job.state.state.costCenter.trim() : "") : ""
  );
  const [costCenterDescription, setCostCenterDescription] = useState(
    job.state
      ? job.state.state
        ? job.state.state.costCenterDescription.trim()
        : ""
      : ""
  );
  if (job.costCenterManager === null) {
    job.costCenterManager = "";
  }
  // const [costCenterManager, setCostCenterManager] = useState(job.state ? (job.state.state? job.state.state.costCenterManager.trim() : '') : '');
  const [costCenterManager, setCostCenterManager] = useState(
    job?.state?.state?.costCenterManager?.trim() ?? ""
  );

  const [costCenterApprover, setCostCenterApprover] = useState(
    job.state
      ? job.state.state
        ? job.state.state.costCenterApprover.trim()
        : ""
      : ""
  );
  const [customer, setCustomer] = useState(
    job.state ? (job.state.state ? job.state.state.customer.trim() : "") : ""
  );
  const [deliveryDate, setDeliveryDate] = useState(
    job.state
      ? job.state.state
        ? new Date(job.state.state.deliveryDate.trim())
            .toISOString()
            .split("T")[0]
        : null
      : null
  );
  const [distributionList, setDistributionList] = useState(
    job.state
      ? job.state.state
        ? job.state.state.distributionList.trim()
        : ""
      : ""
  );
  const [depositionSamples, setDepositionSamples] = useState(
    job.state
      ? job.state.state
        ? job.state.state.depositionSamples.trim()
        : "Scrap"
      : "Scrap"
  );
  // const [nocNumber, setNocNumber] = useState(job.state?.state?.nocNumber?.trim() || '');
  const [nocNumber, setNocNumber] = useState(
    job.state?.state?.nocNumber?.trim() || "N/A"
  );

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSaved, setFormSaved] = useState(false);
  const [samples, setSamples] = useState(
    job.state
      ? job.state.state
        ? job.state.state.samples.length > 0
          ? job.state.state.samples[0].PartName
            ? job.state.state.samples
            : []
          : []
        : []
      : []
  );
  const [purchaseOrders, setPurchaseOrders] = useState(
    /*job.state.state ? job.state.state.PurchaseOrders : */ []
  );
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(
    job.state
      ? job.state.state
        ? job.state.state.orderNumber
          ? job.state.state.orderNumber !== "undefined"
            ? job.state.state.orderNumber
              ? job.state.state.orderNumber
              : 0
            : 0
          : job.state.state.purchaseOrderNumber
        : 0
      : 0
  );
  const [purchaseOrderDescription, setPurchaseOrderDescription] = useState(
    job.state
      ? job.state.state
        ? job.state.state.description
          ? job.state.state.description
          : job.state.state.purchaseOrderDescription
        : ""
      : ""
  );
  const [purchaseOrderAttachment, setPurchaseOrderAttachment] = useState(
    job.state
      ? job.state.state
        ? job.state.state.purchaseOrderAttachment != null
          ? job.state.state.purchaseOrderAttachment
          : null
        : null
      : null
  );
  const [engineeringDescription, setEngineeringDescription] = useState(
    job.state
      ? job.state.state
        ? job.state.state.eSDescription != null
          ? job.state.state.eSDescription.trim()
          : job.state.state.engineeringDescription.trim()
        : ""
      : ""
  );
  const [engineeringFile, setEngineeringFile] = useState(
    job.state
      ? job.state.state
        ? job.state.state.engineeringFile != null
          ? job.state.state.engineeringFile
          : null
        : null
      : null
  );
  const [engineeringFiles, setEngineeringFiles] = useState(
    job.state
      ? job.state.state
        ? job.state.state.engineeringFiles != null
          ? job.state.state.engineeringFiles
          : []
        : []
      : []
  );
  const [flowchartDescription, setFlowchartDescription] = useState(
    job.state
      ? job.state.state
        ? job.state.state.fCDescription != null
          ? job.state.state.fCDescription.trim()
          : job.state.state.flowchartDescription.trim()
        : ""
      : ""
  );
  const [flowchartFile, setFlowchartFile] = useState(
    job.state
      ? job.state.state
        ? job.state.state.flowchartFile != null
          ? job.state.state.flowchartFile
          : null
        : null
      : null
  );
  const [softSave, setSoftSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fcUpdating, setFCUpdating] = useState(false);
  const [etoProjects, setEtoProjects] = useState({});
  const [jobTypes, setJobTypes] = useState({});
  const [nodes, setNodes] = useState(
    job.state ? (job.state.nodes ? job.state.nodes : []) : []
  );
  const [edges, setEdges] = useState(
    job.state ? (job.state.edges ? job.state.edges : []) : []
  );
  const [unfilledNodes, setUnfilledNodes] = useState(
    job.state
      ? job.state.state.unfilledNodes
        ? job.state.state.unfilledNodes
        : []
      : []
  );
  const [attachedSpecsFiles, setAttachedSpecsFiles] = useState([]);
  const [attachedSpecsFile, setAttachedSpecsFile] = useState(
    job.state
      ? job.state.state
        ? job.state.state.engineeringFile
          ? job.state.state.engineeringFile.name
            ? job.state.state.engineeringFile.name.trim()
            : job.state.state.engineeringFile
          : ""
        : ""
      : ""
  );
  const [attachedPOFile, setAttachedPOFile] = useState(
    job.state
      ? job.state.state
        ? job.state.state.fileAddress
          ? job.state.state.fileAddress.trim()
          : ""
        : ""
      : ""
  );
  const [attachedFCFile, setAttachedFCFile] = useState(
    job.state
      ? job.state.state
        ? job.state.state.flowchartFile
          ? job.state.state.flowchartFile.name
          : ""
        : ""
      : ""
  );
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState(
    job.state
      ? job.state.state
        ? job.state.state.status
          ? job.state.state.status.trim()
          : ""
        : ""
      : ""
  );
  const [formErrors, setFormErrors] = useState({
    originator: false,
    etoProject: false,
    divisionName: false,
    jobType: false,
    jobGroup: false,
    costCenter: false,
    costCenterDescription: false,
    costCenterManager: false,
    costCenterApprover: false,
    customer: false,
    deliveryDate: false,
    depositionSamples: false,
  });
  //quotation states
  const [quotationSubmitted, setQuotationSubmitted] = useState(
    status === "Job Assigned by PL" || status === "Pending Assignment" ? 0 : 1
  );
  // const [quotationSubmitted, setQuotationSubmitted] = useState(status === 'Assigned' || status === 'Pending Assignment' ? 0 : 1);
  // const [quotationSubmitted, setQuotationSubmitted] = useState(job.state ? (job.state.state ? (job.state.state.status ? (job.state.state.status.trim() === 'Assigned' || job.state.state.status.trim() === 'Pending Assignment' ? 0 : 1) : 0) : 1): 1);

  const [jobName, setJobName] = useState(etoProject);
  const [hourlyCost, setHourlyCost] = useState(60);
  const [estimatedEtoHours, setEstimatedEtoHours] = useState(0);
  const [estimatedEtoCost, setEstimatedEtoCost] = useState(0);
  const [estimatedOutsourcingCost, setEstimatedOutsourcingCost] = useState(0);
  const [estimatedFixtureCost, setEstimatedFixtureCost] = useState(0);
  const [estimatedTotalCost, setEstimatedTotalCost] = useState(
    estimatedEtoCost + estimatedOutsourcingCost + estimatedFixtureCost
  );
  const [quotationFiles, setQuotationFiles] = useState([]);
  const [finalHourlyCost, setFinalHourlyCost] = useState(60);
  const [finalEtoHours, setFinalEtoHours] = useState(0);
  const [finalEtoCost, setFinalEtoCost] = useState(0);
  const [finalOutsourcingCost, setFinalOutsourcingCost] = useState(0);
  const [finalFixtureCost, setFinalFixtureCost] = useState(0);
  const [finalTotalCost, setFinalTotalCost] = useState(
    finalEtoCost + finalOutsourcingCost + finalFixtureCost
  );

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [outsourcingDetails, setOutsourcingDetails] = useState("");
  const [onCompletion, setOnCompletion] = useState(false);
  const [comments, setComments] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [closingDate, setClosingDate] = useState(new Date());

  const { TextArea } = Input;
  const [closingNotes, setClosingNotes] = useState("");

  const handleNotesChange = (e) => {
    setClosingNotes(e.target.value);
  };

  const [EverSubmitted, setEverSubmitted] = useState(false);
  const [sendForApprovalStatus, setSendForApprovalStatus] = useState(
    fromFlowChartBackBtn || fromFlowChart
      ? fromFlowChartBackBtn
        ? job.state.nodesAmount === 0
          ? true
          : job.state.sendForApprovalStatus
        : unfilledNodes.length === 0
      : false
  );
  const [clicks, setClicks] = useState([]);
  const [moves, setMoves] = useState([]);

  useEffect(() => {
    const storedClicks =
      JSON.parse(localStorage.getItem("addjob_clicks")) || [];
    setClicks(storedClicks);
  }, []);

  useEffect(() => {
    const storedMoves =
      JSON.parse(localStorage.getItem("addjob_tracker")) || [];
    setMoves(storedMoves);
  }, []);

  const handleClick = (e) => {
    const newClick = { x: e.clientX, y: e.clientY };
    const updatedClicks = [...clicks, newClick];
    setClicks(updatedClicks);
    localStorage.setItem("addjob_clicks", JSON.stringify(updatedClicks));
  };

  const handleMove = (e) => {
    const mousePosition = { x: e.clientX, y: e.clientY };
    const updatedMoves = [...moves, mousePosition];
    setMoves(updatedMoves);
    localStorage.setItem("addjob_tracker", JSON.stringify(updatedMoves));
  };

  const localStorageSpace = () => {
    const totalSpace = 5 * 1024 * 1024;
    let usedSpace = 0;

    for (let key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        usedSpace += key.length + localStorage.getItem(key).length;
      }
    }
    return totalSpace - usedSpace;
  };

  useEffect(() => {
    const x = localStorageSpace();
  }, [moves]);

  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  // console.log(softSave);
  var userNames = users.map((user) => ({
    label: user.email,
    // value: user.name
    value: user.email,
  }));
  useEffect(() => {
    if (userRole === "Cost Center Manager") {
      setStep(6);
    }
    // if (userRole === 'Project Leader' && (fromFlowChart || fromFlowChartBackBtn)){
    //   setStep(6);
    // };
  }, []);

  //gets the project list from Python
  useEffect(() => {
    customFetch(`${PY_API_URL}/api/get_project_list`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        let res = response.json();
        return res;
      })
      .then((data) => {
        setEtoProjects(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  // console.log(etoProjects);

  //gets job types from Python
  useEffect(() => {
    customFetch(`${PY_API_URL}/api/get_job_types`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        let res = response.json();
        return res;
      })
      .then((data) => {
        setJobTypes(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    customFetch(`${PY_API_URL}/api/getUsers`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  //gets the nodes
  useEffect(() => {
    if (jobID !== -1 && nodes.length === 0) {
      customFetch(`${PY_API_URL}/api/get_nodes/${jobID}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          setNodes(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  // gets the edges
  useEffect(() => {
    if (jobID !== -1 && edges.length === 0) {
      customFetch(`${PY_API_URL}/api/get_edges/${jobID}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          setEdges(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const downloadFile = (file) => {
    customFetch(`${PY_API_URL}/api/download_file/${file}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Get the response as a Blob
      })
      .then((blob) => {
        // Create a link element
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = file;

        // Append the link to the body
        document.body.appendChild(a);

        // Simulate click
        a.click();

        // Remove the link
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (jobID !== -1) {
      const timeoutId = setTimeout(() => {
        customFetch(`${PY_API_URL}/api/get_quotation/${jobID}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            if (!data.error) {
              // console.log(data);
              setJobName(data.quotationJobName);
              setStartDate(
                new Date(data.quotationStartDate).toISOString().split("T")[0]
              );
              setEndDate(
                new Date(data.quotationEndDate).toISOString().split("T")[0]
              );
              setHourlyCost(Number(data.hourlyCost));
              setEstimatedEtoHours(Number(data.etoHours));
              setEstimatedEtoCost(Number(data.etoCost));
              setEstimatedOutsourcingCost(Number(data.outsourcingCost));
              setEstimatedFixtureCost(Number(data.fixtureCost));
              setEstimatedTotalCost(Number(data.totalCost));
              setOutsourcingDetails(data.outsourcingDetails);
              setOnCompletion(data.quotationOnCompletion);
              setComments(data.quotationComments);
              setFinalHourlyCost(Number(data.finalHourlyCost));
              setFinalEtoHours(Number(data.finalEtoHours));
              setFinalEtoCost(Number(data.finalEtoCost));
              setFinalOutsourcingCost(Number(data.finalOutsourcingCost));
              setFinalFixtureCost(Number(data.finalFixtureCost));
              setFinalTotalCost(Number(data.finalTotalCost));
              setQuotationFiles(data.files);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [jobID]);

  // console.log(estimatedTotalCost);
  useEffect(() => {
    // console.log('responsibility: ', responsibility);
    if (jobID !== -1 && responsibility !== userName) {
      customFetch(`${PY_API_URL}/api/get_quotation_submit_status/${jobID}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setQuotationSubmitted(data.QuotationSubmitted);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  //adding samples
  const handleAddSample = () => {
    const newSample = {
      id: Date.now(),
      Quantity: "",
      PartNumber: "",
      RevisionLevel: "",
      PartName: "",
    };
    setSamples([...samples, newSample]);
  };

  //changing samples
  const handleInputChange = (id, field, value) => {
    const updatedSamples = samples.map((sample) => {
      // console.log(sample.SamplesID);
      if (sample.id) {
        if (sample.id === id) {
          return { ...sample, [field]: value };
        }
      }
      if (sample.SamplesID) {
        if (sample.SamplesID === id) {
          return { ...sample, [field]: value };
        }
      }
      return sample;
    });
    setSamples(updatedSamples);
  };

  //removing samples
  const handleRemoveSample = (id) => {
    // console.log(samples);
    const updatedSamples = samples.filter(
      (sample) => sample.id !== id && sample.SamplesID != id
    );
    setSamples(updatedSamples);
  };

  //adding purchase order
  const handleAddPurchaseOrder = () => {
    const newPurchaseOrder = {
      id: Date.now(),
      purchaseOrderNumber: "",
      AttachPurchaseOrder: "",
    };

    setPurchaseOrders([...purchaseOrders, newPurchaseOrder]);
  };

  //removing purchase order
  const handleRemovePurchaseOrder = (id) => {
    // const updatedPurchaseOrders = purchaseOrders.filter(purchaseOrder => purchaseOrder.id !== id);
    setPurchaseOrders([]);
    setPurchaseOrderDescription("");
    // setPurchaseOrders(updatedPurchaseOrders);
  };
  const jobData = {
    jobID,
    originator,
    etoProject,
    divisionName,
    jobType,
    jobGroup,
    costCenter,
    costCenterDescription,
    costCenterManager,
    costCenterApprover,
    customer,
    deliveryDate,
    distributionList,
    depositionSamples,
    submissionStatus: 1,
    samples,
    purchaseOrderNumber,
    purchaseOrderDescription,
    purchaseOrderAttachment:
      purchaseOrderAttachment !== null
        ? purchaseOrderAttachment
        : attachedPOFile,
    engineeringDescription,
    engineeringFiles,
    flowchartDescription,
    flowchartFile,
    nodes: JSON.stringify(nodes),
    edges: JSON.stringify(edges),
    fromFlowChart,
    responsibility,
    EverSubmitted: 0,
    userRole,
    projectLeader,
    status,
    sendForApprovalStatus,
    nocNumber,
  };

  if (fromFlowChart && !softSave) {
    setSoftSave(true);
    // console.log('Setting Loading to be true here..');
    setIsLoading(true);
    // setTimeout(() => {
    const formData = new FormData();
    // console.log("saving flowchart");

    Object.keys(jobData).forEach((key) => {
      if (key === "samples") {
        formData.append(key, JSON.stringify(jobData[key]));
      } else if (key === "submissionStatus") {
        formData.append(key, "0");
      } else if (key === "engineeringFiles") {
        jobData[key].forEach((file, index) => {
          formData.append(`${key}[${index}]`, file);
        });
      } else {
        formData.append(key, jobData[key]);
      }
    });

    const token = localStorage.getItem("token");
    customFetch(`${PY_API_URL}/api/add-job`, {
      method: "POST",
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          console.log("Flow Chart: HTTP status code: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Form data saved successfully.", data);
      })
      .catch((error) => {
        console.error("An error occurred while sending the form data:", error);
      })
      .finally(() => {
        console.log("Setting Loading to be false here..");
        setIsLoading(false);
      });
  }

  useEffect(() => {
    console.log("entering the getMostRecentJobID...");
    if (fromFlowChart && jobID === -1) {
      const timer = setTimeout(() => {
        customFetch(`${PY_API_URL}/api/getMostRecentJobID`)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // console.log(data);
            setJobID(data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, 1000); //job id is gotten after a second to ensure that the database has enough time to update
      return () => clearTimeout(timer);
    }
  }, []);

  async function trackButtonClick(buttonId) {
    try {
      const response = await axios.post(
        `${PY_API_URL}/api/track-button-click`,
        {
          button_id: buttonId,
          userName: userName,
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error tracking button click:", error);
      throw error;
    }
  }

  const handleQuotationFileChange = (info) => {
    const newFiles = info.fileList.map((file) => file.originFileObj || file);
    setQuotationFiles((prevFiles) => {
      // Filter out duplicates based on file name or any other unique property you prefer
      const existingFileNames = prevFiles.map((file) => file.name || file);
      const filteredNewFiles = newFiles.filter(
        (file) => !existingFileNames.includes(file.name || file)
      );
      return [...prevFiles, ...filteredNewFiles];
    });
  };

  // console.log(jobID);
  //sends job details to python when saved
  //todo make one function for saving and submitting
  // const handleSave = async (e) => {
  //   if (e) e.preventDefault();
  //   // setStep(4);
  //   setIsLoading(true);
  //   const formData = new FormData();

  //   Object.keys(jobData).forEach((key) => {
  //     if (key === "samples") {
  //       // Convert the 'samples' array to a JSON string
  //       formData.append(key, JSON.stringify(jobData[key]));
  //     } else if (key === "submissionStatus") {
  //       formData.append(key, "0");
  //     } else if (key === "engineeringFiles" && !softSave) {
  //       jobData[key].forEach((file, index) => {
  //         formData.append(`${key}[${index}]`, file);
  //       });
  //     } else {
  //       formData.append(key, jobData[key]);
  //       // console.log('the key and data is: ', jobData[key]);
  //     }
  //   });
  //   try {
  //     setLoading(true);
  //     // console.log('476 add job endpoint:', jobData);
  //     // console.log('477 add job endpoint:', formData);

  //     const token = localStorage.getItem("token");
  //     // console.log('the token is: ', token);

  //     const response = await fetch(`${PY_API_URL}/api/add-job`, {
  //       method: "POST",
  //       headers: {
  //         ...(token ? { Authorization: `Bearer ${token}` } : {}),
  //       },
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       setFormSubmitted(false);
  //       setFormSaved(true);
  //       console.log("Form data saved successfully.");
  //     } else {
  //       const responseText = await response.text();
  //       console.error("Response text:", responseText);
  //       console.error(
  //         "Form data submission failed. HTTP status code:",
  //         response.status
  //       );
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while sending the form data:", error);
  //   } finally {
  //     trackButtonClick("Save_Job");
  //     setLoading(false);
  //     setIsLoading(false);
  //   }
  // };

  const handleSave = async (e) => {
    if (e) e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();

    // Prepare the form data
    Object.keys(jobData).forEach((key) => {
      if (key === "samples") {
        formData.append(key, JSON.stringify(jobData[key]));
      } else if (key === "submissionStatus") {
        formData.append(key, "0");
      } else if (key === "engineeringFiles" && !softSave) {
        jobData[key].forEach((file, index) => {
          formData.append(`${key}[${index}]`, file);
        });
      } else {
        formData.append(key, jobData[key]);
      }
    });

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${PY_API_URL}/api/add-job`, {
        method: "POST",
        headers: { ...(token ? { Authorization: `Bearer ${token}` } : {}) },
        body: formData,
      });

      if (response.ok) {
        setFormSaved(true);
        console.log("Form data saved successfully.");
        return true; // Save successful
      } else {
        console.error("Form data submission failed.", await response.text());
        return false; // Save failed
      }
    } catch (error) {
      console.error("Error saving form data:", error);
      return false; // Save failed
    } finally {
      setIsLoading(false);
    }
  };

  const sendNotificationEmail = async (jobDetails, emailType) => {
    // console.log('AddJob -> Sending notification email... jobData:', jobDetails);
    // console.log('the emailType is: ', emailType);

    let payload;
    if (emailType === "NewJobSubmission" || emailType === "PLApproval") {
      // console.log('confirmed! the email type is: ', emailType);
      jobDetails.append("emailType", emailType);
      payload = jobDetails;
    } else {
      // For JSON data
      jobDetails["emailType"] = emailType;
      payload = JSON.stringify(jobDetails);
    }

    try {
      console.log("Trying the API for send job notifications now...");
      const emailResponse = await customFetch(
        `${PY_API_URL}/api/send_job_notification`,
        {
          method: "POST",
          // headers: emailType === 'NewJobSubmission' ? {} : {'Content-Type': 'application/json'},
          headers:
            emailType === "NewJobSubmission" || emailType === "PLApproval"
              ? {}
              : { "Content-Type": "application/json" },
          body: payload,
        }
      );

      const responseData = await emailResponse.json();

      if (!emailResponse.ok) {
        console.error(
          "Failed to send notification email:",
          responseData.details
        );
        message.error(`Error: ${responseData.message}`);
      } else {
        // console.log('Notification email sent successfully:', responseData);
        message.success(responseData.message);
      }
    } catch (error) {
      console.error(
        "An error occurred while sending the notification email:",
        error
      );
    }
  };

  //sends job details to backend when saved
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStep(5);

    const formData = new FormData();

    Object.keys(jobData).forEach((key) => {
      if (key === "samples") {
        formData.append(key, JSON.stringify(jobData[key]));
      }
      if (key === "EverSubmitted") {
        formData.append(key, "1");
      } else if (key === "engineeringFiles" && !softSave) {
        jobData[key].forEach((file, index) => {
          formData.append(`${key}[${index}]`, file);
        });
      } else {
        formData.append(key, jobData[key]);
      }
    });

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await fetch(`${PY_API_URL}/api/add-job`, {
        method: "POST",
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
        body: formData,
      });

      // console.log(jobData);
      if (response.ok) {
        const responseData = await response.json();
        // console.log('The response from the add-job is:', responseData);
        setFormSubmitted(true);

        const currentJobID = responseData.currentJobID;
        const emailFormData = new FormData();
        for (let [key, value] of formData.entries()) {
          emailFormData.append(key, value);
        }
        emailFormData.append("currentJobID", currentJobID);
        await sendNotificationEmail(emailFormData, "NewJobSubmission");
      } else {
        const responseData = await response.json();
        // console.log('The response from the add-job is:', responseData);
        console.error(
          "Form data submission failed. Error: " + (await response.text())
        );
      }
    } catch (error) {
      console.error("An error occurred while sending the form data:", error);
    } finally {
      trackButtonClick("Submit_Job");
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  // const EnterFlowChart = async () => {
  //   setFromFlowChart(false);
  //   setSoftSave(false);

  //   if (jobID === -1) {
  //     console.log("here");
  //     await handleSave();
  //     console.log("here2");

  //     const fetchedJobID = await getMostRecentJobID();

  //     navigate("/flowchart", { state: { ...jobData, jobID: fetchedJobID } });
  //     trackButtonClick("Enter_Flowchart");
  //   } else {
  //     navigate("/flowchart", { state: jobData });
  //     trackButtonClick("Enter_Flowchart");
  //   }
  // };

  const EnterFlowChart = async () => {
    setFromFlowChart(false);
    setSoftSave(false);

    if (jobID === -1) {
      console.log("Saving data before entering flowchart...");
      const saveSuccessful = await handleSave(); // Wait for save

      if (saveSuccessful) {
        const fetchedJobID = await getMostRecentJobID();
        navigate("/flowchart", { state: { ...jobData, jobID: fetchedJobID } });
        console.log("entering navigate state: ", jobData);
        trackButtonClick("Enter_Flowchart");
      } else {
        console.error("Failed to save data. Cannot enter flowchart.");
        // Optionally, show an error to the user
      }
    } else {
      navigate("/flowchart", { state: jobData });
      trackButtonClick("Enter_Flowchart");
    }
  };

  //checks if all required fields have been entered
  const validateForm = () => {
    const entered = {
      originator: false,
      etoProject: false,
      jobType: false,
      costCenter: false,
      deliveryDate: false,
      depositionSamples: false,
    };
    if (!originator.trim()) {
      entered.originator = true;
    }
    if (!etoProject) {
      entered.etoProject = true;
    }
    if (!jobType) {
      entered.jobType = true;
    }
    if (!costCenter) {
      entered.costCenter = true;
    }
    if (!deliveryDate) {
      entered.deliveryDate = true;
    }
    if (!depositionSamples) {
      entered.depositionSamples = true;
    }
    if (!nocNumber) {
      entered.nocNumber = true;
    }
    setFormErrors(entered);
    return (
      !entered.originator &&
      !entered.etoProject &&
      !entered.jobType &&
      !entered.costCenter &&
      !entered.deliveryDate &&
      !entered.depositionSamples &&
      !entered.nocNumber
    );
  };

  //going to next step of form
  const handleNext = () => {
    if (validateForm()) {
      setStep(step + 1);
      trackButtonClick("Next");
    }
  };

  //going back to previous step of form
  const handlePrevious = () => {
    setStep(step - 1);
    trackButtonClick("Previous");
  };

  const handleGoToQuotation = () => {
    setStep(6);
    trackButtonClick("Go_To_Quotation");
  };

  const handleStepClick = (step) => {
    if (validateForm()) {
      setStep(step);
      if (step === 1) {
        trackButtonClick("General_Info_Tab");
      } else if (step === 2) {
        trackButtonClick("Job_Description_Tab");
      } else if (step === 3) {
        trackButtonClick("Test_Instructions_Tab");
      } else {
        trackButtonClick("Quotation_Tab");
      }
    }
  };

  //actions for quotation
  useEffect(() => {
    setEstimatedEtoCost(estimatedEtoHours * hourlyCost);
  }, [hourlyCost, estimatedEtoHours]);

  useEffect(() => {
    setFinalEtoCost(finalEtoHours * finalHourlyCost);
  }, [finalHourlyCost, finalEtoHours]);

  useEffect(() => {
    setEstimatedTotalCost(
      estimatedEtoCost + estimatedOutsourcingCost + estimatedFixtureCost
    );
  }, [estimatedEtoCost, estimatedOutsourcingCost, estimatedFixtureCost]);

  useEffect(() => {
    setFinalTotalCost(finalEtoCost + finalOutsourcingCost + finalFixtureCost);
  }, [finalEtoCost, finalOutsourcingCost, finalFixtureCost]);

  const handleCheckboxChange = (e) => {
    setOnCompletion(e.target.checked);
  };

  const quotationData = {
    jobID,
    jobName,
    hourlyCost,
    estimatedEtoHours,
    estimatedEtoCost,
    estimatedOutsourcingCost,
    estimatedFixtureCost,
    estimatedTotalCost,
    finalHourlyCost,
    finalEtoHours,
    finalEtoCost,
    finalOutsourcingCost,
    finalFixtureCost,
    finalTotalCost,
    startDate,
    endDate,
    outsourcingDetails,
    onCompletion,
    comments,
    quotationFiles,
  };
  //  console.log(quotationData);

  const handleSendForApproval = (jobNum, name, userName) => {
    customFetch(`${PY_API_URL}/api/send_to_ccm/data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        job_id: jobNum,
        name: name,
        projectLeaderName: userName,
      }),
    })
      .then((response) => {
        if (!response.ok) {
        }
        setQuotationSubmitted(1);
        return response.json();
      })
      .then(() => {
        message.success("Sent to Cost Center Manager");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error returning to originator:", error);
      })
      .finally(() => {
        trackButtonClick("Submit_Quotation");
      });
  };

  const showConfirmReturnToOrignator = (
    jobData,
    jobNum,
    originator_name,
    projectLeader
  ) => {
    Modal.confirm({
      title: `Are you sure you want to return to originator (${originator_name})?`,
      content: "This action cannot be undone.",
      onOk() {
        handleReturnToOriginator(
          jobData,
          jobNum,
          originator_name,
          projectLeader
        );
      },
      onCancel() {
        console.log("Inactivation cancelled");
      },
    });
  };

  const handleReturnToOriginator = async (
    jobData,
    jobNum,
    originator_name,
    projectLeader
  ) => {
    // console.log('handling return to originator..., the job Data is: ', jobData);
    // console.log('the job_id from job Data is:', jobData.jobID);
    const jobDetails = {
      job_id: jobData.jobID,
      originator: jobData.originator,
      projectLeader: projectLeader,
      jobName: jobData.etoProject,
      deliveryDate: jobData.deliveryDate,
      depositionSamples: jobData.depositionSamples,
      division: jobData.divisionName,
      customer: jobData.customer,
    };
    // console.log('the jobDetails are: ', jobDetails);

    try {
      const response = await customFetch(
        `${PY_API_URL}/api/return_to_originator/data`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            job_id: jobNum,
            originator_name: originator_name,
            projectLeader: userName,
            costCenterManager: null,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      await sendNotificationEmail(jobDetails, "PLRejection");
      message.success("Returned to originator and notification sent");
      navigate("/");
    } catch (error) {
      console.error("Error in handling return to originator:", error);
      message.error("Failed to return to originator");
    } finally {
      trackButtonClick("Return_To_Originator");
    }
  };

  useEffect(() => {
    if (userRole === "Project Leader") {
      const timer = setTimeout(() => {
        customFetch(`${PY_API_URL}/api/getStartEndDates/${jobID}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            if (data.startDate && data.endDate) {
              setStartDate(
                new Date(data.startDate).toISOString().split("T")[0]
              );
              setEndDate(new Date(data.endDate).toISOString().split("T")[0]);
            }
            // console.log(startDate);
            // console.log(endDate);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, 1000); //dates are gotten after a second to ensure that the database has enough time to update
    }
  }, [fromFlowChart]);

  const getMostRecentJobID = async () => {
    try {
      const response = await customFetch(
        `${PY_API_URL}/api/getMostRecentJobID`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("latest saved job is:", data);
      setJobID(data);
      return data; // Return the fetched job ID
    } catch (error) {
      console.error("Error fetching data:", error);
      return -1; // Return a default/fallback value in case of error
    }
  };

  const showConfirmSubmitQuotation = (e) => {
    Modal.confirm({
      title: "Are you sure you want to send for approval?",
      content: "This action cannot be undone.",
      onOk() {
        handleSubmitQuotation(e);
      },
      onCancel() {
        console.log("Inactivation cancelled");
      },
    });
  };

  const handleSubmitQuotation = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const formData = new FormData();

    Object.keys(quotationData).forEach((key) => {
      if (key !== "quotationFiles") {
        formData.append(key, quotationData[key]);
      }
    });

    // Append each file in quotationFiles individually
    quotationData.quotationFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${PY_API_URL}/api/submit_quotation`, {
        method: "POST",
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
        body: formData,
      });
      if (response.ok) {
        message.success("Quotation Updated");
        if (userRole === "Project Leader" && quotationSubmitted === 0) {
          handleSendForApproval(jobID, costCenterManager, userName);

          formData.append("projectLeader", userName);
          formData.append("costCenterManager", costCenterManager);
          console.log("sending notification email for PL approval now...");
          sendNotificationEmail(formData, "PLApproval");
        }
      } else {
        console.error(
          "Quotation data submission failed. HTTP status code:",
          response.status
        );
        console.error("Response text:", response.text());
      }
    } catch (error) {
      console.error("An error occurred while sending the form data:", error);
    }
  };

  const showConfirmCcmApprove = (
    e,
    jobNum,
    projectLeader,
    costCenterManager
  ) => {
    Modal.confirm({
      title: "Are you sure you want to appove this job?",
      content: "This action cannot be undone.",
      onOk() {
        handleCcmApprove(e, jobNum, projectLeader, costCenterManager);
      },
      onCancel() {
        console.log("Inactivation cancelled");
      },
    });
  };

  const handleCcmApprove = async (
    e,
    jobNum,
    projectLeader,
    costCenterManager
  ) => {
    e.preventDefault();

    const jobDetails = {
      job_id: jobData.jobID,
      originator: jobData.originator,
      projectLeader: projectLeader,
      jobName: jobData.etoProject,
      deliveryDate: jobData.deliveryDate,
      depositionSamples: jobData.depositionSamples,
      division: jobData.divisionName,
      customer: jobData.customer,
      costCenterManager: costCenterManager,
    };
    // console.log('the jobDetails are: ', jobDetails);

    sendNotificationEmail(jobDetails, "CCMApproval");
    customFetch(`${PY_API_URL}/api/CCM_job_approved`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        job_id: jobNum,
        projectLeader: projectLeader,
        costCenterManager: costCenterManager,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // handleSubmitQuotation(e);
        return response.json();
      })
      .then(() => {
        message.success("Successfully Approved Job.");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error taking assignment:", error);
      })
      .finally(() => {
        trackButtonClick("Approve");
      });
  };

  const showConfirmCcmReject = (e, jobID, originator, costCenterManager) => {
    Modal.confirm({
      title: "Are you sure you want to reject this job?",
      content: "This action cannot be undone.",
      onOk() {
        handleCcmReject(e, jobID, originator, costCenterManager);
      },
      onCancel() {
        console.log("Inactivation cancelled");
      },
    });
  };

  const handleCcmReject = async (e, jobID, originator, costCenterManager) => {
    e.preventDefault();

    const jobDetails = {
      job_id: jobData.jobID,
      originator: jobData.originator,
      projectLeader: projectLeader,
      jobName: jobData.etoProject,
      deliveryDate: jobData.deliveryDate,
      depositionSamples: jobData.depositionSamples,
      division: jobData.divisionName,
      customer: jobData.customer,
      costCenterManager: costCenterManager,
    };
    // console.log('the jobDetails are: ', jobDetails);

    sendNotificationEmail(jobDetails, "CCMRejection");

    customFetch(`${PY_API_URL}/api/return_to_originator/data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        job_id: jobID,
        originator_name: originator,
        projectLeader: null,
        costCenterManager: costCenterManager,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response.json();
      })
      .then(() => {
        message.success("Returned to originator");
        // Redirects to /addJob with state
        navigate("/");
      })
      .catch((error) => {
        console.error("Error taking assignment:", error);
      })
      .finally(() => {
        trackButtonClick("Return_To_Originator");
      });
  };

  const handleCloseJobModal = (jobNum) => {
    setIsModalVisible(true);
  };

  // const confirmClosingJob = (jobData, closingDate) => {
  //   const jobDetails = {
  //     job_id: jobData.jobID,
  //     originator: jobData.originator,
  //     projectLeader: jobData.projectLeader,
  //     jobName: jobData.etoProject,
  //     deliveryDate: jobData.deliveryDate,
  //     depositionSamples: jobData.depositionSamples,
  //     division: jobData.divisionName,
  //     customer: jobData.customer,
  //   };

  //   sendNotificationEmail(jobDetails, 'PLCloseJob');
  //   customFetch(`http://127.0.0.1:5000/api/close_job`, {
  //     method: 'POST',
  //     headers: {
  //         'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ jobID: jobData.jobID, closingDate: closingDate, projectLeader: jobData.projectLeader})
  //   })
  //   .then((response) => {
  //     if (response.ok) {
  //       message.success('Successfully closed job!');
  //       navigate('/');
  //       return response.json();
  //     }
  //     throw new Error("Network response was not ok.");
  //   })
  //   .catch((error) => {
  //     console.error("Error:", error);
  //     message.error("Failed to close job.");
  //   });
  // }
  const confirmClosingJob = (jobData, closingDate, closingNotes) => {
    const jobDetails = {
      job_id: jobData.jobID,
      originator: jobData.originator,
      projectLeader: jobData.projectLeader,
      jobName: jobData.etoProject,
      deliveryDate: jobData.deliveryDate,
      depositionSamples: jobData.depositionSamples,
      division: jobData.divisionName,
      customer: jobData.customer,
    };

    sendNotificationEmail(jobDetails, "PLCloseJob");
    customFetch(`${PY_API_URL}/api/close_job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jobID: jobData.jobID,
        closingDate: closingDate,
        closingNotes: closingNotes,
        projectLeader: jobData.projectLeader,
      }),
    })
      .then((response) => {
        if (response.ok) {
          message.success("Successfully closed job!");
          navigate("/");
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error("Failed to close job.");
      })
      .finally(() => {
        trackButtonClick("Job_Complete");
      });
  };

  const handleSaveDetails = (quotationData) => {
    const formData = new FormData();

    // Populate form data with the required fields
    formData.append("jobID", quotationData.jobID);
    formData.append("jobName", quotationData.jobName);
    formData.append("hourlyCost", quotationData.hourlyCost); // Default value
    formData.append("estimatedEtoHours", quotationData.estimatedEtoHours);
    formData.append("estimatedEtoCost", quotationData.estimatedEtoCost);
    formData.append(
      "estimatedOutsourcingCost",
      quotationData.estimatedOutsourcingCost
    );
    formData.append("estimatedFixtureCost", quotationData.estimatedFixtureCost);
    formData.append("estimatedTotalCost", quotationData.estimatedTotalCost);
    formData.append("startDate", quotationData.startDate);
    formData.append("endDate", quotationData.endDate);
    formData.append("outsourcingDetails", quotationData.outsourcingDetails);
    formData.append("onCompletion", quotationData.onCompletion);
    formData.append("comments", quotationData.comments);
    formData.append("finalHourlyCost", quotationData.finalHourlyCost);
    formData.append("finalEtoHours", quotationData.finalEtoHours);
    formData.append("finalEtoCost", quotationData.finalEtoCost);
    formData.append("finalOutsourcingCost", quotationData.finalOutsourcingCost);
    formData.append("finalFixtureCost", quotationData.finalFixtureCost);
    formData.append("finalTotalCost", quotationData.finalTotalCost);

    // If files are included (e.g., attachments)
    if (jobData.files && jobData.files.length > 0) {
      jobData.files.forEach((file) => {
        formData.append("files", file);
      });
    }

    // Send the data to the server
    customFetch(`${PY_API_URL}/api/submit_quotation`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          message.success("Details saved successfully!");
          navigate("/");
        } else {
          throw new Error("Failed to save details.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error("An error occurred while saving details.");
      });
  };

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setClosingDate(formattedDate);
  }, []);

  const handleDateChange = (event) => {
    setClosingDate(event.target.value);
  };

  const nodes_columns = [
    {
      title: "Test Number",
      dataIndex: "TestNumber",
      width: 100,
    },
    {
      title: "Test Name",
      dataIndex: "TestName",
      width: 270,
    },
    {
      title: "Hours Estimation",
      dataIndex: "HoursEstimation",
      width: 70,
    },
    {
      title: "Expense Estimation",
      dataIndex: "ExpenseEstimation",
      width: 70,
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      width: 120,
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      width: 120,
    },
  ];

  const data = [];
  for (const node of nodes) {
    data.push({
      TestNumber: node.data.testNumber
        ? node.data.testNumber.slice(0, 10)
        : null,
      TestName: node.data.testName,
      HoursEstimation:
        node.data.hoursEstimation === -1 ? "" : node.data.hoursEstimation,
      ExpenseEstimation:
        node.data.expenseEstimation === -1 ? "" : node.data.expenseEstimation,
      StartDate: new Date(node.data.startDate).toLocaleDateString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
      EndDate: new Date(node.data.endDate).toLocaleDateString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
    });
  }
  // console.log(nodes);
  useEffect(() => {
    let totalCost = 0;
    let totalHours = 0;

    for (const node of nodes) {
      totalCost += Number(node.data.expenseEstimation);
      totalHours += Number(node.data.hoursEstimation);
    }

    setEstimatedOutsourcingCost(totalCost < 0 ? 0 : totalCost);
    setEstimatedEtoHours(totalHours < 0 ? 0 : totalHours);
  }, [nodes]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newEngineeringFiles = [];
    const newAttachedSpecsFiles = [];

    for (let i = 0; i < files.length; i++) {
      newEngineeringFiles.push(files[i]);
      newAttachedSpecsFiles.push(files[i]);
    }
    setEngineeringFiles([...engineeringFiles, ...newEngineeringFiles]);
    setAttachedSpecsFiles([...attachedSpecsFiles, ...newAttachedSpecsFiles]);
  };

  // console.log(engineeringFiles);
  // console.log(purchaseOrderAttachment);

  // Function to remove a file
  const removeFile = (index, type, file) => {
    // if (type === 'purchaseOrder'){
    //   setPurchaseOrderAttachment(null);
    //   setAttachedPOFile('');
    // };
    // if (type == 'flowchart'){
    //   setFlowchartFile(null);
    //   setAttachedFCFile('');
    // };
    if (type === "engineering") {
      const updatedEngineeringFiles = [...engineeringFiles];
      updatedEngineeringFiles.splice(index, 1);
      setEngineeringFiles(updatedEngineeringFiles);
      const updatedAttachedSpecsFiles = [...attachedSpecsFiles];
      updatedAttachedSpecsFiles.splice(index, 1);
      setAttachedSpecsFiles(updatedAttachedSpecsFiles);
    }
    customFetch(`${PY_API_URL}/api/delete_file/${file}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {})
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const { Option } = Select;
  const handleChange = (value) => {
    setEtoProject(value);
    if (etoProjects[value]) {
      setDivisionName(
        `${etoProjects[value].division} ${etoProjects[value].divisionNum}`
      );
      setCustomer(etoProjects[value].customer);
      setJobType("");
    }
  };

  // const handlePOInputChange = (e) => {
  //   const value = e.target.value;
  //   if (/^\d*$/.test(value)) { // Only allow digits
  //     setPurchaseOrderNumber(value);
  //   }
  // };
  // each step of form is rendered here
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            {isLoading ? (
              <div className="loading-overlay">
                <Spin tip="Saving..." size="large">
                  <div className="content" />
                </Spin>
              </div>
            ) : null}
            <div id="border">
              <div className="cost-container">
                <div>
                  <label>Originator:</label>
                  {/* {(userRole === 'Engineer' || userRole === 'Admin') ?
                  <input className='autofill' type="text" name="originator" value={originator} onChange={(e) => setOriginator(e.target.value)} readonly required style={{
                    border: formErrors.originator ? '2px solid red' : '1px solid #ccc', borderRadius : '4px',
                  }}/> : <input className="autofill" type="text" name="originator" value={originator} required readOnly style={{
                    border: formErrors.originator ? '2px solid red' : '1px solid #ccc', borderRadius : '4px',
                  }}/>} */}
                  <input
                    className="autofill"
                    type="text"
                    name="originator"
                    value={originator}
                    required
                    readOnly
                    style={{
                      border: formErrors.originator
                        ? "2px solid red"
                        : "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                </div>

                <div>
                  <label>ETO Project:</label>
                  {userRole === "Engineer" ||
                  userRole === "Admin" ||
                  (userRole === "Project Leader" && jobID === -1) ? (
                    <Select
                      size={"large"}
                      name="etoProject"
                      value={etoProject}
                      onChange={handleChange}
                      showSearch
                      placeholder="Select ETO Project"
                      style={{
                        border: formErrors.etoProject ? "2px solid red" : null,
                        borderRadius: "4px",
                        width: "96%",
                      }}
                    >
                      {Object.keys(etoProjects)
                        .filter((key) => etoProjects[key].show === true)
                        .map((type) => (
                          <Option key={type} value={type}>
                            {type}
                          </Option>
                        ))}
                    </Select>
                  ) : (
                    <input
                      className="autofill"
                      type="text"
                      name="etoProject"
                      value={etoProject}
                      required
                      readOnly
                      style={{
                        border: formErrors.etoProject
                          ? "2px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="cost-container">
                <div>
                  <label>Division Name:</label>
                  <input
                    className="autofill"
                    type="text"
                    name="divisionName"
                    value={divisionName}
                    readonly
                    required
                  />
                </div>

                <div>
                  <label>Customer:</label>
                  <input
                    className="autofill"
                    type="text"
                    name="customer"
                    value={customer}
                    readonly
                    required
                  />
                </div>
              </div>
              <div className="cost-container">
                <div>
                  <label>Job Type:</label>
                  {(userRole === "Engineer" ||
                    userRole === "Admin" ||
                    (userRole === "Project Leader" && jobID === -1)) &&
                  etoProject ? (
                    <select
                      name="jobType"
                      value={jobType}
                      onChange={(e) => {
                        setJobType(e.target.value);
                        setJobGroup(jobTypes[e.target.value]);
                        if (
                          jobTypes[e.target.value] != "Q" &&
                          jobTypes[e.target.value] != ""
                        ) {
                          setCostCenter(
                            etoProjects[etoProject]["OHEcc"]["costCenter"]
                          );
                          setCostCenterDescription(
                            etoProjects[etoProject]["OHEcc"][
                              "costCenterDescription"
                            ]
                          );
                          setCostCenterManager(
                            etoProjects[etoProject]["OHEcc"][
                              "costCenterManager"
                            ]
                          );
                          setCostCenterApprover(
                            etoProjects[etoProject]["OHEcc"][
                              "costCenterApprover"
                            ]
                          );
                        } else {
                          setCostCenter("");
                          setCostCenterDescription("");
                          setCostCenterManager("");
                          setCostCenterApprover("");
                        }
                      }}
                      required
                      style={{
                        border: formErrors.jobType
                          ? "2px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                    >
                      <option value="" disabled selected>
                        Select Job Type
                      </option>
                      {Object.keys(jobTypes).map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      className="autofill"
                      type="text"
                      name="jobType"
                      value={jobType}
                      readonly
                      required
                    />
                  )}
                </div>
                <div>
                  <label>Group:</label>
                  <input
                    className="autofill"
                    type="text"
                    name="jobGroup"
                    value={jobGroup}
                    readonly
                    required
                  />
                </div>
                <div>
                  <label>Cost Center:</label>
                  {(userRole === "Engineer" ||
                    userRole === "Admin" ||
                    (userRole === "Project Leader" && jobID === -1)) &&
                  jobGroup == "Q" ? (
                    <select
                      name="costCenter"
                      value={costCenter}
                      onChange={(e) => {
                        setCostCenter(
                          etoProjects[e.target.value]["Qcc"]["costCenter"]
                        );
                        setCostCenterDescription(
                          etoProjects[e.target.value]["Qcc"][
                            "costCenterDescription"
                          ]
                        );
                        setCostCenterApprover(
                          etoProjects[e.target.value]["Qcc"][
                            "costCenterApprover"
                          ]
                        );
                        setCostCenterManager(
                          etoProjects[e.target.value]["Qcc"][
                            "costCenterManager"
                          ]
                        );
                      }}
                      required
                      style={{
                        border: formErrors.costCenter
                          ? "2px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                    >
                      <option value="" disabled selected>
                        Select Cost Center
                      </option>
                      {Object.keys(etoProjects)
                        .filter((key) => etoProjects[key].show === false)
                        .map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <input
                      className="autofill"
                      type="text"
                      name="costCenter"
                      value={costCenter}
                      readonly
                      required
                    />
                  )}
                </div>
              </div>
              <div className="cost-container">
                <div>
                  <label>Cost Center Description:</label>
                  <input
                    className="autofill"
                    type="text"
                    name="costCenterDescription"
                    value={costCenterDescription}
                    readonly
                    required
                  />
                </div>

                <div>
                  <label>Cost Center Manager:</label>
                  <input
                    className="autofill"
                    type="text"
                    name="costCenterManager"
                    value={costCenterManager}
                    readonly
                    required
                  />
                </div>
                <div>
                  <label>Cost Center Backup Approver:</label>
                  <input
                    className="autofill"
                    type="text"
                    name="costCenterApprover"
                    value={costCenterApprover}
                    readonly
                    required
                  />
                </div>
              </div>
              <div>
                <label>Test Report Distribution List:</label>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                >
                  <Select
                    mode="multiple"
                    disabled={userRole !== "Engineer" && userRole !== "Admin"}
                    allowClear
                    style={{
                      width: "97%",
                    }}
                    placeholder="Please select who you would like to be notified"
                    defaultValue={
                      distributionList.length > 1
                        ? distributionList.split(", ")
                        : []
                    }
                    onChange={(selectedOption) => {
                      // console.log(selectedOption)
                      var str = "";
                      for (const option of selectedOption) {
                        str = str.concat(option);
                        str = str.concat(", ");
                      }
                      setDistributionList(str.substring(0, str.length - 2));
                      // console.log(str);
                    }}
                    options={userNames}
                  />
                </Space>
                {/* <Select
                    options={userNames}
                    isSearchable
                    isMulti
                    placeholder="Search For Users"
                    onChange={(selectedOption) => {
                      console.log(selectedOption)
                      var str = "";
                      for (const option of selectedOption){
                        str = str.concat(option.label);
                        str = str.concat(", ");
                      }
                      setDistributionList(str.substring(0,str.length - 2));
                      console.log(str);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '54vw',
                        marginBottom: '5px',
                      }),
                    }}
                  /> */}
                {/* <input type="text" name="distributionList" value={distributionList} onChange={(e) => setDistributionList(e.target.value)} required/> */}
              </div>
              <div className="cost-container">
                <div>
                  <label>Sample Delivery Date:</label>
                  {userRole === "Engineer" ||
                  userRole === "Admin" ||
                  (userRole === "Project Leader" && jobID === -1) ? (
                    <input
                      type="date"
                      name="deliveryDate"
                      value={deliveryDate}
                      onChange={(e) => setDeliveryDate(e.target.value)}
                      required
                      style={{
                        border: formErrors.deliveryDate
                          ? "2px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                    />
                  ) : (
                    <input
                      className="autofill"
                      type="text"
                      name="deliveryDate"
                      value={deliveryDate}
                      readonly
                      required
                    />
                  )}
                </div>

                <div>
                  <label>Deposition Of Samples:</label>
                  {userRole === "Engineer" ||
                  userRole === "Admin" ||
                  (userRole === "Project Leader" && jobID === -1) ? (
                    <select
                      name="depositionSamples"
                      value={depositionSamples}
                      onChange={(e) => setDepositionSamples(e.target.value)}
                      required
                      style={{
                        border: formErrors.depositionSamples
                          ? "2px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                    >
                      <option value="Scrap">Scrap</option>
                      <option value="Return to Originator">
                        Return to Originator
                      </option>
                    </select>
                  ) : (
                    <input
                      className="autofill"
                      type="text"
                      name="depositionSamples"
                      value={depositionSamples}
                      readonly
                      required
                    />
                  )}
                </div>
                <div>
                  <label>Initiating Program:</label>
                  {userRole === "Engineer" ||
                  userRole === "Admin" ||
                  (userRole === "Project Leader" && jobID === -1) ? (
                    <input
                      type="text"
                      name="nocNumber"
                      value={nocNumber}
                      onChange={(e) => setNocNumber(e.target.value)}
                      required
                      maxLength="255"
                      style={{
                        border: formErrors.nocNumber
                          ? "2px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                    />
                  ) : (
                    <input
                      className="autofill"
                      type="text"
                      name="nocNumber"
                      value={nocNumber}
                      readonly
                      required
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div id="border">
            {isLoading ? (
              <div className="loading-overlay">
                <Spin tip="Saving..." size="large">
                  <div className="content" />
                </Spin>
              </div>
            ) : null}
            <div>
              <h3>Samples</h3>
              <div>
                {samples.map((sample, index) => (
                  <div key={sample.id} className="Samples-Container">
                    {userRole === "Engineer" ||
                    userRole === "Admin" ||
                    (userRole === "Project Leader" && jobID === -1) ? (
                      <button
                        type="button"
                        className="remove-sample"
                        onClick={() =>
                          sample.id
                            ? handleRemoveSample(sample.id)
                            : handleRemoveSample(sample.SamplesID)
                        }
                      >
                        X
                      </button>
                    ) : null}
                    <div>
                      <div className="samplesQuantity">
                        <label>Quantity:</label>
                        {userRole === "Engineer" ||
                        userRole === "Admin" ||
                        (userRole === "Project Leader" && jobID === -1) ? (
                          <input
                            type="number"
                            value={sample.Quantity}
                            min="0" // Enforce non-negative numbers
                            step="1" // Prevent decimals
                            onKeyDown={(e) => {
                              // Prevent invalid keys: '-', '.', and 'e'
                              if (["-", ".", "e"].includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Only allow positive integers
                              if (/^\d*$/.test(value)) {
                                handleInputChange(
                                  sample.id ? sample.id : sample.SamplesID,
                                  "Quantity",
                                  value
                                );
                              }
                            }}
                          />
                        ) : (
                          <input
                            className="autofill"
                            type="text"
                            value={sample.Quantity}
                            readOnly
                            required
                          />
                        )}
                      </div>
                      <div className="samplesPartName">
                        <label>Part Name:</label>
                        {userRole === "Engineer" ||
                        userRole === "Admin" ||
                        (userRole === "Project Leader" && jobID === -1) ? (
                          <input
                            type="text"
                            value={sample.PartName}
                            onChange={(e) =>
                              handleInputChange(
                                sample.id ? sample.id : sample.SamplesID,
                                "PartName",
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          <input
                            className="autofill"
                            type="text"
                            value={sample.PartName}
                            readonly
                            required
                          />
                        )}
                      </div>
                      <div className="samplesPartNumber">
                        <label>Part Number:</label>
                        {userRole === "Engineer" ||
                        userRole === "Admin" ||
                        (userRole === "Project Leader" && jobID === -1) ? (
                          <input
                            type="text"
                            value={sample.PartNumber}
                            onChange={(e) =>
                              handleInputChange(
                                sample.id ? sample.id : sample.SamplesID,
                                "PartNumber",
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          <input
                            className="autofill"
                            type="text"
                            value={sample.PartNumber}
                            readonly
                            required
                          />
                        )}
                      </div>
                      <div className="samplesRevision">
                        <label>Revision Level:</label>
                        {userRole === "Engineer" ||
                        userRole === "Admin" ||
                        (userRole === "Project Leader" && jobID === -1) ? (
                          <input
                            type="text"
                            value={sample.RevisionLevel}
                            onChange={(e) =>
                              handleInputChange(
                                sample.id ? sample.id : sample.SamplesID,
                                "RevisionLevel",
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          <input
                            className="autofill"
                            type="text"
                            value={sample.RevisionLevel}
                            readonly
                            required
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {userRole === "Engineer" ||
              userRole === "Admin" ||
              (userRole === "Project Leader" && jobID === -1) ? (
                <button
                  className="add-sample"
                  type="button"
                  onClick={handleAddSample}
                >
                  Add Sample
                </button>
              ) : null}
            </div>

            <div>
              <h3>Purchase Order and Purchase Order Attachments</h3>
              {/* <label >Purchase Order Number</label>
                  <input type="text" value={purchaseOrderNumber} onChange={e => setPurchaseOrderNumber(e.target.value)}/> */}
              {purchaseOrders.map((purchaseOrder, index) => (
                <div key={purchaseOrder.id} className="seperation-container">
                  <button
                    type="button"
                    className="remove-purchase"
                    onClick={() => handleRemovePurchaseOrder(purchaseOrder.id)}
                  >
                    X
                  </button>
                  <div className="purchase-info">
                    <label>Purchase Order Number</label>
                    {userRole === "Engineer" ||
                    userRole === "Admin" ||
                    (userRole === "Project Leader" && jobID === -1) ? (
                      <Tooltip title="Please enter only integers">
                        <input
                          type="text"
                          value={purchaseOrderNumber}
                          // onChange =
                          // { e => setPurchaseOrderNumber(e.target.value)}
                          onChange={(e) => {
                            const inputVal = e.target.value;
                            const numericVal = inputVal.replace(/\D/g, "");
                            setPurchaseOrderNumber(numericVal);
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <input
                        className="autofill"
                        type="text"
                        value={purchaseOrderNumber}
                        readonly
                        required
                      />
                    )}

                    {/* <label>Purchase Order Number</label> */}
                    {/* {(userRole === 'Engineer' || userRole === 'Admin') ? 
                        <Tooltip title="Please enter only integers">
                          <Input 
                            type="text" 
                            value={purchaseOrderNumber} 
                            onChange={handlePOInputChange} 
                          />
                        </Tooltip> 
                        : 
                        <Input 
                          className="autofill" 
                          type="text" 
                          value={purchaseOrderNumber} 
                          readOnly 
                          required 
                        />
                      } */}
                    <label className="purchaseOrderDesc">Description:</label>
                    {userRole === "Engineer" ||
                    userRole === "Admin" ||
                    (userRole === "Project Leader" && jobID === -1) ? (
                      <input
                        type="text"
                        value={purchaseOrderDescription}
                        onChange={(e) =>
                          setPurchaseOrderDescription(e.target.value)
                        }
                      />
                    ) : (
                      <input
                        className="autofill"
                        type="text"
                        value={purchaseOrderDescription}
                        readonly
                        required
                      />
                    )}
                    {userRole === "Engineer" ||
                    userRole === "Admin" ||
                    (userRole === "Project Leader" && jobID === -1) ? (
                      <div>
                        {/* {flowchartFile.name? flowchartFile.name: flowchartFile} <DownloadOutlined style={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => downloadFile(attachedFCFile ? attachedFCFile : flowchartFile)}/><span className='remove-file' onClick={() => {setFlowchartFile(null); setAttachedFCFile('');}}> <DeleteOutlined /></span></span> : attachedFCFile} </p>} */}
                        <label className="purchaseOrderAttachment">
                          Attach File:
                        </label>
                        {((attachedPOFile !== "No File Attached" &&
                          attachedPOFile !== "") ||
                          purchaseOrderAttachment) && (
                          <p
                            style={{
                              fontSize: "small",
                              color: "black",
                              margin: "0px",
                            }}
                          >
                            Attached File:{" "}
                            {purchaseOrderAttachment ? (
                              purchaseOrderAttachment.name ? (
                                <span>
                                  {purchaseOrderAttachment.name}{" "}
                                  <DownloadOutlined
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "5px",
                                    }}
                                    onClick={() =>
                                      downloadFile(purchaseOrderAttachment.name)
                                    }
                                  />
                                  <span
                                    className="remove-file"
                                    onClick={() => {
                                      setPurchaseOrderAttachment(null);
                                      setAttachedPOFile("");
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </span>
                                </span>
                              ) : (
                                <span>
                                  {purchaseOrderAttachment}{" "}
                                  <DownloadOutlined
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "5px",
                                    }}
                                    onClick={() =>
                                      downloadFile(purchaseOrderAttachment)
                                    }
                                  />
                                  <span
                                    className="remove-file"
                                    onClick={() => {
                                      setPurchaseOrderAttachment(null);
                                      setAttachedPOFile("");
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </span>
                                </span>
                              )
                            ) : (
                              <span>
                                {attachedPOFile}{" "}
                                <DownloadOutlined
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "5px",
                                  }}
                                  onClick={() => downloadFile(attachedPOFile)}
                                />
                                <span
                                  className="remove-file"
                                  onClick={() => {
                                    setPurchaseOrderAttachment(null);
                                    setAttachedPOFile("");
                                  }}
                                >
                                  <DeleteOutlined />
                                </span>
                              </span>
                            )}{" "}
                          </p>
                        )}
                        <input
                          type="file"
                          onChange={(e) =>
                            setPurchaseOrderAttachment(e.target.files[0])
                          }
                        />
                      </div>
                    ) : (
                      <div>
                        <label className="purchaseOrderAttachment">
                          Attached File:
                        </label>
                        {((attachedPOFile !== "No File Attached" &&
                          attachedPOFile !== "") ||
                          purchaseOrderAttachment) && (
                          <p>
                            {attachedPOFile
                              ? attachedPOFile
                              : purchaseOrderAttachment}{" "}
                            <DownloadOutlined
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                downloadFile(
                                  attachedPOFile
                                    ? attachedPOFile
                                    : purchaseOrderAttachment
                                )
                              }
                            />{" "}
                          </p>
                        )}
                      </div>
                    )}
                    {/* <label className='purchaseOrderAttachment'> Attach File</label>
                      <input type="file"  onChange={e => setPurchaseOrderAttachment(e.target.files[0])} /> */}
                  </div>
                </div>
              ))}
              {purchaseOrders.length === 1 ||
              userRole === "Project Leader" ||
              userRole === "Cost Center Manager" ? null : (
                <button
                  className="add-sample"
                  type="button"
                  onClick={handleAddPurchaseOrder}
                >
                  Add Purchase Order Details
                </button>
              )}
              {purchaseOrders.length !== 1 &&
              (userRole === "Project Leader" ||
                userRole === "Cost Center Manager") ? (
                <button
                  className="add-sample"
                  type="button"
                  onClick={handleAddPurchaseOrder}
                >
                  Show Purchase Order Details
                </button>
              ) : null}
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div id="border">
              <div>
                <h3>Engineering Specifications</h3>
                <div>
                  <label>Description:</label>
                  {userRole === "Engineer" ||
                  userRole === "Admin" ||
                  (userRole === "Project Leader" && jobID === -1) ? (
                    <input
                      type="text"
                      value={engineeringDescription}
                      onChange={(e) =>
                        setEngineeringDescription(e.target.value)
                      }
                    />
                  ) : (
                    <input
                      className="autofill"
                      type="text"
                      value={engineeringDescription}
                      readonly
                      required
                    />
                  )}
                </div>

                {/* {userRole === 'Engineer' || userRole === 'Admin' ? 
                  <div>
                    <label>Attach File:</label>
                    {((attachedSpecsFile !== 'No File Attached' && attachedSpecsFile !== '') || engineeringFile ) && <p style={{ fontSize: 'small', color: 'black', margin:'0px' }}>Attached File: {engineeringFile ? <span>
                      {engineeringFile.name? engineeringFile.name: engineeringFile} <span className='remove-file' onClick={() => {setEngineeringFile(null); setAttachedSpecsFile('');}}><DeleteOutlined /></span></span> : attachedSpecsFile} </p>}
                    <input type="file" name="file" onChange={e => setEngineeringFile(e.target.files[0])} />
                  </div> : 
                  <div>
                  <label>Attached File:</label>
                  {((attachedSpecsFile !== 'No File Attached' && attachedSpecsFile !== '') || engineeringFile ) && <p>{attachedSpecsFile} <DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => downloadFile(attachedSpecsFile)}/></p>}
                </div>} */}
                {userRole === "Engineer" ||
                userRole === "Admin" ||
                (userRole === "Project Leader" && jobID === -1) ? (
                  <div>
                    <label>Attach File:</label>
                    {(attachedSpecsFiles.length > 0 ||
                      engineeringFiles.length > 0) && (
                      <div>
                        <p
                          style={{
                            fontSize: "small",
                            color: "black",
                            margin: "0px",
                          }}
                        >
                          Attached Files:
                        </p>
                        {engineeringFiles.map((file, index) => (
                          <div key={index}>
                            {file.name ? (
                              <span
                                style={{
                                  fontSize: "small",
                                  color: "black",
                                  margin: "0px",
                                }}
                              >
                                {index + 1}. {file.name}{" "}
                                <span
                                  className="remove-file"
                                  onClick={() =>
                                    removeFile(index, "engineering", "")
                                  }
                                >
                                  <DeleteOutlined />
                                </span>
                              </span>
                            ) : (
                              <span
                                style={{
                                  fontSize: "small",
                                  color: "black",
                                  margin: "0px",
                                }}
                              >
                                {index + 1}. {file}{" "}
                                <DownloadOutlined
                                  style={{ cursor: "pointer" }}
                                  onClick={() => downloadFile(file)}
                                />
                                <span
                                  style={{ marginLeft: "5px" }}
                                  className="remove-file"
                                  onClick={() =>
                                    removeFile(index, "engineering", file)
                                  }
                                >
                                  <DeleteOutlined />
                                </span>
                              </span>
                            )}
                          </div>
                        ))}
                        {/* {attachedSpecsFiles.map((file, index) => (
                                    <div key={index}>
                                        {file.name && <span>{file.name} <span className='remove-file' onClick={() => removeFile(index, 'specs')}><DeleteOutlined /></span></span>}
                                    </div>
                                ))} */}
                      </div>
                    )}
                    <input
                      type="file"
                      name="file"
                      onChange={(e) => handleFileChange(e)}
                      multiple
                    />
                  </div>
                ) : (
                  <div>
                    <label>Attached Files:</label>
                    {(attachedSpecsFiles.length > 0 ||
                      engineeringFiles.length > 0) && (
                      <div>
                        {engineeringFiles.map((file, index) => (
                          <div key={index}>
                            {index + 1}. {file}{" "}
                            <DownloadOutlined
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                              onClick={() => downloadFile(file)}
                            />
                          </div>
                        ))}
                        {/* {attachedSpecsFiles.map((file, index) => (
                                    <div key={index}>{file.name}</div>
                                ))} */}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div>
                <h3>Flow Chart</h3>
                <div>
                  <label>Description:</label>
                  {userRole === "Engineer" ||
                  userRole === "Admin" ||
                  (userRole === "Project Leader" && jobID === -1) ? (
                    <input
                      type="text"
                      value={flowchartDescription}
                      onChange={(e) => setFlowchartDescription(e.target.value)}
                    />
                  ) : (
                    <input
                      className="autofill"
                      type="text"
                      value={flowchartDescription}
                      readonly
                      required
                    />
                  )}
                </div>
                {userRole === "Engineer" ||
                userRole === "Admin" ||
                (userRole === "Project Leader" && jobID === -1) ? (
                  <div>
                    <label>Attach Flow Chart:</label>
                    {((attachedFCFile !== "No File Attached" &&
                      attachedFCFile !== "") ||
                      flowchartFile) && (
                      <p
                        style={{
                          fontSize: "small",
                          color: "black",
                          margin: "0px",
                        }}
                      >
                        Attached File:{" "}
                        {flowchartFile ? (
                          <span>
                            {flowchartFile.name
                              ? flowchartFile.name != "No File Attached"
                                ? flowchartFile.name
                                : null
                              : flowchartFile !== "No File Attached"
                              ? flowchartFile
                              : null}{" "}
                            {(attachedFCFile
                              ? attachedFCFile
                              : flowchartFile) !== "No File Attached" && (
                              <DownloadOutlined
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                                onClick={() =>
                                  downloadFile(
                                    attachedFCFile
                                      ? attachedFCFile
                                      : flowchartFile
                                  )
                                }
                              />
                            )}
                            {(attachedFCFile
                              ? attachedFCFile
                              : flowchartFile) !== "No File Attached" && (
                              <span
                                className="remove-file"
                                onClick={() => {
                                  setFlowchartFile(null);
                                  setAttachedFCFile("");
                                }}
                              >
                                {" "}
                                <DeleteOutlined />
                              </span>
                            )}
                          </span>
                        ) : (
                          attachedFCFile
                        )}{" "}
                      </p>
                    )}
                    <input
                      type="file"
                      onChange={(e) => setFlowchartFile(e.target.files[0])}
                    />
                  </div>
                ) : (
                  <div>
                    <label>Attached File:</label>
                    {((attachedFCFile !== "No File Attached" &&
                      attachedFCFile !== "") ||
                      flowchartFile) && (
                      <p>
                        {attachedFCFile ? attachedFCFile : flowchartFile}{" "}
                        <DownloadOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            downloadFile(
                              attachedFCFile ? attachedFCFile : flowchartFile
                            )
                          }
                        />{" "}
                      </p>
                    )}
                  </div>
                )}

                {/* {(userRole !== 'Test Technician') ?
                  <div>
                    {isLoading && (
                      <div className="loading-overlay">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Saving...</span>
                        </Spinner>
                      </div>
                    )}
                    <button className='flow-chart-btn' type='button' onClick={EnterFlowChart}>Enter Flow Chart Environment</button>
                    {(userRole !== 'Cost Center Manager') && <p style={{ fontSize: 'small', color: 'green', margin:'0px', marginLeft:'3px'  }}>{fromFlowChart === true ? "Flow Chart Updated": null}</p>}
                  </div>
                   : null} */}
                {userRole !== "Test Technician" ? (
                  <div>
                    {isLoading ? (
                      <div className="loading-overlay">
                        <Spin tip="Saving..." size="large">
                          <div className="content" />
                        </Spin>
                      </div>
                    ) : (
                      <>
                        <button
                          className="flow-chart-btn"
                          type="button"
                          onClick={EnterFlowChart}
                        >
                          Enter Flow Chart Environment
                        </button>
                        {userRole !== "Cost Center Manager" && (
                          <p
                            style={{
                              fontSize: "small",
                              color: "green",
                              margin: "0px",
                              marginLeft: "3px",
                            }}
                          >
                            {fromFlowChart === true
                              ? "Flow Chart Updated"
                              : null}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <div id="loading">
              <div className="loading-container">
                {loading && (
                  <div>
                    <center class="loader"></center>
                  </div>
                )}
                {!loading && (
                  <div>
                    <center>Job Successfully Saved</center>
                  </div>
                )}
                {!loading && (
                  <Link to="/">
                    <button className="submitted">Go Home</button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div id="loading">
            <div className="loading-container">
              {loading && (
                <div>
                  <center class="loader"></center>
                </div>
              )}
              {!loading && (
                <div>
                  <center>Job Successfully Submitted</center>
                </div>
              )}
              {!loading && (
                <Link to="/">
                  <button className="submitted">Go Home</button>
                </Link>
              )}
            </div>
          </div>
        );
      case 6:
        return (
          <div>
            {userRole === "Project Leader" && quotationSubmitted === 0 && (
              <Alert
                message="You have assigned yourself as the Project Leader for this job. Please fill out the quotation."
                type="success"
              />
            )}
            <br />
            {(userRole === "Project Leader" || userRole === "Admin") && (
              <div>
                <Table
                  columns={nodes_columns}
                  dataSource={data}
                  style={{ marginBottom: "20px" }}
                  size="small"
                  pagination={{
                    pageSize: 50,
                  }}
                  scroll={{
                    y: 200,
                  }}
                />
              </div>
            )}
            {userRole === "Project Leader" || userRole === "Admin" ? (
              <div>
                <div className="dates-container">
                  <label>
                    Job Name:
                    <input
                      type="text"
                      value={jobName}
                      onChange={(e) => setJobName(e.target.value)}
                    />
                  </label>

                  <label>
                    Start Date:
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </label>

                  <label>
                    End Date:
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </label>
                </div>
                <Tabs size="large" type="card">
                  {status === "Job Approved by CCM" ||
                  status === "Job Complete" ||
                  status === "Testing In Progress" ||
                  status === "Pending CCM Approval" ? (
                    <Tabs.TabPane tab="Estimated Costs" key="1">
                      <div>
                        <label>
                          <Checkbox disabled> On Completion</Checkbox>
                        </label>
                        <table>
                          <thead>
                            <tr>
                              <th>Hourly Cost</th>
                              <th>Estimated ETO Hours</th>
                              <th>Estimated ETO Cost</th>
                              <th>Estimated Outsourcing Cost</th>
                              <th>Estimated Fixture Cost</th>
                              <th>Estimated Total Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="cost-row">
                              <td>
                                <input
                                  className="autofill"
                                  type="number"
                                  value={hourlyCost}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  className="autofill"
                                  type="number"
                                  value={estimatedEtoHours}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  className="autofill"
                                  type="number"
                                  value={estimatedEtoCost}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  className="autofill"
                                  type="number"
                                  value={estimatedOutsourcingCost}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  className="autofill"
                                  type="number"
                                  value={estimatedFixtureCost}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  className="autofill"
                                  type="number"
                                  value={estimatedTotalCost}
                                  readOnly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Tabs.TabPane>
                  ) : (
                    <Tabs.TabPane tab="Estimated Costs" key="1">
                      <div>
                        <label>
                          <Checkbox onChange={handleCheckboxChange}>
                            {" "}
                            On Completion
                          </Checkbox>
                        </label>
                        <table>
                          <thead>
                            <tr>
                              <th>Hourly Cost</th>
                              <th>Estimated ETO Hours</th>
                              <th>Estimated ETO Cost</th>
                              <th>Estimated Outsourcing Cost</th>
                              <th>Estimated Fixture Cost</th>
                              <th>Estimated Total Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="cost-row">
                              <td>
                                <input
                                  type="number"
                                  value={hourlyCost}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    setHourlyCost(value < 0 ? 0 : value);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={estimatedEtoHours}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    setEstimatedEtoHours(value < 0 ? 0 : value);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={estimatedEtoCost}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={estimatedOutsourcingCost}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    setEstimatedOutsourcingCost(
                                      value < 0 ? 0 : value
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={estimatedFixtureCost}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    setEstimatedFixtureCost(
                                      value < 0 ? 0 : value
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={estimatedTotalCost}
                                  readOnly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Tabs.TabPane>
                  )}

                  {(status === "Job Approved by CCM" ||
                    status === "Job Complete" ||
                    status === "Testing In Progress") && (
                    <Tabs.TabPane tab="Final Costs" key="2">
                      <div>
                        {/* <label>
                          <Checkbox onChange={handleCheckboxChange}> On Completion</Checkbox>
                        </label> */}
                        <table>
                          <thead>
                            <tr>
                              <th>Hourly Cost</th>
                              <th>Final ETO Hours</th>
                              <th>Final ETO Cost</th>
                              <th>Final Outsourcing Cost</th>
                              <th>Final Fixture Cost</th>
                              <th>Final Total Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="cost-row">
                              <td>
                                <input
                                  type="number"
                                  value={finalHourlyCost}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    setFinalHourlyCost(value < 0 ? 0 : value);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={finalEtoHours}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    setFinalEtoHours(value < 0 ? 0 : value);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={finalEtoCost}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={finalOutsourcingCost}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    setFinalOutsourcingCost(
                                      value < 0 ? 0 : value
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={finalFixtureCost}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    setFinalFixtureCost(value < 0 ? 0 : value);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={finalTotalCost}
                                  readOnly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {status !== "Job Completed" && (
                          <Button
                            className="quotation-save"
                            type="primary"
                            style={{ width: "10%" }}
                            onClick={handleSubmitQuotation}
                          >
                            Update Final Quotation
                          </Button>
                        )}
                      </div>
                    </Tabs.TabPane>
                  )}
                </Tabs>

                <div style={{ display: "table", width: "100%" }}>
                  {status !== "Job Complete" && (
                    <div
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        borderRight: "1px solid #ccc",
                        width: "20%",
                      }}
                    >
                      <Upload onChange={handleQuotationFileChange}>
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </div>
                  )}
                  <div
                    style={{
                      display: "table-cell",
                      verticalAlign: "top",
                      paddingLeft: status !== "Job Complete" ? "5%" : "0",
                    }}
                  >
                    {quotationFiles.length > 0 && (
                      <div>
                        <h4>Attached Files:</h4>
                        {quotationFiles.map((file, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "8px",
                            }}
                          >
                            <span>{file.name || file}</span>
                            <DownloadOutlined
                              style={{ cursor: "pointer", marginLeft: "10px" }}
                              onClick={() => downloadFile(file.name || file)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <label>
                  Outsourcing Details:
                  <input
                    type="text"
                    value={outsourcingDetails}
                    onChange={(e) => setOutsourcingDetails(e.target.value)}
                  />
                  {/* <textarea value={outsourcingDetails} onChange={(e) => setOutsourcingDetails(e.target.value)} /> */}
                </label>

                {/* <label> */}
                {/* <input type="checkbox" checked={onCompletion} onChange={handleCheckboxChange} /> */}
                {/* <Checkbox onChange={handleCheckboxChange}> On Completion</Checkbox> */}
                {/* </label> */}
                <br />
                <label>
                  Comments:
                  <input
                    type="text"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                  {/* <textarea value={comments} onChange={(e) => setComments(e.target.value)} /> */}
                </label>
              </div>
            ) : (
              <div>
                <div className="dates-container">
                  <label>
                    Job Name:
                    <input
                      className="autofill"
                      type="text"
                      name="jobType"
                      value={jobName}
                      readonly
                      required
                    />
                  </label>

                  <label>
                    Start Date:
                    <input
                      className="autofill"
                      type="text"
                      name="jobType"
                      value={startDate}
                      readonly
                      required
                    />
                  </label>

                  <label>
                    End Date:
                    <input
                      className="autofill"
                      type="text"
                      name="jobType"
                      value={endDate}
                      readonly
                      required
                    />
                  </label>
                </div>

                <h2>Costs</h2>
                <label>
                  <Checkbox disabled checked={onCompletion}>
                    {" "}
                    On Completion
                  </Checkbox>
                </label>
                <table>
                  <thead>
                    <tr>
                      {/* <th>Cost Type</th>
                        <th>Value in €</th> */}
                      <th>Hourly Cost</th>
                      <th>Estimated ETO Hours</th>
                      <th>Estimated ETO Cost</th>
                      <th>Estimated Outsourcing Cost</th>
                      <th>Estimated Fixture Cost</th>
                      <th>Estimated Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="cost-row">
                      <td>
                        <input
                          className="autofill"
                          value={hourlyCost}
                          readonly
                          required
                        />
                      </td>
                      <td>
                        <input
                          className="autofill"
                          value={estimatedEtoHours}
                          readonly
                          required
                        />
                      </td>
                      <td>
                        <input
                          className="autofill"
                          value={estimatedEtoCost}
                          readonly
                          required
                        />
                      </td>
                      <td>
                        <input
                          className="autofill"
                          value={estimatedOutsourcingCost}
                          readonly
                          required
                        />
                      </td>
                      <td>
                        <input
                          className="autofill"
                          value={estimatedFixtureCost}
                          readonly
                          required
                        />
                      </td>
                      <td>
                        <input
                          className="autofill"
                          value={estimatedTotalCost}
                          readonly
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <label>
                  Outsourcing Details:
                  <input
                    className="autofill"
                    value={outsourcingDetails}
                    readonly
                    required
                  />
                </label>

                <br />
                <label>
                  Project Leader's Comments:
                  <input
                    className="autofill"
                    value={comments}
                    readonly
                    required
                  />
                </label>
                {/* <label>
                    Comments:
                    <input className="autofill" value={comments} readonly required/>
                  </label> */}
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="form-container"
      onClick={handleClick}
      onMouseMove={handleMove}
    >
      <div className="content-container">
        {/* <Heatmap clicks={clicks} moves={moves} /> */}
        {/* NavigationPanel changes depnding on step number */}
        {step !== 4 && step !== 5 && (
          <NavigationPanel
            currentStep={step}
            onStepClick={handleStepClick}
            status={status}
          />
        )}
        <form onSubmit={handleSubmit}>
          {(status === "Job Approved by CCM" ||
            status === "Testing In Progress") &&
            (userRole === "Project Leader" || userRole === "Admin") &&
            step === 6 && (
              <Flex
                gap="small"
                wrap="nowrap"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  className="save-details"
                  type="default"
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSaveDetails(quotationData);
                  }}
                >
                  <span>Save Details and Go Back</span>
                </Button>
                <Button
                  className="job-complete"
                  type="primary"
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCloseJobModal();
                  }}
                >
                  <span>Job Complete - Close Job</span>
                </Button>
                <Modal
                  title={`Closing JOB${currentYear}-${jobID}`}
                  open={isModalVisible}
                  onCancel={() => setIsModalVisible(false)}
                  width={700}
                  footer={null}
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <p style={{ fontSize: "15px", marginBottom: "0" }}>
                      Please select your closing date:
                    </p>
                    <input
                      type="date"
                      value={closingDate}
                      onChange={handleDateChange}
                      style={{
                        marginTop: "0",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        border: "1px solid #d9d9d9",
                        boxSizing: "border-box",
                        width: "30%",
                      }}
                    />
                    <p style={{ fontSize: "15px", marginBottom: "0" }}>
                      Close Job Notes:
                    </p>
                    <TextArea
                      value={closingNotes}
                      onChange={handleNotesChange}
                      maxLength={255}
                      rows={4}
                      placeholder="Enter your closing job notes here (character limit 255)..."
                      style={{
                        marginTop: "0",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        border: "1px solid #d9d9d9",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    />
                    <p style={{ fontSize: "15px", marginBottom: "0" }}>
                      Outsourcing Details:
                    </p>
                    <TextArea
                      value={outsourcingDetails}
                      onChange={(e) => setOutsourcingDetails(e.target.value)}
                      maxLength={255}
                      rows={4}
                      placeholder="Enter outsourcing details here..."
                      style={{
                        marginTop: "0",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        border: "1px solid #d9d9d9",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    />
                    <Button
                      className="closing-confirm"
                      type="primary"
                      onClick={() =>
                        confirmClosingJob(jobData, closingDate, closingNotes)
                      }
                    >
                      Confirm
                    </Button>
                  </div>
                </Modal>
              </Flex>
            )}
          <h2>
            {step === 1
              ? "General Information"
              : step === 2
              ? "Job Description"
              : step === 3
              ? "Test Instructions"
              : null}
          </h2>
          <div>{renderStep()}</div>
          <div className="button-container">
            {/* {step === 6 && quotationSubmitted === 1 && onCompletion && userRole === 'Project Leader' && 
              <Button className='quotation-save' type="primary"  style={{width: '24%'}} onClick={handleSubmitQuotation}>Update Quotation</Button>
            } */}

            {/* {(userRole === 'Engineer' || userRole === 'Admin') && (step === 3 && !formSubmitted && !formSaved && <button className='sub-button' type="submit" >Submit</button>)} */}

            {step === 6 &&
              quotationSubmitted === 0 &&
              (userRole === "Project Leader" || userRole === "Admin") && (
                <Flex gap="small" wrap="wrap">
                  <Button
                    className="view-datails"
                    type="primary"
                    style={{ width: "24%" }}
                    onClick={() => setStep(1)}
                  >
                    View Details
                  </Button>

                  {
                    <Button
                      className="quotation-return"
                      type="primary"
                      danger
                      style={{ width: "30%" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (jobID && originator) {
                          showConfirmReturnToOrignator(
                            jobData,
                            jobID,
                            originator,
                            userName
                          );
                        } else {
                          console.error("Job data is incomplete", job);
                        }
                      }}
                    >
                      Return To Originator
                    </Button>
                  }
                  {(sendForApprovalStatus || nodes.length === 0) && (
                    <Button
                      className="quotation-approval"
                      type="primary"
                      style={{ width: "24%" }}
                      onClick={showConfirmSubmitQuotation}
                    >
                      Send For Approval
                    </Button>
                  )}
                  {!sendForApprovalStatus && nodes.length !== 0 && (
                    <>
                      <Tooltip
                        title="There are incomplete tests in the Flow Chart"
                        mouseEnterDelay={0.5}
                      >
                        <Button
                          className="quotation-approval"
                          type="primary"
                          style={{ width: "24%" }}
                          disabled
                        >
                          Send For Approval
                        </Button>
                      </Tooltip>
                    </>
                  )}

                  {/* <Link to="/"><Button className='quotation-cancel' danger  style={{width: '24%'}}>Back</Button></Link> */}
                </Flex>
              )}
          </div>
          {/* <h2>{step === 1 ? "General Information" : step === 2 ? "Job Description" : step === 3 ? "Test Instructions" : null}</h2> */}
          {/* <div>
          {renderStep()}
        </div> */}
          <div>
            {step === 6 &&
              status === "Pending CCM Approval" &&
              (userRole === "Cost Center Manager" || userRole === "Admin") && (
                <Flex
                  className="inner-button-container"
                  gap="small"
                  wrap="wrap"
                >
                  {/* <Link to="/"><Button className='quotation-cancel' danger  style={{width: '24%'}}>Cancel</Button></Link> */}
                  <Button
                    className="view-datails"
                    type="primary"
                    onClick={() => setStep(1)}
                  >
                    View Details
                  </Button>
                  <Button
                    className="quotation-return"
                    type="primary"
                    danger
                    onClick={(e) =>
                      showConfirmCcmReject(
                        e,
                        jobID,
                        originator,
                        costCenterManager
                      )
                    }
                  >
                    Reject
                  </Button>
                  <Button
                    className="quotation-approval"
                    type="primary"
                    onClick={(e) =>
                      showConfirmCcmApprove(
                        e,
                        jobID,
                        projectLeader,
                        costCenterManager
                      )
                    }
                  >
                    Approve
                  </Button>
                  {/* <Link to="/"><Button className='quotation-cancel' danger  style={{width: '24%'}}>Back</Button></Link> */}
                </Flex>
              )}
            <Flex className="button-container" gap="small">
              {/* {step > 1 && step < 4 && !formSubmitted && <Button type="primary" className="prev-button"  onClick={handlePrevious} style={{ zIndex: 1, position: 'relative' }} >Previous</Button>} */}
              {step > 1 && step < 4 && !formSubmitted && (
                <button
                  className="prev-button"
                  type="button"
                  onClick={handlePrevious}
                  style={step === 3 ? { right: "10%" } : {}}
                >
                  Previous
                </button>
              )}
              {/* {step < 3 && !formSubmitted && <Button type="primary" className="next-button" onClick={handleNext} >Next</Button>} */}
              {step < 3 && !formSubmitted && (
                <button
                  className="next-button"
                  type="button"
                  onClick={handleNext}
                  style={step === 1 ? { marginLeft: "50%" } : {}}
                >
                  Next
                </button>
              )}
              {/* {userRole !== 'Engineer' && (step <= 3 && !formSubmitted && (fromFlowChart || fromFlowChartBackBtn) && <button className="goquo-button" type='button' onClick={() => setStep(6)}>Go to Quotation</button>)} */}
              {userRole !== "Engineer" && step === 3 && !formSubmitted && (
                <button
                  className="goquo-button"
                  type="button"
                  onClick={handleGoToQuotation}
                >
                  Go to Quotation
                </button>
              )}
            </Flex>

            {/* {(userRole === 'Engineer' || userRole === 'Admin') && !loading && (step <= 3 && !formSubmitted && !formSaved && <button className='save-button' type="button" onClick={handleSave}>Save</button>)} */}
            {/* {(userRole === 'Engineer' || userRole === 'Admin') && !loading && (step === 3 && !formSubmitted && !formSaved && <button className='sub-button' type="submit" >Submit</button>)} */}

            {/* {step === 6 && quotationSubmitted && <Link to="/">
                    <button type="button">
                        Job Sent For Approval. Click To Go Home.
                    </button>
                </Link>} */}
          </div>
          {(userRole === "Engineer" ||
            userRole === "Admin" ||
            (userRole === "Project Leader" && jobID === -1)) &&
            !loading &&
            step <= 2 &&
            !formSubmitted && (
              <button
                className="save-button"
                type="button"
                onClick={handleSave}
                style={{ marginLeft: "0%", width: "100%" }}
              >
                Save
              </button>
            )}
          {(userRole === "Engineer" ||
            userRole === "Admin" ||
            (userRole === "Project Leader" && jobID === -1)) &&
            !loading &&
            step === 3 &&
            !formSubmitted && (
              <button
                className="save-button"
                type="button"
                onClick={handleSave}
                style={{ marginLeft: "0%", width: "48%" }}
              >
                Save
              </button>
            )}
          {(userRole === "Engineer" ||
            userRole === "Admin" ||
            (userRole === "Project Leader" && jobID === -1)) &&
            !loading &&
            step === 3 &&
            !formSubmitted && (
              <button
                className="sub-button"
                type="submit"
                style={{ marginLeft: "10px", width: "48%" }}
              >
                Submit
              </button>
            )}
        </form>
      </div>
    </div>
  );
}

export default AddJob;
