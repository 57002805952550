import React, { useState, useEffect } from 'react';
import { StarOutlined, UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Input, Form, Button, Tabs, message, Upload,Tag, Row, Col, Select, Modal  } from 'antd';
import './adminPanel.css';
import axios from 'axios';
import { customFetch } from './customFetch';



const { TabPane } = Tabs;
const { Option } = Select;

const PY_API_URL = process.env.REACT_APP_PY_API_URL;

const AdminPanel = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Submitted',
    });
  };
  // State hooks for all inputs
  const [projectNumber, setProjectNumber] = useState('');
  const [program, setProgram] = useState('');
  const [pm, setPm] = useState('');
  const [customer, setCustomer] = useState('');
  const [project, setProject] = useState('');
  const [cdc, setCdc] = useState('');
  const [division, setDivision] = useState('');
  const [testType, setTestType] = useState('');
  const [group, setGroup] = useState('');
  const [costCenterProjectNumber, setCostCenterProjectNumber] = useState('');
  const [costCenterProgram, setCostCenterProgram] = useState('');
  const [costCenterPm, setCostCenterPm] = useState('');
  const [bckPm, setBckPm] = useState('');
  const [costCenterCdc, setCostCenterCdc] = useState('');
  const [chargeTo, setChargeTo] = useState('');
  const [fixtureName, setFixtureName] = useState('');
  const [climaticChamberName, setClimaticChamberName] = useState('');
  const [testName, setTestName] = useState('');
  const [testNumber, setTestNumber] = useState('');
  const [file, setFile] = useState(null);

  //modifying
  const [etoProjects, setEtoProjects] = useState({});
  const [selectedProject, setSelectedProject] = useState(null);
  const [modifiedProject, setModifiedProject] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = () => {
    customFetch(`${PY_API_URL}/api/get_project_list`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setEtoProjects(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleModifyProject = () => {
    // Implement the modify project logic here.
    message.success(`Project ${selectedProject} modified to ${modifiedProject}`);
  };

  const handleRemoveProject = () => {
    // Implement the remove project logic here.
    setIsModalVisible(false);
    message.success(`Project ${selectedProject} removed successfully`);
  };

  const [jobTypes, setJobTypes] = useState({});
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [modifiedJobType, setModifiedJobType] = useState('');

  useEffect(() => {
    fetchJobTypes();
  }, []);

  const fetchJobTypes = () => {
    customFetch(`${PY_API_URL}/api/get_job_types`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setJobTypes(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleModifyJobType = () => {
    // Implement the modify job type logic here.
    message.success(`Job Type ${selectedJobType} modified to ${modifiedJobType}`);
  };

  const handleRemoveJobType = () => {
    // Implement the remove job type logic here.
    setIsModalVisible(false);
    message.success(`Job Type ${selectedJobType} removed successfully`);
  };

  const [fixtures, setFixtures] = useState([]);
  const [selectedFixture, setSelectedFixture] = useState(null);
  console.log(selectedFixture);
  const [modifiedFixtureName, setModifiedFixtureName] = useState('');

  useEffect(() => {
    fetchFixtures();
  }, []);

  const fetchFixtures = () => {
    customFetch(`${PY_API_URL}/api/get_fixtures`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setFixtures(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleModifyFixture = () => {
    // Implement the modify fixture logic here
    message.success(`Fixture ${selectedFixture} modified to ${modifiedFixtureName}`);
  };

  const handleRemoveFixture = () => {
    // Implement the remove fixture logic here
    setIsModalVisible(false);
    message.success(`Fixture ${selectedFixture} removed successfully`);
  };
  
  const [climaticChambers, setClimaticChambers] = useState([]);
  const [selectedChamber, setSelectedChamber] = useState(null);
  const [modifiedChamberName, setModifiedChamberName] = useState('');
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  useEffect(() => {
    customFetch((`${PY_API_URL}/api/get_climatic_chambers`), {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        let res = response.json()
        return res; 
      })
      .then((data) => {
        setClimaticChambers(data); 
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []); 

   // Handle modify chamber
   const handleModifyChamber = () => {
    if (selectedChamber && modifiedChamberName) {
      // Implement API call to update the chamber name
      console.log('Modify chamber:', selectedChamber, 'New name:', modifiedChamberName);
      // Logic to send modified data to the server and update state
    }
  };

  // Handle remove chamber
  const handleRemoveChamber = () => {
    if (selectedChamber) {
      console.log('Removing chamber with ID:', selectedChamber);
      // Logic to send delete request to the server and update state
      setDeleteModalVisible(false);
    }
  };


  const handleProjectNumberChange = (e) => setProjectNumber(e.target.value);
  const handleProgramChange = (e) => setProgram(e.target.value);
  const handlePmChange = (e) => setPm(e.target.value);
  const handleCustomerChange = (e) => setCustomer(e.target.value);
  const handleProjectChange = (e) => setProject(e.target.value);
  const handleCdcChange = (e) => setCdc(e.target.value);
  const handleDivisionChange = (e) => setDivision(e.target.value);
  const handleTestTypeChange = (e) => setTestType(e.target.value);
  const handleGroupChange = (e) => setGroup(e.target.value);
  const handleCostCenterProjectNumberChange = (e) => setCostCenterProjectNumber(e.target.value);
  const handleCostCenterProgramChange = (e) => setCostCenterProgram(e.target.value);
  const handleCostCenterPmChange = (e) => setCostCenterPm(e.target.value);
  const handleBckPmChange = (e) => setBckPm(e.target.value);
  const handleCostCenterCdcChange = (e) => setCostCenterCdc(e.target.value);
  const handleChargeToChange = (e) => setChargeTo(e.target.value);
  const handleFixtureNameChange = (e) => setFixtureName(e.target.value);
  const handleClimaticChamberNameChange = (e) => setClimaticChamberName(e.target.value);
  const handleTestNameChange = (e) => setTestName(e.target.value);
  const handleTestNumberChange = (e) => setTestNumber(e.target.value);
  
  // Handle button clicks
  const handleSubmitProject = () => {
    try {
        const response = axios.post(`${PY_API_URL}/api/add_new_eto_project`, {
          projectNumber: projectNumber,
          program: program,
          pm: pm,
          customer: customer, 
          project: project,
          cdc: cdc,
          division: division,
        });
        // success()
        message.success("Added successfully");
      } catch (error) {
        console.error('Error submitting new eto project:', error);
      }
  };

  const handleSubmitJobType = () => {
    try {
        const response = axios.post(`${PY_API_URL}/api/add_new_job_type`, {
          testType: testType,
          group: group,
        });
        // success();
        message.success("Added successfully");
      } catch (error) {
        console.error('Error submitting new job type:', error);
      }
  };

  const handleSubmitCostCenter = () => {
    try {
        const response = axios.post(`${PY_API_URL}/api/add_cost_center`, {
          projectNumber: costCenterProjectNumber,
          program: costCenterProgram,
          pm: costCenterPm,
          bckpm: bckPm, 
          cdc: costCenterCdc,
          chargeTo: chargeTo,
        });
        // success();
        message.success("Added successfully");
      } catch (error) {
        console.error('Error submitting new cost center:', error);
      }
  };

  const handleSubmitFixture = () => {
    try {
        const response = axios.post(`${PY_API_URL}/api/add_new_fixture`, {
          fixtureName: fixtureName,
        });
        // success()
        message.success("Added successfully");
      } catch (error) {
        console.error('Error submitting new fixture:', error);
      }
  };

  const handleSubmitClimaticChamber = () => {
    try {
        const response = axios.post(`${PY_API_URL}/api/add_climatic_chamber`, {
            chamberName: climaticChamberName,
        });
        // success();
        message.success("Added successfully");
      } catch (error) {
        console.error('Error submitting new climatic chamber:', error);
      }
  };

  const handleSubmitTestInstruction = async () => {
    if (!file || !testName || !testNumber) {
      message.error("Please complete all fields and upload a file.");
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('testName', testName);
    formData.append('testNumber', testNumber);
  
    try {
      const response = await axios.post(`${PY_API_URL}/api/add_new_test_instruction`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success("Test instruction added successfully");
    } catch (error) {
      message.error('Error submitting new test instruction');
      console.error('Error submitting new test instruction:', error);
    }
  };

  const handleUpload = (file) => {
    if (file.type !== 'application/pdf') {
      message.error(`${file.name} is not a PDF file.`);
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return false; // Prevent automatic upload
  };

  // Function to handle file upload
  // const handleUpload = (file) => {
  //   if (file.type !== 'application/pdf') {
  //     message.error(`${file.name} is not a PDF file.`);
  //     return Upload.LIST_IGNORE;
  //   }
  
  //   const formData = new FormData();
  //   formData.append('file', file);
  
  //   fetch(`${PY_API_URL}/api/add_new_test_instruction`, {
  //     method: 'POST',
  //     body: formData,
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.message) {
  //         message.success("Test instruction added successfully");
  //       } else {
  //         message.error("Failed to add test instruction");
  //       }
  //     })
  //     .catch(error => {
  //       message.error("An error occurred while uploading the file.");
  //       console.error("Error:", error);
  //     });
  
  //   return true;
  // };

  // const handleChange = (info) => {
  //   if (info.file.status === 'uploading') {
  //     console.log("uploading"); // Log uploading status
  //   }
  //   if (info.file.status === 'done') {
  //     console.log('uploaded'); // Log uploaded status
  //   }
  // };

  // useEffect(() => {
  //   customFetch(`${PY_API_URL}/api/get_project_list`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       let res = response.json()
  //       return res; 
  //     })
  //     .then((data) => {
  //       setEtoProjects(data); 
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []); 


  return (
    <div className="admin-panel-container">
      <h2 style={{ display: 'inline-block', marginRight: '10px', marginBottom: '0' }}>Admin Panel</h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="ETO Project" key="1">
          <Tabs>
          <TabPane tab="Add" key="1.1">
            <Card bordered>
              <Form layout="horizontal" style={{ maxWidth: 400 }}>
                <Form.Item label="Project Number">
                  <Input value={projectNumber} onChange={handleProjectNumberChange} placeholder='T70330 Carryover WR MAP'/>
                </Form.Item>
                <Form.Item label="Program">
                  <Input value={program} onChange={handleProgramChange} placeholder='Carryover WR MAP'/>
                </Form.Item>
                <Form.Item label="PM">
                  <Input value={pm} onChange={handlePmChange} placeholder='Paolo Casalini'/>
                </Form.Item>
                <Form.Item label="Customer">
                  <Input value={customer} onChange={handleCustomerChange} placeholder='Generic'/>
                </Form.Item>
                <Form.Item label="Project">
                  <Input value={project} onChange={handleProjectChange} placeholder='T70330'/>
                </Form.Item>
                <Form.Item label="CDC">
                  <Input value={cdc} onChange={handleCdcChange}/>
                </Form.Item>
                <Form.Item label="Division">
                  <Input value={division} onChange={handleDivisionChange} placeholder='MAP (295)'/>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={handleSubmitProject}>
                    Submit New ETO Project
                  </Button>
                </Form.Item>
              </Form>
            </Card>
            </TabPane>
            <TabPane tab="Modify" key="1.2">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Select Project">
                    <Select
                      placeholder="Select a project to modify"
                      onChange={(value) => setSelectedProject(value)}
                    >
                      {Object.keys(etoProjects).map((project) => (
                        <Option key={project} value={project}>
                          {etoProjects[project].project_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectedProject && (
                    <Form.Item label="New Project Name">
                      <Input
                        placeholder="Enter new project name"
                        value={modifiedProject}
                        onChange={(e) => setModifiedProject(e.target.value)}
                      />
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={handleModifyProject}
                      disabled={!selectedProject || !modifiedProject}
                    >
                      Modify Project
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </TabPane>
            <TabPane tab="Remove" key="1.3">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Select Project">
                    <Select
                      placeholder="Select a project to remove"
                      onChange={(value) => setSelectedProject(value)}
                    >
                      {Object.keys(etoProjects).map((project) => (
                        <Option key={project} value={project}>
                          {etoProjects[project].project_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      danger
                      onClick={() => setIsModalVisible(true)}
                      disabled={!selectedProject}
                    >
                      Remove Project
                    </Button>
                  </Form.Item>
                </Form>
                <Modal
                  title="Confirm Project Removal"
                  visible={isModalVisible}
                  onOk={handleRemoveProject}
                  onCancel={() => setIsModalVisible(false)}
                >
                  <p>Are you sure you want to remove the project "{selectedProject}"?</p>
                </Modal>
              </Card>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Job Type" key="2">
      <Tabs>
        <TabPane tab="Add" key="2.1">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Test Type">
                <Input value={modifiedJobType} onChange={handleTestTypeChange} placeholder='ETO' />
              </Form.Item>
              <Form.Item label="Group">
                <Input value={selectedJobType} onChange={handleGroupChange} placeholder='OH' />
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitJobType}>
                  Submit New Job Type
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
        
        <TabPane tab="Modify" key="2.2">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Select Job Type">
                <Select
                  placeholder="Select a job type to modify"
                  onChange={(value) => setSelectedJobType(value)}
                >
                  {Object.keys(jobTypes).map((jobType) => (
                    <Option key={jobType} value={jobType}>
                      {jobTypes[jobType].test_type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {selectedJobType && (
                <Form.Item label="New Job Type Name">
                  <Input
                    placeholder="Enter new job type name"
                    value={modifiedJobType}
                    onChange={(e) => setModifiedJobType(e.target.value)}
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleModifyJobType}
                  disabled={!selectedJobType || !modifiedJobType}
                >
                  Modify Job Type
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>

        <TabPane tab="Remove" key="2.3">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Select Job Type">
                <Select
                  placeholder="Select a job type to remove"
                  onChange={(value) => setSelectedJobType(value)}
                >
                  {Object.keys(jobTypes).map((jobType) => (
                    <Option key={jobType} value={jobType}>
                      {jobTypes[jobType].test_type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  onClick={() => setIsModalVisible(true)}
                  disabled={!selectedJobType}
                >
                  Remove Job Type
                </Button>
              </Form.Item>
            </Form>
            <Modal
              title="Confirm Job Type Removal"
              visible={isModalVisible}
              onOk={handleRemoveJobType}
              onCancel={() => setIsModalVisible(false)}
            >
              <p>Are you sure you want to remove the job type "{selectedJobType}"?</p>
            </Modal>
          </Card>
        </TabPane>
      </Tabs>
    </TabPane>
        <TabPane tab="Cost Center Details" key="3">
          <Tabs>
          <TabPane tab="Add" key="3.1">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Project Number">
                <Input value={costCenterProjectNumber} onChange={handleCostCenterProjectNumberChange} placeholder='904931'/>
              </Form.Item>
              <Form.Item label="Program">
                <Input value={costCenterProgram} onChange={handleCostCenterProgramChange} placeholder='OFerrari Serratura DX CPLH'/>
              </Form.Item>
              <Form.Item label="PM">
                <Input value={costCenterPm} onChange={handleCostCenterPmChange} placeholder='Carmelo Gioffre'/>
              </Form.Item>
              <Form.Item label="BCKPM">
                <Input value={bckPm} onChange={handleBckPmChange} placeholder='Carmelo Gioffre'/>
              </Form.Item>
              <Form.Item label="CDC">
                <Input value={costCenterCdc} onChange={handleCostCenterCdcChange} placeholder='OH'/>
              </Form.Item>
              <Form.Item label="Charge To">
                <Input value={chargeTo} onChange={handleChargeToChange} placeholder='Motrol (288)'/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitCostCenter}>
                  Submit New Cost Center Details
                </Button>
              </Form.Item>
            </Form>
          </Card>
          </TabPane>
          <TabPane tab="Modify" key="3.2">
          <Card bordered>
            Add dropdown to modify
          </Card>
          </TabPane>
          <TabPane tab="Remove" key="3.3">
          <Card bordered>
            Add dropdown to remove
          </Card>
          </TabPane>
        </Tabs>
        </TabPane>

        <TabPane tab="Fixture" key="4">
      <Tabs>
        <TabPane tab="Add" key="4.1">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Fixture Name">
                <Input
                  value={fixtureName}
                  onChange={handleFixtureNameChange}
                  placeholder='Galdabini Quasar 100'
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleSubmitFixture}
                >
                  Submit New Fixture
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>

        <TabPane tab="Modify" key="4.2">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Select Fixture">
                <Select
                  placeholder="Select a fixture to modify"
                  onChange={(value) => setSelectedFixture(value)}
                >
                  {fixtures.map((fixture) => (
                    <Option key={fixture.id} value={fixture.id}>
                      {fixture.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {selectedFixture && (
                <Form.Item label="New Fixture Name">
                  <Input
                    placeholder="Enter new fixture name"
                    value={modifiedFixtureName}
                    onChange={(e) => setModifiedFixtureName(e.target.value)}
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleModifyFixture}
                  disabled={!selectedFixture || !modifiedFixtureName}
                >
                  Modify Fixture
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>

        <TabPane tab="Remove" key="4.3">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Select Fixture">
                <Select
                  placeholder="Select a fixture to remove"
                  onChange={(value) => setSelectedFixture(value)}
                >
                  {fixtures.map((fixture) => (
                    <Option key={fixture.id} value={fixture.id}>
                      {fixture.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  onClick={() => setIsModalVisible(true)}
                  disabled={!selectedFixture}
                >
                  Remove Fixture
                </Button>
              </Form.Item>
            </Form>
            <Modal
              title="Confirm Fixture Removal"
              visible={isModalVisible}
              onOk={handleRemoveFixture}
              onCancel={() => setIsModalVisible(false)}
            >
              <p>Are you sure you want to remove the fixture "{selectedFixture}"?</p>
            </Modal>
          </Card>
        </TabPane>
      </Tabs>
    </TabPane>
        <TabPane tab="Climatic Chamber " key="5">
          <Tabs>
          <TabPane tab="Add " key="5.1">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Climatic Chamber Name">
                <Input value={climaticChamberName} onChange={handleClimaticChamberNameChange} placeholder='Camera 01 ACS'/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitClimaticChamber}>
                  Submit New Climatic Chamber
                </Button>
              </Form.Item>
            </Form>
          </Card>
          </TabPane>
          <TabPane tab="Modify " key="5.2">
          <Card bordered>
            Modify
          </Card>
          </TabPane>
          <TabPane tab="Remove " key="5.3">
          <Card bordered>
            Remove
          </Card>
          </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Test Instruction" key="6">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Test Name">
                <Input value={testName} onChange={handleTestNameChange} placeholder='Conditioning' />
              </Form.Item>
              <Form.Item label="Test Number" className="form-item-container">
                <div className="input-container">
                  <Input value={testNumber} onChange={handleTestNumberChange} maxLength={9} placeholder='T00001-A00' />
                </div>
                <div className="tag-container">
                  <Tag icon={<ExclamationCircleOutlined />} color="warning">
                    Make sure the test number matches the uploaded PDF file. Can only be 9 characters long
                  </Tag>
                </div>
              </Form.Item>

              <Form.Item>
                <Upload 
                  beforeUpload={handleUpload}
                  accept=".pdf"
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Upload PDF</Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitTestInstruction}>
                  Submit New Test Instruction
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AdminPanel;
