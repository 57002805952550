// src/axiosConfig.js
import axios from 'axios';

// base URL for axios
// axios.defaults.baseURL = 'http://127.0.0.1:5000';

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    // console.log('axios config, the token is: ', token);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axios;


