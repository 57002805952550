import React, { useState, createRef } from "react";
import { Input } from "antd";
import './resetPages.css';

const CodeInput = ({ length, onChange }) => {
  const [inputs, setInputs] = useState(Array(length).fill(""));
  const inputRefs = Array(length)
    .fill()
    .map(() => createRef());
  const handleChange = (index) => (e) => {
    const newInputs = [...inputs];
    newInputs[index] = e.target.value;
    setInputs(newInputs);
    if (index < length - 1 && e.target.value) {
      inputRefs[index + 1].current.focus();
    }
    onChange(newInputs.join(""));
  };
  const handleKeyDown = (index) => (e) => {
    if (e.key === "Backspace" && index > 0 && !inputs[index]) {
      inputRefs[index - 1].current.focus();
    }
  };
  return (
    <div>
      {inputs.map((input, index) => (
        <Input
          key={index}
          ref={inputRefs[index]}
          value={input}
          maxLength={1}
          onChange={handleChange(index)}
          onKeyDown={handleKeyDown(index)}
          style={{
            width: "60px",
            height: "60px",
            marginRight: "5px",
            textAlign: "center",
            borderRadius: "5px",
          }}
        />
      ))}
    </div>
  );
};
export default CodeInput;
