import React, {useState, useEffect} from 'react'; 
import "./quotation.css";
import { Alert, Checkbox } from 'antd';

function Quotation({job_name}) {
  const [jobName, setJobName] = useState(job_name);
  const [hourlyCost, setHourlyCost] = useState(60);
  const [estimatedEtoHours, setEstimatedEtoHours] = useState(0);
  const [estimatedEtoCost, setEstimatedEtoCost] = useState(0);
  const [estimatedOutsourcingCost, setEstimatedOutsourcingCost] = useState(0);
  const [estimatedFixtureCost, setEstimatedFixtureCost] = useState(0);
  const [estimatedTotalCost, setEstimatedTotalCost] = useState(estimatedEtoCost + estimatedOutsourcingCost + estimatedFixtureCost);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [outsourcingDetails, setOutsourcingDetails] = useState('');
  const [onCompletion, setOnCompletion] = useState(false);
  const [comments, setComments] = useState('');

  useEffect(() => {
    setEstimatedEtoCost(estimatedEtoHours * hourlyCost);
  }, [hourlyCost, estimatedEtoHours]);

  useEffect(() => {
    setEstimatedTotalCost(estimatedEtoCost + estimatedOutsourcingCost + estimatedFixtureCost);
  }, [estimatedEtoCost, estimatedOutsourcingCost, estimatedFixtureCost]);
  
  const handleCheckboxChange = (e) => {
    setOnCompletion(e.target.checked);
  };

  return (
    <div>
      <Alert message="You have assigned yourself as the Project Leader for this job. Please fill out the quotation." type="success" />
      <br />
      <div className='dates-container'>
        <label >
          Job Name:
          <input type="text" value={jobName} onChange={(e) => setJobName(e.target.value)} />
        </label>
        
        <label>
            Start Date:
            <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>

        <label>
            End Date:
            <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
      </div>
      
      <h2>Costs</h2>
      <table>
        <thead>
          <tr>
            {/* <th>Cost Type</th>
            <th>Value in €</th> */}
            <th>Hourly Cost</th>
            <th>Estimated ETO Hours</th>
            <th>Estimated ETO Cost</th>
            <th>Estimated Outsourcing Cost</th>
            <th>Estimated Fixture Cost</th>
            <th>Estimated Total Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="number"
                value={hourlyCost}
                onChange={(e) => setHourlyCost(parseFloat(e.target.value))}
              />
            </td>
            <td>
              <input
                type="number"
                value={estimatedEtoHours}
                onChange={(e) => setEstimatedEtoHours(parseFloat(e.target.value))}
              />
            </td>
            <td>
              <input type="number" value={estimatedEtoCost} readOnly />
            </td>
            <td>
              <input
                type="number"
                value={estimatedOutsourcingCost}
                onChange={(e) => setEstimatedOutsourcingCost(parseFloat(e.target.value))}
              />
            </td>
            <td>
              <input
                type="number"
                value={estimatedFixtureCost}
                onChange={(e) => setEstimatedFixtureCost(parseFloat(e.target.value))}
              />
            </td>
            <td>
              <input type="number" value={estimatedTotalCost} readOnly />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <label>
        Outsourcing Details:
        <input type="text" value={outsourcingDetails} onChange={(e) => setOutsourcingDetails(e.target.value)} />
        {/* <textarea value={outsourcingDetails} onChange={(e) => setOutsourcingDetails(e.target.value)} /> */}
      </label>

      <label>
        {/* <input type="checkbox" checked={onCompletion} onChange={handleCheckboxChange} /> */}
        <Checkbox onChange={handleCheckboxChange}> On Completion</Checkbox>
      </label>
      <br />
      <label>
        Comments:
        <input type="text" value={comments} onChange={(e) => setComments(e.target.value)}/>
        {/* <textarea value={comments} onChange={(e) => setComments(e.target.value)} /> */}
      </label>
    </div>
  );
};

export default Quotation;