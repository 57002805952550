import React from "react";
import "./NavBar.css";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Dropdown, Menu, Switch } from "antd";
import { HomeOutlined, MenuOutlined } from "@ant-design/icons";

function NavBar({ onLogout }) {
  const userRole = localStorage.getItem("userRole");
  const userName = localStorage.getItem("userName");

  function getInitials(name) {
    const names = name.split(" ");
    const initials = names.map((part) => part[0]).join("");
    return initials;
  }
  const userInitials = userName ? getInitials(userName) : "";
  const navigate = useNavigate();
  const job = {};
  const handleFlowchartNavigation = () => {
    job["from"] = "NavBar";
    navigate("/flowchart", { state: job });
  };

  function getRoleColor(role) {
    switch (role) {
      case "Admin":
        return "#8a62bd";
      case "Project Leader":
        return "#707fd4";
      case "Engineer":
        return "#E63946";
      case "Cost Center Manager":
        return "#F4A261";
      case "Test Technician":
        return "#2A9D8F";
      default:
        return "#CCCCCC";
    }
  }
  const roleColor = getRoleColor(userRole);

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      {(userRole === "Engineer" || userRole === "Admin") && (
        <Menu.Item>
          <Link to="/addJob">Add Job</Link>
        </Menu.Item>
      )}
      <Menu.Item onClick={handleFlowchartNavigation}>
        Flowchart Editor Mode
      </Menu.Item>
      {userRole === "Admin" && (
        <>
          <Menu.Item>
            <Link to="/users">Manage Users</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/adminPanel">Admin Panel</Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  // return (
  //   <nav>
  //     <div className="logo">
  //       <Link to="/">
  //         <img src="magna-logo.png" alt="Logo" />
  //       </Link>
  //       {userName}
  //       <ul className="nav-link">
  //         <li className="li-link">
  //           <Link to="/"><HomeOutlined /></Link>
  //         </li>
  //         <li className="li-link">
  //           <Dropdown overlay={menu} trigger={['click']}>
  //             <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
  //               <MenuOutlined />
  //             </a>
  //           </Dropdown>
  //         </li>
  //       </ul>
  //     </div>

  //     <ul className="nav-links">
  //       <li>
  //         <button onClick={onLogout} className="logout-button">
  //           Logout
  //         </button>
  //       </li>
  //     </ul>
  //   </nav>
  // );

  return (
    <nav>
      <div className="logo">
        <Link to="/">
          {/* <img src="magna-logo.png" alt="Logo" /> */}
          <img src="eto-logo.png" alt="Logo" />
        </Link>

        <ul className="nav-link"></ul>
        <small style={{ marginTop: "20px", marginLeft: "10px" }}>V1.4</small>
      </div>

      <ul className="nav-links">
        <li className="li-link">
          <Link to="/">
            <HomeOutlined />
          </Link>
        </li>
        <li className="li-link">
          <Dropdown overlay={menu} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <MenuOutlined />
            </a>
          </Dropdown>
        </li>
        <Link to="/profile" className="profile-link">
          <span className="user-initials" style={{ borderColor: roleColor }}>
            {userInitials}
          </span>
        </Link>
        <li>
          <button onClick={onLogout} className="logout-button">
            Logout
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
